import {createSelector} from 'reselect';

import {IAppState} from '../../state/AppState';

const getLocalAnswers = (state: IAppState) => state.localAnswers;

export const getCurrentCompetitionsLocalAnswers = createSelector(
  getLocalAnswers,
  (answers) => {
    if (answers !== null) {
      return answers;
    } else {
      return {answers: {}, likes: {}, tempRemoteId: null};
    }
  },
);
