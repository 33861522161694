import React from 'react';
import {StyleSheet} from 'react-native';
import {COLOR_DARK, COLOR_YELLOW} from '../Styles/DesignSystem-chancer';
import {ShareIcon} from '../Svg/SvgIcons';

import {IButtonProps, PrimaryButton} from './PrimaryButton';

interface IProps extends IButtonProps {
  withIcon?: boolean;
}

export const ShareButton: React.FC<IProps> = (props) => {
  const withIcon = props.withIcon ?? true;
  return (
    <PrimaryButton
      style={[styles.container, props.style]}
      size={props.size}
      labelStyle={[styles.label, props.labelStyle]}
      disabledStyle={props.disabledStyle}
      disabled={props.disabled}
      label={props.label}
      icon={withIcon ? <ShareIcon height={26} color={COLOR_DARK} /> : undefined}
      onPress={props.onPress}>
      {props.children}
    </PrimaryButton>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_YELLOW,
  },
  label: {
    color: COLOR_DARK,
  },
});
