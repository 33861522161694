import {IAppState} from '../../state/AppState';

import {
  TLeader,
  LeaderboardType,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  getCompetition,
  getUsersCompetitionEntry,
  getCompetitionCounts,
  getQuestions,
  getCompetitionStatus,
} from '../competitions/CompetitionSelectors';
import {createSelector} from 'reselect';
import {getUser, getUserIsFollowingAsMap} from '../user/AppUserSelectors';
import {usersCompetitionResults} from '@chancer/common/lib/utils/CompetitionUtils';
import {TFirebaseLeaderboard} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';

const getLeaderboardConfigResult = (state: IAppState) =>
  state.messageData.leaderboardConfiguration.response;

export const getLeaderboard = (state: IAppState): TLeader[] =>
  state.messageData.leaders.response?.leaders ?? [];

export const getNumFollowingInComp = createSelector(
  getLeaderboard,
  getUserIsFollowingAsMap,
  (leaders, following) => {
    let count = 0;
    leaders.forEach((leader) => {
      if (following[leader.u] === true) {
        count++;
      }
    });
    return count;
  },
);

export const getFollowingInComp = createSelector(
  getLeaderboard,
  getUserIsFollowingAsMap,
  (leaderboard, following): string[] =>
    leaderboard
      .filter((leader) => following[leader.u] === true)
      .map((leader) => leader.u),
);

export const getLeaderboardConfig = createSelector(
  getCompetition,
  getLeaderboardConfigResult,
  (competition, config): TFirebaseLeaderboard => {
    if (competition && config) {
      return config;
    }
    return {
      id: '',
      path: '',
      vendor: competition?.vendor ?? '',
      key: 'ingame',
      type: LeaderboardType.INGAME,
      shortName: '',
      name: '',
      publishUserLeaders: false,
      details: '',
      media: {
        caption: '',
      },
      comps: [],
      levels: [],
      prizes: [],
    };
  },
);

export const getUsersLeaderboardEntry = createSelector(
  getUser,
  getLeaderboard,
  (user, leaderboard) =>
    user ? leaderboard.find((l) => l.u === user.id) ?? null : null,
);

export const getUsersCompetitionResults = createSelector(
  getUsersCompetitionEntry,
  getCompetitionCounts,
  getCompetitionStatus,
  getQuestions,
  (usersEntry, compData, status, questions) => {
    return usersCompetitionResults(usersEntry, compData, status, questions);
  },
);
