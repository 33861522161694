import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {PrimaryButton} from '../Button/PrimaryButton';
import {COLOR_LIGHT, FONT_COPY_MEDIUM} from '../Styles/DesignSystem-chancer';

interface IProps {
  onPress: () => void;
}

export const UpdateRequired: React.FC<IProps> = (props) => {
  return (
    <View style={styles.updateContainer}>
      <Text style={styles.updateText}>
        An app update is required to view this feature
      </Text>
      <PrimaryButton
        style={styles.updateButton}
        onPress={props.onPress}
        label="Update Now"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  updateContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    paddingHorizontal: 32,
    paddingVertical: 48,
  },
  updateText: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 18,
    paddingBottom: 24,
    textAlign: 'center',
  },
  updateButton: {
    marginHorizontal: 64,
  },
  container: {paddingHorizontal: 16},
});
