import {IApiData, IFirestoreServerApi} from '../state/AppState';
import {ActionType, IAction} from '../actions/Actions';

export const undefinedInReducerError = new Error(
  'Passing undefined to a reducer is not allowed',
);

export const firestoreServerApiHandler = (
  prop: keyof IFirestoreServerApi,
  requestActionType: ActionType,
  responseActionType: ActionType,
  errorActionType: ActionType,
): {
  [actionType in ActionType]?: (
    state: IFirestoreServerApi,
    action: IAction<any>,
  ) => IFirestoreServerApi;
} => ({
  [requestActionType]: (state: IFirestoreServerApi, action: IAction<any>) => {
    return {
      ...state,
      [prop]: handleServerRequest(state[prop], action),
    };
  },
  [responseActionType]: (state: IFirestoreServerApi, action: IAction<any>) => {
    return {
      ...state,
      [prop]: handleServerResponse(state[prop], action),
    };
  },
  [errorActionType]: (state: IFirestoreServerApi, action: IAction<any>) => {
    return {
      ...state,
      [prop]: handleServerError(state[prop], action),
    };
  },
});

/**
 * Generic server request handler for updating an IApiData instance
 */
export const handleServerRequest = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: null,
    isInvalidated: false,
    isUpdating: true,
    request: processPayload(action.payload),
  };
};

/**
 * Generic server response handler for updating an IApiData instance
 */
export const handleServerResponse = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: null,
    isInvalidated: false,
    isUpdating: false,
    lastUpdated: new Date(),
    response: processPayload(action.payload),
  };
};

/**
 * Generic server error handler for updating an IApiData instance
 */
export const handleServerError = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: processPayload(action.payload),
    isInvalidated: false,
    isUpdating: false,
    lastUpdated: new Date(),
    response: null,
  };
};

const processPayload = (payload: any): any => {
  const responseType: string = typeof payload;
  let response: any;
  if (
    responseType === 'string' ||
    responseType === 'number' ||
    responseType === 'boolean' ||
    responseType === 'undefined' ||
    payload === null
  ) {
    response = payload;
  } else if (Array.isArray(payload)) {
    response = [...payload];
  } else {
    response = {...payload};
  }
  return response;
};
