import React, {useCallback, useMemo} from 'react';
import {
  LayoutChangeEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {
  COLOR_DARK,
  COLOR_YELLOW,
  FONT_COPY_BOLD,
  FONT_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedTextStyle, CompositedViewStyle} from '../Styles/StyleTypes';

export type ButtonSize = 'small' | 'medium' | 'large';

export interface IButtonProps {
  children?: React.ReactNode;
  size?: ButtonSize;
  style?: CompositedViewStyle;
  labelStyle?: CompositedTextStyle;
  label?: string;
  disabledStyle?: CompositedViewStyle;
  disabledLabelStyle?: CompositedTextStyle;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconStyle?: CompositedViewStyle;
  iconSpacingOnRight?: boolean;
  secondLabelStyle?: CompositedTextStyle;
  secondLabel?: string;
  onPress?: () => void;
}

export const PrimaryButton: React.FC<IButtonProps> = (props) => {
  const {size} = props;
  const styles = useMemo(() => getStyles(size ?? 'large'), [size]);
  const iconSpacingOnRight = props.iconSpacingOnRight ?? true;

  const [iconWidth, setIconWidth] = React.useState(0);

  const onIconSize = useCallback((event: LayoutChangeEvent) => {
    setIconWidth(event.nativeEvent.layout.width);
  }, []);

  return (
    <Pressable
      style={[
        styles.container,
        props.style,
        props.disabled ? props.disabledStyle : undefined,
      ]}
      disabled={props.disabled}
      onPress={props.onPress}>
      {props.icon !== undefined && (
        <View style={[styles.icon, props.iconStyle]} onLayout={onIconSize}>
          {props.icon}
        </View>
      )}
      {props.label !== undefined && (
        <Text
          style={[
            styles.label,
            props.labelStyle,
            props.disabled ? props.disabledLabelStyle : undefined,
          ]}>
          {props.label}
        </Text>
      )}
      {props.secondLabel !== undefined && (
        <Text style={[styles.secondLabel, props.secondLabelStyle]}>
          {props.secondLabel}
        </Text>
      )}
      {props.icon !== undefined && iconSpacingOnRight && (
        <View style={[styles.iconPadding, {width: iconWidth}]} />
      )}
      {props.children}
    </Pressable>
  );
};

const getStyles = (size: ButtonSize) =>
  StyleSheet.create({
    container: {
      flexGrow: 0,
      flexDirection: 'row',
      userSelect: 'none',
      backgroundColor: COLOR_YELLOW,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      height: size === 'small' ? 32 : size === 'medium' ? 40 : 48,
      paddingHorizontal: 16,
    },
    icon: {marginRight: 2, flexGrow: 0},
    iconPadding: {flexShrink: 1, flexGrow: 0},
    label: {
      flexGrow: 1,
      fontFamily:
        size === 'small'
          ? FONT_COPY_BOLD
          : size === 'medium'
            ? FONT_COPY_BOLD
            : FONT_TITLE,
      color: COLOR_DARK,
      textAlign: 'center',
      fontSize: size === 'small' ? 14 : size === 'medium' ? 16 : 18,
    },
    secondLabel: {
      position: 'absolute',
      right: 16,
      fontFamily:
        size === 'small'
          ? FONT_COPY_BOLD
          : size === 'medium'
            ? FONT_COPY_BOLD
            : FONT_TITLE,
      color: COLOR_DARK,
      textAlign: 'right',
      fontSize: size === 'small' ? 14 : size === 'medium' ? 16 : 18,
    },
  });
