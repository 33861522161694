import QueryString from 'qs';
import {createSelector} from 'reselect';

import {IAppState} from '../../state/AppState';

export const getChallenges = (state: IAppState) =>
  state.messageData.userChallenges.response || [];

export const getChallengeCounts = (state: IAppState) =>
  state.messageData.userChallengeCounts.response || {};

export const getChallengeReadCounts = (state: IAppState) =>
  state.messageData.userChallengeReadCounts.response || {};

export const getLaunchChallenge = createSelector(
  getChallenges,
  (challenges) => {
    const challengeId = getAllQuerystringParams().challengeId;
    if (challengeId) {
      return challenges.find((challenge) => challenge.id === challengeId);
    }
    return null;
  },
);

const getAllQuerystringParams = () => {
  const queryString = window.location.search;
  if (queryString && queryString.length > 0) {
    return QueryString.parse(queryString.substring(1), {depth: 0}) as {
      [key: string]: string;
    };
  }
  return {};
};
