import React, {useMemo} from 'react';
import {StyleSheet} from 'react-native';
import {
  COLOR_DARK_0,
  COLOR_GREY_6,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_COPY_BOLD,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';

import {Badge} from '../Status/Badge';
import {COLOR_GREY_8} from '../Styles/DesignSystem-sky';
import {IButtonProps, PrimaryButton} from './PrimaryButton';

interface IProps
  extends Omit<IButtonProps, 'size' | 'labelStyle' | 'disabledStyle'> {
  color?: string;
  backgroundColor?: string;
  selected?: boolean;
  count?: number;
  hideBadgeLabel?: boolean;
}

export const TabButton: React.FC<IProps> = (props) => {
  const {style, selected} = props;
  const styles = useMemo(
    () => getStyles(props.color, props.backgroundColor),
    [props.color, props.backgroundColor],
  );
  const containerStyle = useMemo(
    () => [styles.container, ...(selected ? [styles.selected] : []), style],
    [selected, styles, style],
  );

  return (
    <>
      <PrimaryButton
        style={containerStyle}
        size="large"
        labelStyle={
          props.selected
            ? styles.selectedLabel
            : props.disabled
              ? styles.disabledLabel
              : styles.label
        }
        disabled={props.disabled}
        label={props.label}
        onPress={props.onPress}>
        {props.count !== undefined && props.count > 0 && (
          <Badge
            style={styles.badge}
            size={props.hideBadgeLabel === true ? 'small' : 'medium'}
            textStyle={styles.badgeText}
            count={props.hideBadgeLabel === true ? undefined : props.count}
          />
        )}
        {props.children}
      </PrimaryButton>
    </>
  );
};

const getStyles = (
  color: string = COLOR_YELLOW,
  backgroundColor: string = COLOR_DARK_0,
) =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexGrow: 1,
      backgroundColor: backgroundColor,
      borderRadius: 0,
      borderBottomWidth: 0,
      borderColor: color,
      borderStyle: 'solid',
      height: 60,
    },
    label: {
      color: COLOR_GREY_8,
      fontFamily: FONT_COPY_MEDIUM,
    },
    selected: {borderBottomWidth: 6, paddingTop: 6},
    selectedLabel: {
      fontFamily: FONT_COPY_BOLD,
      color: COLOR_LIGHT,
    },
    disabledLabel: {
      color: COLOR_GREY_6,
      fontFamily: FONT_COPY_MEDIUM,
    },
    badge: {
      // This doesn't look great in all situations, but it does work reasonably well
      // for common screen sizes.
      position: 'absolute',
      top: 15,
      right: '15%',
    },
    badgeText: {
      fontFamily: FONT_COPY_BOLD,
    },
  });
