import {createSelector} from 'reselect';

import {TFirebaseCompCounts} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  ChatMessageType,
  CompStatus,
  QuestionStatus,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';

import {ClientStatus} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {getCompetitionClientStatus} from '@chancer/common/lib/utils/CompetitionUtils';
import {IAppState} from '../../state/AppState';
import {getCurrentCompetitionsLocalAnswers} from '../answers/AnswersSelectors';

export const getUpcomingCompetitions = (state: IAppState) =>
  state.messageData.upcomingCompetitions.response || [];

export const getCompetition = (state: IAppState) =>
  state.messageData.competition.response;

export const getCompetitionSummary = (state: IAppState) =>
  state.messageData.competitionSummary.response;

export const getCompetitionId = (state: IAppState) =>
  state.messageData.competition.response
    ? state.messageData.competition.response.id
    : null;

export const getQuestions = (state: IAppState) =>
  state.messageData.questions.response;

export const getCompetitionStatusResponse = (state: IAppState) =>
  state.messageData.competitionStatus.response;

export const getCompetitionStatus = createSelector(
  getCompetitionStatusResponse,
  (response) => response?.status ?? null,
);

// TODO: we can drop the filter after the updated native builds have been adopted
const getCompetitionChatResponse = (state: IAppState) =>
  state.messageData.competitionChat.response;
export const getCompetitionChat = createSelector(
  getCompetitionChatResponse,
  (competitionChat) =>
    competitionChat?.filter((m) => m.type !== ChatMessageType.UNKNOWN) ?? [],
);

const getCompetitionChatReadMs = (state: IAppState) =>
  state.messageData.competitionChatRead.response ?? 0;

export const getUsersCompetitionEntryReceived = (state: IAppState) => {
  return state.messageData.competitionUsersEntry.lastUpdated !== null;
};

export const getUsersCompetitionEntry = (state: IAppState) => {
  return state.messageData.competitionUsersEntry.response;
};

export const getEnterCompetitionStatus = (state: IAppState) => {
  return state.messageData.enterCompStatus;
};

export const getCompetitionChatUnreadCount = createSelector(
  getCompetitionChat,
  getCompetitionChatReadMs,
  (messages, chatReadMs) => {
    const count = Math.min(10, messages.length);
    if (chatReadMs === 0) {
      return count;
    }
    for (var i = 0; i < count; i++) {
      const m = messages[i];
      if (m.created !== null && m.created.seconds < chatReadMs / 1000) {
        return i;
      }
    }
    return count;
  },
);

export const getIsDraftMode = createSelector(
  getCompetitionStatus,
  (status) => status === CompStatus.DRAFT,
);

const getInternalCounts = (state: IAppState) =>
  state.messageData.competitionCounts.response;

export const getCompetitionCounts = createSelector(
  getInternalCounts,
  getQuestions,
  (counts, questions): TFirebaseCompCounts => {
    if (counts) {
      return counts;
    } else {
      if (questions !== null) {
        return {
          id: '',
          path: '',
          numQuestions: questions.length,
          entriesCount: 0,
          answersBreakdown: {},
          likesBreakdown: {},
          resolvedQuestions: [],
        };
      }
    }
    return {
      id: '',
      path: '',
      numQuestions: 0,
      entriesCount: 0,
      answersBreakdown: {},
      likesBreakdown: {},
      resolvedQuestions: [],
    };
  },
);
export const getOutstandingQuestions = createSelector(
  getQuestions,
  getUsersCompetitionEntry,
  getCurrentCompetitionsLocalAnswers,
  (questions, entry, answers) => {
    if (questions) {
      let questionsRemaining = [
        ...questions.filter(
          (q) =>
            q.status === QuestionStatus.OPEN ||
            q.status === QuestionStatus.OPEN_CHANGEABLE,
        ),
      ];
      if (entry) {
        questionsRemaining = questionsRemaining.filter(
          (q) => entry.answers[q.id] === undefined,
        );
      }
      if (answers) {
        questionsRemaining = questionsRemaining.filter(
          (q) => answers.answers[q.id] === undefined,
        );
      }
      return questionsRemaining;
    }
    return [];
  },
);

export const getClientCompetitionStatus = createSelector(
  getCompetition,
  getCompetitionStatus,
  (comp, status) => {
    if (comp) {
      return getCompetitionClientStatus(comp.starts, status);
    }
    return ClientStatus.SUSPENDED;
  },
);
