import {Reducer} from 'react';

import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import {ILocalAnswersForComp} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {ActionType, IAction} from '../../actions/Actions';

export const localAnswersReducer: Reducer<
  ILocalAnswersForComp | null,
  IAction<any>
> = (state, action) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.LOCAL_ANSWERS_MESSAGE:
      return action.payload;
  }
  return state;
};
