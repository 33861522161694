import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import {TMediaEntry} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {applyWindowsScrollStyle} from '@chancer/common/lib/utils/ComponentUtils';
import {ScreenBackground} from './ScreenBackground';

import './ScreenContainer.scss';
import {COLOR_BACKGROUND} from '@chancer/components/lib/Styles/DesignSystem-chancer';
import {
  IColorConfiguration,
  getColorConfiguration,
} from '@chancer/common/lib/utils/ColorUtils';

interface IProps {
  containerStyle?: React.CSSProperties;
  ref?: React.Ref<HTMLDivElement>;
  mute: boolean;
  currentIndex: number;
  pageIndex: number;
  isQuestion: boolean;
  media: TMediaEntry | null;
  windowHeight: number;
  scrollPosition?: number;
  safeAreaTopPadding: number;
  children: React.ReactNode;
  colorConfig?: IColorConfiguration;
  onMute: () => void;
  onScroll?: React.UIEventHandler<HTMLDivElement> | undefined;
}

export const ScreenContainer: React.FC<IProps> = forwardRef(
  (props, scrollRef: React.ForwardedRef<HTMLDivElement>) => {
    const innerRef = useRef<HTMLDivElement>(null!);
    useImperativeHandle(scrollRef, () => innerRef.current);

    const diff = props.pageIndex - props.currentIndex;
    const isReadyToPreload = diff >= 0 && diff < 2;

    const isActive = props.currentIndex === props.pageIndex;
    const isNext = props.pageIndex - props.currentIndex === 1;
    const isPrev = props.pageIndex - props.currentIndex === -1;

    const {scrollPosition} = props;

    useEffect(() => {
      if (scrollPosition !== undefined && innerRef.current) {
        if (innerRef.current.scrollTop !== scrollPosition) {
          innerRef.current.scrollTo({top: scrollPosition});
        }
      }
    }, [scrollPosition]);

    return (
      <div className="screen-container__container">
        <ScreenBackground
          active={isActive}
          colorConfig={
            props.colorConfig || getColorConfiguration(COLOR_BACKGROUND)
          }
          safeAreaTopPadding={props.safeAreaTopPadding}
          preload={isReadyToPreload}
          media={props.media}
          mute={props.mute}
          onMute={props.onMute}
        />
        {/* This element `screen-container__content` is the one that scrolls the content */}
        <div
          ref={innerRef}
          className={applyWindowsScrollStyle('screen-container__content')}
          style={props.containerStyle}
          onScroll={props.onScroll}>
          {isNext || isActive || isPrev ? props.children : null}
        </div>
      </div>
    );
  },
);
