import {
  TLeader,
  TMultiGameLeader,
} from '../interfaces/firestore/FirestoreInterfaces';
import {assertType} from './TypeUtils';

// Return a filtered version of leaders with post filter deltas(d)
// by first reconstituting the previous leaders array using the current
// leaders and deltas, then filtering both arrays and calculating new
// deltas from the filtered leaders.
export const filter = <T extends TLeader | TMultiGameLeader>(
  leaders: T[],
  predicate: (leader: T) => boolean,
) => {
  const previous = new Array<T>(leaders.length);
  leaders.map((l, idx) => (previous[idx + (l.d ?? 0)] = l));
  const filtered = previous.filter(predicate);
  if (previous.length !== leaders.length) {
    // This is an indication of non-sensical deltas, so just return the
    // filtered leaders array without deltas instead.
    return leaders
      .filter(predicate)
      .map((l) => assertType<T>({...l, d: 0}));
  }

  return leaders
    .filter(predicate)
    .map((l, idx) => assertType<T>({...l, d: filtered.indexOf(l) - idx}));
};
