/**
 * Capitalises the first letter of a word. red -> Red. RED -> Red
 * @param input : String you expect to be capitalisesd.
 */
export const capitalise = (input: string): string => {
  return (
    input.toLowerCase().charAt(0).toUpperCase() + input.toLowerCase().slice(1)
  );
};

export const removeSpaces = (input: string): string => {
  return input.replace(/\s/g, '');
};

export const joinWithAnd = (list: string[]): string => {
  const copy = [...list].filter((item) => item);

  if (copy.length === 0) {
    return '';
  }

  if (copy.length === 1) {
    return copy[0];
  }

  const last = copy.pop();

  return copy.join(', ') + ' and ' + last;
};

// https://stackoverflow.com/questions/29182244/convert-a-string-to-a-template-string/71814647#71814647
export const interpolate = (
  template: string,
  hash: Record<string, any>,
): string => {
  return new Function(...Object.keys(hash), `return \`${template}\`;`)(
    ...Object.values(hash),
  );
};
