import {
  TCompEntryAnswers,
  TCompEntryLikes,
} from '../firestore/FirestoreInterfaces';

export enum EnterCompStatus {
  NOT_ENTERED = 0,
  PROCESSING = 1,
  COMPLETE = 2,
  ERROR = 3,
}

export enum AppPlatform {
  UNKNOWN = 0,
  WEB = 1,
  IOS = 2,
  ANDROID = 3,
}

export interface IAppConfig {
  version: string;
  appUrl: string;
  gameUrl: string;
  cdnUrl: string;
  facebookAppId: string;
  topPadding: number;
  isMobile: boolean;
  platform: AppPlatform;
}

export interface ILocalAnswersForComp {
  answers: TCompEntryAnswers;
  likes: TCompEntryLikes;
  tempRemoteId: string | null;
}

export interface IAnalyticsPayload {
  name: string;
  eventParams?: {[key: string]: any};
}

export enum EnterCompContentType {
  DEFAULT = 'DEFAULT',
  ANSWERED_OUTSTANDING_QUESTIONS = 'ANSWERED_OUTSTANDING_QUESTIONS',
  CHALLENGE_COMPLETED = 'CHALLENGE_COMPLETED',
  FIRST_N_GAMES = 'FIRST_N_GAMES',
  FIRST_N_VENDOR_GAMES = 'FIRST_N_VENDOR_GAMES',
}
