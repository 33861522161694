import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import {setLogPrefix} from '@chancer/common/lib/utils/Log';
import App from './App';
import reportWebVitals from './reportWebVitals';

setLogPrefix('[APP]');

if (
  window.self.location === window.top?.location &&
  navigator.userAgent.includes('com.chancer') === false &&
  window.hasOwnProperty('ReactNativeWebView') === false
) {
  window.top.location.replace('https://www.mixnpik.com');
}
if (process.env.BUILD_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://8cadf2e4c4da491cbd3e68139398bde9@o449662.ingest.sentry.io/5433002',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: process.env.BUILD_ENVIRONMENT,
    release: process.env.RELEASE_HASH,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
