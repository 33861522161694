import {combineLatest, merge, of} from 'rxjs';
import {ajax} from 'rxjs/ajax';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';

import {Value} from '@sinclair/typebox/value';
import {
  TFirebaseCompEntry,
  TFirebaseUser,
} from '../interfaces/firestore/FirestoreClientInterfaces';
import {processSerialisedTimestamps} from './FirebaseUtils';
import {assertType} from './TypeUtils';
import {getFingerprint} from './Fingerprint';

type CacheStrategy = 'cache' | 'invalidate' | 'none';

export const getTemporaryUserScoreStream =
  (gameUrl: string) => (userId: string, compId: string) =>
    of({userId, compId}).pipe(
      delay(90),
      mergeMap((payload) =>
        ajax({
          url: `${gameUrl}/get_temporary_user_score?uid=${payload.userId}&cid=${
            payload.compId
          }&fp=${getFingerprint()}`,
          method: 'GET',
        }).pipe(
          map((response) => (response.response?.score as number) ?? 0),
          tap((score) =>
            console.log(
              `Temporary user score for ${payload.userId} in ${payload.compId}: ${score}`,
            ),
          ),
        ),
      ),
      catchError((err) => of(err).pipe(map(() => 0))),
    );

const _users = new Map<string, TFirebaseUser>();
export const getUserByIdStream = (
  gameUrl: string,
  cacheStrategy: CacheStrategy = 'cache',
) => {
  const invalidated = new Map<string, boolean>();
  return (userId: string) => {
    if (
      cacheStrategy === 'none' ||
      (cacheStrategy === 'invalidate' && !invalidated.get(userId))
    ) {
      // Remove cached value
      _users.delete(userId);
      if (cacheStrategy === 'invalidate') {
        invalidated.set(userId, true);
      }
    }
    const requestStream = of(userId).pipe(
      delay(90),
      mergeMap((userId) =>
        ajax({
          url: `${gameUrl}/get_user?uid=${userId}`,
          method: 'GET',
        }).pipe(
          map((response) => processSerialisedTimestamps(response.response)),
          map((response) =>
            Value.Cast(TFirebaseUser, {
              ...response,
              id: userId,
              path: `/users/${userId}`,
            }),
          ),
          tap((user) => _users.set(userId, user)),
        ),
      ),
      catchError((err) =>
        of(err).pipe(
          map(() =>
            assertType<TFirebaseUser>({
              name: 'Unknown',
              media: {},
              achievements: [],
              id: '',
              path: '',
              vendorLeaders: {},
              vendorAchievements: {},
            }),
          ),
        ),
      ),
    );
    const cacheStream = of(_users.get(userId)).pipe(
      filter((user): user is TFirebaseUser => user !== undefined),
      delay(50),
    );

    return merge(cacheStream, requestStream).pipe(take(1));
  };
};

const _userEntries = new Map<string, TFirebaseCompEntry>();
export const getUserEntryByIdStream = (
  gameUrl: string,
  compId: string,
  cacheStrategy: CacheStrategy = 'cache',
) => {
  const invalidated = new Map<string, boolean>();
  return (userId: string) => {
    const key = `${compId}-${userId}`;
    if (
      cacheStrategy === 'none' ||
      (cacheStrategy === 'invalidate' && !invalidated.get(key))
    ) {
      // Remove cached value
      _users.delete(key);
      if (cacheStrategy === 'invalidate') {
        invalidated.set(key, true);
      }
    }
    const requestStream = of(userId).pipe(
      delay(90),
      mergeMap((userId) =>
        ajax({
          url: `${gameUrl}/get_user_entry?cid=${compId}&uid=${userId}`,
          method: 'GET',
        }).pipe(
          map((response) => processSerialisedTimestamps(response.response)),
          map((response) =>
            Value.Cast(TFirebaseCompEntry, {
              ...response,
              id: userId,
              path: `/competitions/${compId}/comp_entries/${userId}`,
            }),
          ),
          tap((entry) => _userEntries.set(key, entry)),
        ),
      ),
      catchError((err) =>
        of(err).pipe(
          map(() =>
            assertType<TFirebaseCompEntry>({
              answers: {},
              likes: {},
              userId: userId,
              compId: compId,
              id: '',
              path: '',
            }),
          ),
        ),
      ),
    );
    const cacheStream = of(_userEntries.get(key)).pipe(
      filter((answers): answers is TFirebaseCompEntry => answers !== undefined),
      delay(50),
    );

    return merge(cacheStream, requestStream).pipe(take(1));
  };
};

export interface IUserAndEntry {
  user: TFirebaseUser;
  entry: TFirebaseCompEntry;
}

export const getUsersAndEntriesStream =
  (gameUrl: string, compId: string) => (userIds: string[]) =>
    of(userIds).pipe(
      map((userIds) => ({
        userIds,
        streamForUser: getUserByIdStream(gameUrl),
        streamForEntry: getUserEntryByIdStream(gameUrl, compId),
      })),
      mergeMap((data) =>
        combineLatest(
          data.userIds.map((userId) =>
            of(userId).pipe(
              mergeMap((userId, _index) =>
                combineLatest([
                  data.streamForUser(userId),
                  data.streamForEntry(userId),
                ]).pipe(
                  map((userAndEntry) =>
                    assertType<IUserAndEntry>({
                      user: userAndEntry[0],
                      entry: userAndEntry[1],
                    }),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    );
