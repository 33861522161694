import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  CompQuestionType,
  QUESTION_MISSED_ANSWER,
  QuestionStatus,
  TCompAnswer,
  TCompAnswerBreakdown,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  calulatePercentages,
  isAnswerCorrect,
} from '@chancer/common/lib/utils/CompetitionUtils';
import {IconButton} from '../Button/IconButton';
import {PrimaryButton} from '../Button/PrimaryButton';
import {SecondaryButton} from '../Button/SecondaryButton';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_DARK,
  COLOR_GREEN,
  COLOR_GREY_2,
  COLOR_GREY_4,
  COLOR_LIGHT,
  COLOR_RED,
  COLOR_YELLOW,
  FONT_COPY,
  FONT_COPY_BOLD,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {ChevronDownIcon, ChevronUpIcon} from '../Svg/SvgIcons';
import {QuestionAnswer, QuestionAnswerState} from './QuestionAnswer';
import {QuestionItemCompareHeader} from './QuestionItemCompareHeader';
import {QuestionItemUserHeader} from './QuestionItemUserHeader';

interface IProps {
  appPlatform?: AppPlatform;
  type: CompQuestionType;
  status: QuestionStatus;
  isCompetitionOpen: Boolean;
  index: number;
  question: string;
  hint?: string;
  answers: TCompAnswer[];
  answersBreakdown: TCompAnswerBreakdown | undefined;
  followedUserEntries: TFirebaseUser[][];
  result?: number | number[];
  isCurrentUser: boolean;
  userAnswer?: number;
  userImageUrl: string | null;
  isComparison?: boolean;
  compareAnswer?: number;
  compareImageUrl?: string | null;
  starts?: Date;
  onPlayComp: () => void;
  onChangeAnswer: ((answer: number) => void) | undefined;
  onGetApp?: () => void;
}

export enum QuestionState {
  NEW,
  MISSED,
  ENTERED,
  WAITING,
  CORRECT,
  INCORRECT,
  CHANGEABLE,
}

enum AnswerChangeState {
  IDLE,
  CHANGING,
  CHANGED,
}

export const QuestionItem: React.FC<IProps> = (props) => {
  const {
    answersBreakdown,
    answers,
    status,
    isCompetitionOpen,
    result,
    isCurrentUser,
    userAnswer,
    type,
    compareAnswer,
    isComparison,
    onChangeAnswer,
  } = props;

  const previousUserAnswer = useRef(userAnswer);
  const [internalAnswer, setInternalAnswer] = useState<number | undefined>(
    userAnswer,
  );
  const [answerChanged, setAnswerChanged] = useState(AnswerChangeState.IDLE);

  const questionState = useMemo(
    () =>
      getQuestionState(
        status,
        isCompetitionOpen,
        onChangeAnswer === undefined,
        result,
        isCurrentUser,
        userAnswer,
        type,
      ),
    [
      status,
      isCompetitionOpen,
      result,
      isCurrentUser,
      userAnswer,
      type,
      onChangeAnswer,
    ],
  );

  const [isOpen, setIsOpen] = useState(
    questionState === QuestionState.NEW || answers.length <= 1,
  );

  useEffect(() => {
    if (
      userAnswer !== previousUserAnswer.current &&
      answerChanged === AnswerChangeState.CHANGING
    ) {
      previousUserAnswer.current = userAnswer;
      setInternalAnswer(userAnswer);
      setAnswerChanged(AnswerChangeState.CHANGED);
    }
  }, [userAnswer, answerChanged]);

  useEffect(() => {
    if (answerChanged === AnswerChangeState.CHANGED) {
      const timer = setTimeout(() => {
        setAnswerChanged(AnswerChangeState.IDLE);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [answerChanged]);

  const compareQuestionState = useMemo(() => {
    if (compareAnswer === undefined) {
      return undefined;
    } else {
      const state = getQuestionState(
        status,
        isCompetitionOpen,
        onChangeAnswer === undefined,
        result,
        false,
        compareAnswer,
        type,
      );
      if (state === QuestionState.NEW) {
        return QuestionState.MISSED;
      }
      return state;
    }
  }, [compareAnswer, status, isCompetitionOpen, result, type, onChangeAnswer]);

  const answerStates = useMemo(
    () =>
      answers.map((answer, index) =>
        getAnswerState(status, questionState, type, result, index),
      ),
    [answers, questionState, status, type, result],
  );

  const score =
    questionState === QuestionState.CORRECT
      ? 1
      : questionState === QuestionState.INCORRECT ||
          questionState === QuestionState.WAITING
        ? 0
        : undefined;

  const percentageBreakdown: number[] | undefined = useMemo(() => {
    if (
      answersBreakdown &&
      (userAnswer !== undefined ||
        questionState === QuestionState.WAITING ||
        questionState === QuestionState.MISSED ||
        questionState === QuestionState.CORRECT ||
        questionState === QuestionState.INCORRECT) &&
      !(
        questionState === QuestionState.CHANGEABLE &&
        type === CompQuestionType.POLL
      )
    ) {
      return calulatePercentages(answers, answersBreakdown);
    }
    return undefined;
  }, [answersBreakdown, userAnswer, questionState, type, answers]);

  const containerStyle: CompositedViewStyle = useMemo(
    () =>
      answerChanged === AnswerChangeState.CHANGED
        ? [styles.container, {borderColor: COLOR_GREEN, borderWidth: 2}]
        : isOpen && questionState === QuestionState.CHANGEABLE
          ? [styles.container, {borderColor: COLOR_YELLOW, borderWidth: 2}]
          : styles.container,
    [answerChanged, isOpen, questionState],
  );

  const visibleAnswers = useMemo(() => {
    if (isOpen) {
      return answers;
    }

    const getMaximumPercentageIndex = () => {
      return answers.reduce((max, _, cur) => {
        return percentageBreakdown &&
          percentageBreakdown[cur] > percentageBreakdown[max]
          ? cur
          : max;
      }, 0);
    };

    const getVisibleQuestion = (
      state: QuestionState,
      answer: number | undefined,
    ) => {
      switch (state) {
        case QuestionState.NEW:
          return [];
        case QuestionState.MISSED:
        case QuestionState.ENTERED:
        case QuestionState.CHANGEABLE:
        case QuestionState.WAITING:
        case QuestionState.CORRECT:
        case QuestionState.INCORRECT:
          return answer !== undefined ? [answers[answer]] : [];
        default:
          return [];
      }
    };
    // Users answer
    let visible = getVisibleQuestion(questionState, userAnswer);
    if (isComparison && compareQuestionState) {
      // Comparison users answer
      visible = visible.concat(
        getVisibleQuestion(compareQuestionState, compareAnswer),
      );
    }
    if (visible.length === 0) {
      visible = [answers[getMaximumPercentageIndex()]];
    }
    return visible;
  }, [
    isOpen,
    questionState,
    answers,
    percentageBreakdown,
    userAnswer,
    isComparison,
    compareAnswer,
    compareQuestionState,
  ]);

  const showMissedQuestionButton =
    props.appPlatform === AppPlatform.WEB &&
    questionState === QuestionState.MISSED &&
    props.onGetApp !== undefined;

  return (
    <Pressable
      style={containerStyle}
      onPress={() => setIsOpen(true)}
      disabled={isOpen || answers.length <= 1}>
      {isComparison || compareQuestionState !== undefined ? (
        <QuestionItemCompareHeader
          questionType={type}
          questionNumber={props.index + 1}
          userQuestionState={questionState}
          compareQuestionState={compareQuestionState}
        />
      ) : (
        <QuestionItemUserHeader
          questionType={type}
          questionNumber={props.index + 1}
          questionState={questionState}
          questionStatus={status}
          starts={props.starts}
          score={score}
          updated={answerChanged === AnswerChangeState.CHANGED}
        />
      )}

      <Text style={styles.question}>{props.question}</Text>
      {questionState === QuestionState.NEW && isCurrentUser ? (
        <PrimaryButton
          style={styles.newButton}
          label="Answer Question"
          size="medium"
          onPress={props.onPlayComp}
        />
      ) : (
        <>
          {answers.map(
            (answer, index) =>
              visibleAnswers.includes(answer) && (
                <QuestionAnswer
                  key={`answer${index}`}
                  label={answer.label}
                  state={answerStates[index]}
                  percentage={
                    percentageBreakdown === undefined
                      ? undefined
                      : percentageBreakdown[index]
                  }
                  selected={internalAnswer === index}
                  userImageUrl={props.userImageUrl}
                  useCompareLayout={props.isComparison}
                  compareSelected={
                    props.compareAnswer !== undefined
                      ? props.compareAnswer === index
                      : undefined
                  }
                  usersSelected={props.followedUserEntries[index] ?? []}
                  compareImageUrl={props.compareImageUrl}
                  onSelected={
                    isOpen &&
                    props.onChangeAnswer !== undefined &&
                    answerChanged !== AnswerChangeState.CHANGING
                      ? () => {
                          setAnswerChanged(AnswerChangeState.CHANGING);
                          setInternalAnswer(index);
                          props.onChangeAnswer!(index);
                        }
                      : undefined
                  }
                />
              ),
          )}
          {answers.length > 1 ? (
            !isOpen ? (
              <IconButton style={styles.arrow} onPress={() => setIsOpen(true)}>
                <ChevronDownIcon color={COLOR_GREY_4} />
                {questionState === QuestionState.CHANGEABLE &&
                  onChangeAnswer !== undefined && (
                    <Text style={styles.arrowText}>CHANGE ANSWER</Text>
                  )}
              </IconButton>
            ) : (
              <IconButton style={styles.arrow} onPress={() => setIsOpen(false)}>
                <ChevronUpIcon color={COLOR_GREY_4} />
              </IconButton>
            )
          ) : null}
          {showMissedQuestionButton ? (
            <View style={styles.missedQuestionContainer}>
              <Text style={styles.missedQuestionCopy}>
                {
                  "You missed a question. Don't let it happen again!\nGet personalised reminders and more."
                }
              </Text>
              <SecondaryButton
                style={styles.missedQuestionButton}
                label="Get The Mixnpik App"
                size="medium"
                onPress={props.onGetApp}
              />
            </View>
          ) : null}
        </>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR_GREY_2,
    borderRadius: 16,
    padding: 16,
    marginBottom: 32,
    borderColor: COLOR_GREY_2,
    borderWidth: 1,
  },
  statusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgeContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  questionNumber: {
    color: COLOR_LIGHT,
    fontFamily: FONT_SUB_TITLE,
    fontSize: 16,
    marginRight: 8,
  },
  question: {
    marginTop: 8,
    marginBottom: 16,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
  },
  newButton: {
    marginHorizontal: 16,
  },
  arrow: {
    height: 16,
    minHeight: 16,
    flexDirection: 'row',
    textTransform: 'uppercase',
    color: COLOR_GREY_4,
  },
  arrowText: {
    color: COLOR_GREY_4,
    fontFamily: FONT_COPY_BOLD,
  },
  missedQuestionContainer: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    marginLeft: -16,
    marginRight: -16,
    marginBottom: -16,
    marginTop: 16,
    padding: 16,
    backgroundColor: COLOR_RED,
  },
  missedQuestionCopy: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY,
    fontSize: 15,
    marginBottom: 16,
  },
  missedQuestionButton: {
    backgroundColor: COLOR_RED,
  },
});

export const getQuestionState = (
  status: QuestionStatus,
  isCompetitionOpen: Boolean,
  changeAnswersDisabled: boolean,
  result: number | number[] | undefined,
  isCurrentUser: boolean,
  userAnswer: number | undefined,
  type: CompQuestionType,
) => {
  if (
    status === QuestionStatus.OPEN ||
    status === QuestionStatus.OPEN_CHANGEABLE
  ) {
    return userAnswer === undefined
      ? isCurrentUser
        ? QuestionState.NEW
        : QuestionState.MISSED
      : userAnswer === QUESTION_MISSED_ANSWER
        ? QuestionState.MISSED
        : type === CompQuestionType.TRIVIA ||
            (status === QuestionStatus.OPEN &&
              (!isCompetitionOpen || changeAnswersDisabled))
          ? QuestionState.ENTERED
          : status === QuestionStatus.OPEN_CHANGEABLE && changeAnswersDisabled
            ? QuestionState.ENTERED
            : result !== undefined && isAnswerCorrect(result, userAnswer)
              ? QuestionState.CORRECT
              : QuestionState.CHANGEABLE;
  } else if (status === QuestionStatus.INPLAY) {
    if (userAnswer === undefined || userAnswer === QUESTION_MISSED_ANSWER) {
      return QuestionState.MISSED;
    } else if (result === undefined) {
      return QuestionState.WAITING;
    } else {
      if (isAnswerCorrect(result, userAnswer)) {
        return QuestionState.CORRECT;
      } else {
        if (type === CompQuestionType.MULTIANSWER) {
          return QuestionState.WAITING;
        } else {
          return QuestionState.INCORRECT;
        }
      }
    }
  } else if (status === QuestionStatus.RESOLVED) {
    if (userAnswer === undefined || userAnswer === -1) {
      return QuestionState.MISSED;
    } else {
      return isAnswerCorrect(result, userAnswer)
        ? QuestionState.CORRECT
        : QuestionState.INCORRECT;
    }
  } else {
    // DRAFT
    return QuestionState.WAITING;
  }
};

export const getAnswerState = (
  questionStatus: QuestionStatus,
  questionState: QuestionState,
  questionType: string,
  result: number | number[] | undefined,
  answerIndex: number,
) => {
  if (
    questionState === QuestionState.NEW ||
    questionState === QuestionState.ENTERED
  ) {
    return QuestionAnswerState.OPEN;
  } else if (questionState === QuestionState.CHANGEABLE) {
    if (result !== undefined && isAnswerCorrect(result, answerIndex)) {
      return QuestionAnswerState.CORRECT;
    }
    return QuestionAnswerState.CHANGEABLE;
  } else {
    if (result === undefined || questionType === CompQuestionType.SURVEY0) {
      return QuestionAnswerState.OPEN;
    } else {
      if (isAnswerCorrect(result, answerIndex)) {
        return QuestionAnswerState.CORRECT;
      } else {
        if (
          questionStatus !== QuestionStatus.RESOLVED &&
          questionType === CompQuestionType.MULTIANSWER
        ) {
          return QuestionAnswerState.IN_PLAY;
        } else {
          return QuestionAnswerState.WRONG;
        }
      }
    }
  }
};

interface ILabelStateProps {
  style?: CompositedViewStyle;
  state: QuestionState;
  type?: CompQuestionType;
}

export const LabelState: React.FC<ILabelStateProps> = memo((props) => {
  const isSurvey =
    props.type === CompQuestionType.SURVEY ||
    props.type === CompQuestionType.SURVEY0;

  if (props.state === QuestionState.NEW) {
    return (
      <LabelBadge
        style={props.style}
        label="NEW QUESTION"
        labelColor={COLOR_DARK}
        backgroundColor={COLOR_YELLOW}
      />
    );
  } else if (
    props.state === QuestionState.CHANGEABLE ||
    props.state === QuestionState.ENTERED ||
    (props.type === CompQuestionType.SURVEY0 &&
      props.state !== QuestionState.MISSED)
  ) {
    return (
      <LabelBadge
        style={props.style}
        label={isSurvey ? 'VOTED' : 'ANSWERED'}
        backgroundColor={`${COLOR_DARK}00`}
        borderColor={COLOR_LIGHT}
      />
    );
  } else if (props.state === QuestionState.WAITING) {
    return (
      <LabelBadge
        style={props.style}
        label={isSurvey ? 'VOTING CLOSED' : 'IN PLAY'}
        backgroundColor={`${COLOR_DARK}00`}
        borderColor={COLOR_LIGHT}
      />
    );
  } else if (props.state === QuestionState.MISSED) {
    return (
      <LabelBadge
        style={props.style}
        label={isSurvey ? 'NOT VOTED' : 'NOT ANSWERED'}
        backgroundColor={`${COLOR_DARK}00`}
        borderColor={COLOR_LIGHT}
      />
    );
  } else if (props.state === QuestionState.CORRECT) {
    return (
      <LabelBadge style={props.style} label={isSurvey ? 'VOTED' : 'CORRECT'} />
    );
  } else if (props.state === QuestionState.INCORRECT) {
    return (
      <LabelBadge
        style={props.style}
        label={isSurvey ? 'DID NOT VOTE' : 'MISSED'}
        backgroundColor={COLOR_RED}
      />
    );
  }
});
