import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_GREY_2,
  COLOR_GREY_8,
  COLOR_LOADING_GREY,
  FONT_COPY,
} from '../Styles/DesignSystem-chancer';

interface IProps {
  summary: string;
  imageUrl: string;
  backgroundColor?: string;
}

export const SummaryCard: React.FC<IProps> = (props) => {
  return (
    <View style={styles.container}>
      <Avatar
        style={styles.avatar}
        imageUrl={props.imageUrl}
        size={32}
        highlightWidth={0}
      />
      <View
        style={[
          styles.copyContainer,
          {backgroundColor: props.backgroundColor ?? COLOR_GREY_2},
        ]}>
        <Text style={styles.copy}>{props.summary}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
  avatar: {
    backgroundColor: COLOR_LOADING_GREY,
    marginRight: 8,
  },
  copyContainer: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0,
    borderRadius: 16,
    padding: 16,
  },
  copy: {
    fontSize: 16,
    fontFamily: FONT_COPY,
    color: COLOR_GREY_8,
  },
});
