import {Reducer} from 'redux';

import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import log from '@chancer/common/lib/utils/Log';
import {ActionType, IAction} from '../../actions/Actions';
import {IMessageDataApi} from '../../state/AppState';
import {handleServerResponse} from '../Reducers';

export const messageReducer: Reducer<IMessageDataApi, IAction<any>> = (
  state: IMessageDataApi | undefined,
  action: IAction<any>,
): IMessageDataApi => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.ENTER_COMP_STATUS_MESSAGE:
      return {
        ...state,
        enterCompStatus: action.payload,
      };

    case ActionType.UPCOMING_COMPETITIONS_MESSAGE:
      return {
        ...state,
        upcomingCompetitions: handleServerResponse(
          state.upcomingCompetitions,
          action,
        ),
      };

    case ActionType.COMPETITION_MESSAGE:
      return {
        ...state,
        competition: handleServerResponse(state.competition, action),
      };

    case ActionType.COMPETITION_SUMMARY_MESSAGE:
      return {
        ...state,
        competitionSummary: handleServerResponse(
          state.competitionSummary,
          action,
        ),
      };

    case ActionType.QUESTIONS_MESSAGE:
      return {
        ...state,
        questions: handleServerResponse(state.questions, action),
      };

    case ActionType.USER_MESSAGE:
      return {
        ...state,
        user: handleServerResponse(state.user, action),
      };

    case ActionType.USER_FOLLOWING_MESSAGE:
      return {
        ...state,
        userFollowing: handleServerResponse(state.userFollowing, action),
      };

    case ActionType.LEADERBOARD_CONFIG_MESSAGE:
      return {
        ...state,
        leaderboardConfiguration: handleServerResponse(
          state.leaderboardConfiguration,
          action,
        ),
      };

    case ActionType.LEADERBOARD_MESSAGE:
      return {
        ...state,
        leaders: handleServerResponse(state.leaders, action),
      };

    case ActionType.COMPETITION_COUNTS_MESSAGE:
      return {
        ...state,
        competitionCounts: handleServerResponse(
          state.competitionCounts,
          action,
        ),
      };

    case ActionType.COMPETITION_VIPS_MESSAGE:
      return {
        ...state,
        competitionVips: handleServerResponse(state.competitionVips, action),
      };

    case ActionType.COMPETITION_STATUS_MESSAGE:
      return {
        ...state,
        competitionStatus: handleServerResponse(
          state.competitionStatus,
          action,
        ),
      };

    case ActionType.COMPETITION_CHAT_MESSAGE:
      return {
        ...state,
        competitionChat: handleServerResponse(state.competitionChat, action),
      };

    case ActionType.COMPETITION_CHAT_READ_DATE:
      return {
        ...state,
        competitionChatRead: handleServerResponse(
          state.competitionChatRead,
          action,
        ),
      };

    case ActionType.USER_ENTRY_MESSAGE:
      if (
        action.payload === null &&
        state.competitionUsersEntry.response !== null
      ) {
        log.debug(
          'Not setting users entry to null if there is a prevous entry.',
        );
        return state;
      }
      return {
        ...state,
        competitionUsersEntry: handleServerResponse(
          state.competitionUsersEntry,
          action,
        ),
      };
    case ActionType.USER_CHALLENGES:
      return {
        ...state,
        userChallenges: handleServerResponse(state.userChallenges, action),
      };
    case ActionType.USER_CHALLENGE_COUNTS:
      return {
        ...state,
        userChallengeCounts: handleServerResponse(
          state.userChallengeCounts,
          action,
        ),
      };
    case ActionType.USER_CHALLENGE_READ_COUNTS:
      return {
        ...state,
        userChallengeReadCounts: handleServerResponse(
          state.userChallengeReadCounts,
          action,
        ),
      };
    case ActionType.PROFILE_IMAGE_UPLOAD:
      return {
        ...state,
        profileImageUpload: action.payload,
      };
    case ActionType.MUTE:
      return {
        ...state,
        mute: action.payload,
      };
    default:
      return state;
  }
};
