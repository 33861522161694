import {IAppState} from '../../state/AppState';
import {getIsLoggingIn} from '../auth/AuthSelectors';
import {
  getQuestions,
  getUsersCompetitionEntryReceived,
  getCompetitionStatus,
} from '../competitions/CompetitionSelectors';
import {createSelector} from 'reselect';

// Leaderboard is at 0, comp home page at 1
export const HOME_PAGE_INDEX = 2;
export const COMP_DETAILS_INDEX = HOME_PAGE_INDEX - 1;
export const LEADER_INDEX = COMP_DETAILS_INDEX - 1;

export const shouldPageNavigationBeAddedToHistory = (
  index: number,
  override?: boolean,
) => {
  if (override !== undefined) {
    return override;
  }
  if (index === HOME_PAGE_INDEX || index === COMP_DETAILS_INDEX) {
    return true;
  }
  return false;
};

export const getIsCompetitionReadyToSetupView = createSelector(
  getIsLoggingIn,
  getQuestions,
  getCompetitionStatus,
  getUsersCompetitionEntryReceived,
  (isLoggingIn, questions, status, receivedUserEntry) => {
    if (isLoggingIn) {
      return false;
    }
    if (questions === null) {
      return false;
    }
    if (status === null) {
      return false;
    }
    return receivedUserEntry;
  },
);

export const getIsViewStateSet = (state: IAppState) =>
  state.viewState.currentPageIndex > -1;

export const getCurrentPageIndex = (state: IAppState) =>
  state.viewState.currentPageIndex;

export const getIsQuestionsRequired = (state: IAppState) =>
  Boolean(state.viewState.isQuestionsRequired);

export const getIsEntryRequired = (state: IAppState) =>
  Boolean(state.viewState.isEntryRequired);

export const getIsAuthRequired = (state: IAppState) =>
  Boolean(state.viewState.isAuthRequired);

export const getIsSignUpNameRequired = (state: IAppState) =>
  Boolean(state.viewState.isSignupNameRequired);

export const getQuestionsToAnswer = (state: IAppState) =>
  state.viewState.questionsToAnswer;
