import React from 'react';
import {StyleSheet} from 'react-native';
import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_GREY_2,
  COLOR_GREY_8,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';

import {IButtonProps, PrimaryButton} from './PrimaryButton';

interface IProps
  extends Omit<
    IButtonProps,
    'size' | 'label' | 'labelStyle' | 'disabledStyle'
  > {
  imageUrl: string | undefined;
}

export const FindMeButton: React.FC<IProps> = (props) => {
  return (
    <PrimaryButton
      style={[styles.container, props.style]}
      labelStyle={styles.label}
      disabledStyle={styles.disabled}
      disabled={props.disabled}
      label="Find Me"
      icon={<Avatar highlightWidth={0} imageUrl={props.imageUrl} size={24} />}
      iconStyle={styles.iconStyle}
      iconSpacingOnRight={false}
      onPress={props.onPress}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    height: 36,
    borderRadius: 8,
    paddingRight: 12,
    paddingLeft: 12,
    backgroundColor: COLOR_GREY_2,
    alignSelf: 'baseline',
  },
  disabled: {
    opacity: 0.6,
  },
  label: {
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 16,
    color: COLOR_GREY_8,
  },
  iconStyle: {
    marginRight: 8,
  },
});
