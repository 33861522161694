export const MILLISECONDS_IN_WEEK: number = 604800000;
export const MILLISECONDS_IN_DAY: number = 86400000;
export const MILLISECONDS_IN_HOUR: number = 3600000;
export const MILLISECONDS_IN_MINUTE: number = 60000;

interface IDurationResponse {
  text: string;
  nextIntervalMs: number;
}

interface IDurationConfig {
  numUnits?: number;
}
const defaultDurationConfig = {
  numUnits: 4,
};

export const getFormattedDuration = (
  durationMs: number,
  config: IDurationConfig = defaultDurationConfig,
): IDurationResponse => {
  if (durationMs > 0) {
    const w = Math.floor(durationMs / MILLISECONDS_IN_WEEK);
    const d = Math.floor(
      (durationMs % MILLISECONDS_IN_WEEK) / MILLISECONDS_IN_DAY,
    );
    const h = Math.floor(
      (durationMs % MILLISECONDS_IN_DAY) / MILLISECONDS_IN_HOUR,
    );
    const m = Math.floor(
      (durationMs % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE,
    );
    const s = Math.floor((durationMs % MILLISECONDS_IN_MINUTE) / 1000);

    const numUnits = config.numUnits || 4;
    let unitCount = 0;
    let nextIntervalMs = 0;
    let time = '';
    if (unitCount < numUnits && w > 0) {
      unitCount++;
      time += `${w}w `;
      nextIntervalMs = MILLISECONDS_IN_MINUTE;
    }
    if (unitCount < numUnits && (w > 0 || d > 0)) {
      unitCount++;
      time += `${d}d `;
      nextIntervalMs = MILLISECONDS_IN_MINUTE;
    }
    if (unitCount < numUnits && (w > 0 || d > 0 || h > 0)) {
      unitCount++;
      time += `${h}h `;
      nextIntervalMs = MILLISECONDS_IN_MINUTE;
    }
    if (unitCount < numUnits && (w > 0 || d > 0 || h > 0 || m > 0)) {
      unitCount++;
      time += `${m}m `;
      nextIntervalMs = MILLISECONDS_IN_MINUTE;
    }
    if (unitCount < numUnits && (w > 0 || d > 0 || h > 0 || m > 0)) {
      unitCount++;
      time += `${s}s `;
      nextIntervalMs = 1000;
    }
    if (unitCount === 0) {
      time += `${s}s `;
      nextIntervalMs = 1000;
    }
    return {
      text: time,
      nextIntervalMs: calculateNextInterval(durationMs, nextIntervalMs),
    };
  } else {
    return {text: '0', nextIntervalMs: 0};
  }
};

const calculateNextInterval = (msRemaining: number, delta: number) => {
  if (msRemaining === 0) {
    return 0;
  }
  msRemaining++;
  let interval = msRemaining % delta;
  if (interval === 0 && msRemaining > 1) {
    interval = delta;
  }
  return interval;
};

interface IStartTimeConfig extends IDurationConfig {
  showDurationFromMs?: number;
  compressedFormat?: boolean;
}

const defaultStartTimeConfig = {
  ...defaultDurationConfig,
  showDurationFromMs: -1,
  compressedFormat: false,
};

export const getFormattedStartTime = (
  starts: Date,
  config: IStartTimeConfig = defaultStartTimeConfig,
): IDurationResponse => {
  const showDurationFromMs = config.showDurationFromMs ?? -1;
  const compressedFormat = config.compressedFormat ?? false;
  const now: Date = new Date();
  const msRemaining = starts.valueOf() - now.valueOf();

  if (showDurationFromMs > 0 && msRemaining <= showDurationFromMs) {
    return getFormattedDuration(msRemaining, config);
  } else {
    const nextIntervalMs =
      showDurationFromMs > 0
        ? Math.min(MILLISECONDS_IN_MINUTE, msRemaining - showDurationFromMs)
        : Math.min(MILLISECONDS_IN_MINUTE, msRemaining);
    return {
      text: `${getTimeInAmPm(starts, now, msRemaining, compressedFormat)}`,
      nextIntervalMs: Math.max(nextIntervalMs, 0),
    };
  }
};

const getTimeInAmPm = (
  d: Date,
  now: Date,
  msRemaining: number,
  compressedFormat: boolean,
) => {
  if (msRemaining > MILLISECONDS_IN_DAY * 6) {
    return getWeekPlusDate(d, compressedFormat);
  } else if (
    d.getDay() - now.getDay() === 1 ||
    d.getDay() - now.getDay() === 6
  ) {
    return getTomorrowFormat(d);
  } else if (d.getDay() === now.getDay()) {
    return getTodayFormat(d);
  } else {
    return getSameWeekFormat(d, compressedFormat);
  }
};

const getWeekPlusDate = (d: Date, compressedFormat: boolean) => {
  if (toLocaleStringSupportsLocales()) {
    if (compressedFormat) {
      return d.toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
      });
    } else {
      return d.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    }
  } else {
    if (compressedFormat) {
      return `${getMonthName(d, true)} ${getMonthDate(d)}`;
    } else {
      return `${getMonthName(d, false)} ${getMonthDate(d)}, ${get12HourTime(
        d,
      )}`;
    }
  }
};

const getSameWeekFormat = (d: Date, compressedFormat: boolean) => {
  if (toLocaleStringSupportsLocales()) {
    return d.toLocaleString('en-US', {
      weekday: compressedFormat ? 'short' : 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } else {
    return `${getWeekdayName(d, compressedFormat)} ${get12HourTime(d)}`;
  }
};

const getTomorrowFormat = (d: Date) => {
  if (toLocaleStringSupportsLocales()) {
    return `Tomorrow ${d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  } else {
    return `Tomorrow  ${get12HourTime(d)}`;
  }
};

const getTodayFormat = (d: Date) => {
  if (toLocaleStringSupportsLocales()) {
    return `Today ${d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  } else {
    return `Today  ${get12HourTime(d)}`;
  }
};

const toLocaleStringSupportsLocales = () => {
  try {
    new Date().toLocaleString('i');
  } catch (e) {
    return (e as Error).name === 'RangeError';
  }
  return false;
};

const get12HourTime = (date: Date) => {
  const hours = date.getHours();
  let hours12 = hours % 12;
  if (hours12 === 0) {
    hours12 = 12;
  }
  const ampm = Math.floor(hours / 12) === 0 ? 'AM' : 'PM';
  const mins = date.getMinutes().toString().padStart(2, '0');

  return `${hours12}:${mins} ${ampm}`;
};

const getWeekdayName = (date: Date, shortFormat = false) => {
  const day = date.getDay();
  const names = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return shortFormat ? names[day].substr(0, 3) : names[day];
};

const getMonthName = (date: Date, shortFormat = false) => {
  const month = date.getMonth();
  const names = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return shortFormat ? names[month].substr(0, 3) : names[month];
};

const getMonthDate = (date: Date) => {
  return date.getDate().toString().padStart(2, '0');
};

export const getFormattedMessageDate = (d: Date): string => {
  const now = new Date();
  const diff = now.valueOf() - d.valueOf();
  if (diff < MILLISECONDS_IN_MINUTE) {
    return 'JUST NOW';
  }
  if (diff < MILLISECONDS_IN_HOUR) {
    const mins = Math.floor(diff / MILLISECONDS_IN_MINUTE);
    return `${mins} MIN${mins > 1 ? 'S' : ''} AGO`;
  }

  if (
    now.getDate() === d.getDate() &&
    now.getMonth() === d.getMonth() &&
    now.getFullYear() === d.getFullYear()
  ) {
    return getTodayFormat(d).toUpperCase();
  } else {
    return getWeekPlusDate(d, true);
  }
};

export const isDayOffset = (
  now: Date,
  date: Date,
  offset: number,
  comparison: 'equals' | 'lessthan' | 'morethan' = 'equals',
) => {
  const offsetDayStart = new Date(
    new Date(now.getTime() + offset * MILLISECONDS_IN_DAY).setHours(0, 0, 0, 0),
  );
  return comparison === 'equals'
    ? date.getFullYear() === offsetDayStart.getFullYear() &&
        date.getMonth() === offsetDayStart.getMonth() &&
        date.getDate() === offsetDayStart.getDate()
    : comparison === 'lessthan'
    ? date < offsetDayStart
    : date > new Date(offsetDayStart.getTime() + MILLISECONDS_IN_DAY); // Needs to be greater than the day after
};
