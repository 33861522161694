import React, {ComponentType} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {ClientStatus} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  TLeaderboardPrize,
  TMediaEntry,
  TTimestamp,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {MILLISECONDS_IN_DAY} from '@chancer/common/lib/utils/DateUtils';
import {platformValue} from '../PlatformUtils';
import {CompPlayers} from '../Status/CompPlayers';
import {CompStatus as CompStatusComponent} from '../Status/CompStatus';
import {
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_COPY_MEDIUM,
  FONT_TITLE,
} from '../Styles/DesignSystem-chancer';
import {LeaderboardImage} from './LeaderboardImage';

interface IProps {
  vendorImageUrl: string | undefined;
  backgroundColor: string;
  title: string;
  subTitle: string;
  players: number;
  status?: {
    startTime: TTimestamp;
    status: ClientStatus;
    questionsRemaining?: number;
    questionsInPlay?: boolean;
  };
  media?: TMediaEntry;
  prizes?: TLeaderboardPrize[];
  MediaComponent: ComponentType<{media: TMediaEntry}>;
  isLive?: boolean;
  onPress?: () => void;
}

export const SummaryHeader: React.FC<IProps> = (props) => {
  const media = props.prizes?.[0]?.media ?? props.media;
  const hasPrizes = (props.prizes && props.prizes.length > 0) ?? false;
  const caption = hasPrizes ? props.prizes?.[0]?.media.caption : '';

  const styles = getStyles(hasPrizes);

  return (
    <View style={styles.container}>
      <Pressable style={styles.contentContainer} onPress={props.onPress}>
        <LeaderboardImage
          style={styles.imageContainer}
          containerStyle={styles.foregroundContainer}
          media={media}
          backgroundColor={props.backgroundColor}
          MediaComponent={props.MediaComponent}>
          <View style={styles.header}>
            {props.status ? (
              <CompStatusComponent
                {...props.status}
                featured={true}
                showDurationFromMs={MILLISECONDS_IN_DAY * 6}
                compressedFormat={true}
                showClock={true}
                isLive={props.isLive}
              />
            ) : (
              <View />
            )}
            <CompPlayers withIcon={true} count={props.players} />
          </View>
          <View style={styles.footer}>
            <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
              {props.title}
            </Text>
            <Text
              style={styles.subTitle}
              numberOfLines={1}
              ellipsizeMode="tail">
              {props.subTitle}
            </Text>
            <Text style={styles.caption}>{caption}</Text>
          </View>
        </LeaderboardImage>
      </Pressable>
      {/* <Avatar
        style={styles.titleImage}
        imageUrl={props.vendorImageUrl}
        size={32}
        radius={8}
        highlightWidth={0}
      /> */}
    </View>
  );
};

const getStyles = (hasPrizes: boolean) =>
  StyleSheet.create({
    container: {flex: 1, alignItems: 'center', flexDirection: 'column-reverse'},
    titleImage: {marginBottom: -20},
    contentContainer: {
      flex: 1,
      width: '100%',
    },
    imageContainer: {
      position: 'relative',
      alignItems: 'center',
      height: hasPrizes ? 224 : 128,
      aspectRatio: platformValue(undefined, {web: 1 / 1}),
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    foregroundContainer: {
      paddingHorizontal: 16,
      paddingTop: 16,
      paddingBottom: 16,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    header: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    footer: {
      width: '100%',
      alignItems: 'center',
    },
    title: {
      color: COLOR_LIGHT,
      fontFamily: FONT_TITLE,
      fontSize: 21,
    },
    subTitle: {
      color: COLOR_GREY_8,
      fontFamily: FONT_COPY_MEDIUM,
      marginTop: 2,
      fontSize: 16,
    },
    caption: {
      marginTop: 8,
      color: COLOR_GREY_8,
      fontFamily: FONT_COPY,
      textAlign: 'center',
      fontSize: 14,
    },
  });
