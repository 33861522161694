import React from 'react';
import {StyleSheet} from 'react-native';
import {
  COLOR_GREY_2,
  COLOR_GREY_6,
  COLOR_LIGHT,
  COLOR_PURPLE,
} from '../Styles/DesignSystem-chancer';

import {IButtonProps, PrimaryButton} from './PrimaryButton';

export const PrimaryGroupsButton: React.FC<IButtonProps> = (props) => {
  return (
    <PrimaryButton
      style={[styles.container, props.style]}
      size={props.size}
      labelStyle={styles.label}
      disabledStyle={[styles.disabledContainer, props.disabledStyle]}
      disabledLabelStyle={[styles.disabledLabel, props.disabledLabelStyle]}
      disabled={props.disabled}
      label={props.label}
      onPress={props.onPress}>
      {props.children}
    </PrimaryButton>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_PURPLE,
  },
  disabledContainer: {
    backgroundColor: COLOR_GREY_2,
  },
  disabledLabel: {
    color: COLOR_GREY_6,
  },
  label: {
    color: COLOR_LIGHT,
  },
});
