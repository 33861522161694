import React from 'react';
import {GestureResponderEvent, Pressable, StyleSheet} from 'react-native';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  children?: React.ReactNode;
  onPress?: ((event: GestureResponderEvent) => void) | null | undefined;
}

export const IconButton: React.FC<IProps> = (props) => {
  return (
    <Pressable onPress={props.onPress} style={[styles.container, props.style]}>
      {props.children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 44,
    minHeight: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
