import React from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';

import {COLOR_LIGHT, FONT_COPY_MEDIUM} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {HeaderImage} from './HeaderImage';

interface IProps {
  style?: CompositedViewStyle;
  image?: string;
  title?: string;
  onPress: () => void;
}

export const TitleHeader: React.FC<IProps> = (props) => {
  return (
    <Pressable style={props.style} onPress={props.onPress}>
      <HeaderImage
        style={[styles.image, props.style]}
        onPress={props.onPress}
        url={props.image}>
        <Text ellipsizeMode="tail" numberOfLines={3} style={styles.title}>
          {props.title}
        </Text>
      </HeaderImage>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  bar: {
    marginBottom: 8,
  },
  image: {
    aspectRatio: 16 / 9,
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  title: {
    flexGrow: 0,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 18,
  },
});
