import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {TLiveScoreSummary} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {LiveScore} from '../LiveScore/LiveScore';
import {
  COLOR_DARK,
  COLOR_LIGHT,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  children?: React.ReactNode;
  style?: CompositedViewStyle;
  safeAreaTopPadding: number;
  color: string;
  title?: string;
  appPlatform: AppPlatform;
  fromColor?: string;
  liveScore?: TLiveScoreSummary;
}

export const SimpleHeader: React.FC<IProps> = (props) => {
  const {style, safeAreaTopPadding} = props;

  const styles = useMemo(
    () => getStyles(props.appPlatform),
    [props.appPlatform],
  );

  const containerStyle = useMemo(
    () => [styles.container, style, {paddingTop: safeAreaTopPadding + 14}],
    [style, styles, safeAreaTopPadding],
  );
  return (
    <LinearGradient
      style={containerStyle}
      colors={[props.color, props.fromColor ?? COLOR_DARK]}
      locations={[0.33, 1]}>
      {props.liveScore ? (
        <LiveScore liveScore={props.liveScore} style={styles.liveScore} />
      ) : props.title ? (
        <View style={styles.labelContainer}>
          <Text style={styles.label} numberOfLines={1} ellipsizeMode="tail">
            {props.title}
          </Text>
        </View>
      ) : null}
      {props.children}
    </LinearGradient>
  );
};

const getStyles = (appPlatform: AppPlatform) =>
  StyleSheet.create({
    container: {
      minHeight: 96,
      paddingHorizontal: 16,
      paddingBottom: 16,
      alignItems: 'center',
    },
    labelContainer: {
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 4,
      marginBottom: 16,
      marginLeft: appPlatform === AppPlatform.ANDROID ? 96 : 72,
      paddingRight: appPlatform === AppPlatform.ANDROID ? 96 : 72,
    },
    liveScore: {
      marginBottom: 16,
      paddingLeft: appPlatform === AppPlatform.ANDROID ? 96 : 72,
      paddingRight: 104 + (appPlatform === AppPlatform.ANDROID ? 96 : 72),
    },
    label: {color: COLOR_LIGHT, fontFamily: FONT_COPY_MEDIUM, fontSize: 16},
  });
