import React, {ReactElement} from 'react';
import {StyleSheet, View} from 'react-native';
import {TabButton} from '../Button/TabButton';

interface ITabValue {
  label: string;
  count?: number;
}

interface IProps<T> {
  data: T[];
  selectedIndex: number;
  disabledIndexes?: number[];
  color?: string;
  backgroundColor?: string;
  hideBadgeLabels?: boolean;
  onTabSelected: (index: number) => void;
}

export const Tabs = <T extends ITabValue>(
  props: IProps<T>,
): ReactElement<IProps<T>, any> | null => {
  return (
    <View style={[styles.container, {borderBottomColor: props.color}]}>
      {props.data.map((item, i) => (
        <TabButton
          key={`tab${i}`}
          color={props.color}
          backgroundColor={props.backgroundColor}
          label={item.label}
          count={item.count}
          selected={props.selectedIndex === i}
          disabled={props.disabledIndexes?.includes(i)}
          hideBadgeLabel={props.hideBadgeLabels}
          onPress={() => props.onTabSelected(i)}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    borderBottomWidth: 1,
  },
});
