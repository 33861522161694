// Enables strict type checking on an object that is never assigned to a specific variable.

import {Static, TSchema, Type} from '@sinclair/typebox';
import {Value} from '@sinclair/typebox/value';

// For instance when passing an object into a firestore document ref.
export const assertType = <T>(x: T): T => x;

export const cleanCast = <T extends TSchema>(
  schema: T,
  value: unknown,
): Static<T> => {
  return Value.Cast(
    Type.Composite([schema], {additionalProperties: false}),
    value,
  );
};
