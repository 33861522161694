import {createSelector} from 'reselect';

import {IAppState} from '../../state/AppState';
import {getAuthUser} from '../auth/AuthSelectors';

import {
  TFirebaseUser,
  TFirebaseUserFollowList,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {TMediaEntry} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getIsImage} from '@chancer/common/lib/utils/MediaUtils';

export const getLocalUser = (state: IAppState) => state.localUser;

export const getUserId = createSelector(getAuthUser, (authUser) => {
  if (authUser) {
    return authUser.uid;
  }
  return null;
});

export const getUserResponse = (state: IAppState) =>
  state.messageData.user.response;

export const getUser = createSelector(
  getUserId,
  getUserResponse,
  (id, user): TFirebaseUser | null => {
    if (id && user) {
      return {...user, id: id, path: `/users/${id}`};
    }
    return null;
  },
);

export const getUserName = createSelector(getUser, (user) =>
  user !== null ? user.name : null,
);

export const getLatestUserName = createSelector(
  getUser,
  getLocalUser,
  (user, localUser) => {
    if (localUser.name) {
      return localUser.name;
    } else {
      if (user && user.name) {
        return user.name;
      }
    }
    return null;
  },
);

export const getUserImageUrl = createSelector(
  getAuthUser,
  getUser,
  (authUser, user) => {
    if (user) {
      if (getIsImage(user.media)) {
        return user.media.image.url;
      } else {
        return null;
      }
    }
    if (authUser) {
      return authUser.photoURL;
    }
    return null;
  },
);

export const getLatestUserInfo = createSelector(
  getLocalUser,
  getUserId,
  getUser,
  getUserImageUrl,
  (localUser, id, user, imageUrl): TFirebaseUser => {
    let userId = id ?? '';
    let media: TMediaEntry = imageUrl !== null ? {image: {url: imageUrl}} : {};
    if (localUser.name === null || localUser.name === '') {
      if (user) {
        localUser.name = user.name;
      } else {
        localUser.name = '';
      }
    }

    const spread =
      user !== null
        ? user
        : {
            id: userId,
            path: `/users/${userId}`,
            achievements: [],
            vendorLeaders: {},
            vendorAchievements: {},
          };

    return {
      ...spread,
      name: localUser.name,
      media: media,
    };
  },
);

export const getUserIsFollowing = (
  state: IAppState,
): TFirebaseUserFollowList => {
  if (state.messageData.userFollowing.response) {
    return state.messageData.userFollowing.response;
  }
  return {
    id: 'following',
    path: `users/${getUserId(state)}/user_profile/following`,
    userIds: [],
  };
};

export const getUserIsFollowingAsMap = createSelector(
  getUserIsFollowing,
  (following) =>
    following.userIds.reduce((a: {[key: string]: boolean}, b) => {
      a[b] = true;
      return a;
    }, {}),
);

const getFollowedUsersEntries = (state: IAppState) => state.followedEntries;

export const getFollowedUsersAnswersByQuestion = createSelector(
  getFollowedUsersEntries,
  (userEntries) => {
    return userEntries.reduce(
      (acc: {[questionId: string]: TFirebaseUser[][]}, entry, index) => {
        const answers = entry.entry.answers;
        Object.keys(answers).forEach((questionId) => {
          const answer = answers[questionId];
          let question = acc[questionId];
          if (!question) {
            question = [];
            acc[questionId] = question;
          }
          let selectedAnswer = question[answer];
          if (!selectedAnswer) {
            for (let j = 0; j < answer + 1; j++) {
              if (!question[j]) {
                question[j] = [];
              }
            }
            selectedAnswer = [];
            question[answer] = selectedAnswer;
          }
          selectedAnswer.push(entry.user);
        });
        return acc;
      },
      {},
    );
  },
);
