import React, {useEffect, useMemo, useState} from 'react';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';
import {Observable, Subscription} from 'rxjs';

import {TOpenGraph} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  COLOR_BLUE,
  COLOR_GREY_2,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_COPY_BOLD,
} from '../Styles/DesignSystem-chancer';
import {CompositedImageStyle, CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  containerStyle?: CompositedViewStyle;
  imageStyle?: CompositedImageStyle;
  url: string;
  getOpenGraphStream: (url: string) => Observable<TOpenGraph>;
  getHostname: (url: string) => string | undefined;
  onPress: (url: string | undefined) => void;
}

export const OpenGraphCard: React.FC<IProps> = (props) => {
  const {url, getOpenGraphStream, getHostname} = props;

  const [openGraph, setOpenGraph] = useState<TOpenGraph | null>(null);

  useEffect(() => {
    let sub: Subscription;
    if (url && openGraph === null) {
      sub = getOpenGraphStream(url).subscribe({
        next: (og) => {
          setOpenGraph(og);
        },
      });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [openGraph, getOpenGraphStream, url]);

  const canRender = useMemo(
    () => openGraph !== null && openGraph.url && openGraph.image,
    [openGraph],
  );

  const hostname = useMemo(() => {
    if (openGraph?.url) {
      return getHostname(openGraph.url);
    }

    return undefined;
  }, [openGraph, getHostname]);

  return canRender ? (
    <Pressable
      style={[styles.container, props.containerStyle]}
      onPress={() => props.onPress(openGraph?.url)}>
      <View style={styles.headerContainer}>
        <Image
          style={[styles.image, props.imageStyle]}
          resizeMode="cover"
          source={{uri: openGraph?.image}}
        />
      </View>
      <View style={styles.footerContainer}>
        <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
          {openGraph?.title}
        </Text>
        <Text style={styles.hostname} numberOfLines={1} ellipsizeMode="tail">
          {hostname}
        </Text>
        <Text style={styles.description} numberOfLines={2} ellipsizeMode="tail">
          {openGraph?.description}
        </Text>
      </View>
    </Pressable>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    height: 240,
    width: 240,
    borderRadius: 16,
    backgroundColor: COLOR_GREY_2,
    marginTop: 2,
  },
  headerContainer: {
    height: 120,
  },
  image: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  footerContainer: {
    flex: 1,
    flexDirection: 'column',
    marginVertical: 8,
    marginHorizontal: 8,
    alignItems: 'flex-start',
  },
  title: {
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
    color: COLOR_LIGHT,
    overflow: 'hidden',
  },
  hostname: {
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
    color: COLOR_BLUE,
    overflow: 'hidden',
  },
  description: {
    fontFamily: FONT_COPY,
    fontSize: 16,
    color: COLOR_LIGHT,
    overflow: 'hidden',
  },
});
