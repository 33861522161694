import {Reducer} from 'react';

import {ActionType, IAction} from '../../actions/Actions';
import {IFollowedEntry} from '../../state/AppState';

export const followedEntriesReducer: Reducer<
  IFollowedEntry[],
  IAction<IFollowedEntry>
> = (state, action) => {
  if (state === undefined) {
    state = [];
  }
  switch (action.type) {
    case ActionType.FOLLOWED_ENTRY:
      return [
        ...state.filter((e) => e.user.id !== action.payload.user.id),
        action.payload,
      ];
  }
  return state;
};
