import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {Platform} from 'react-native';

export const platformValue = <T>(
  defaultValue: T,
  platformValues: {[key in 'ios' | 'android' | 'web']?: T},
  forcePlatform?: AppPlatform,
): T => {
  let p = Platform.OS;
  if (forcePlatform) {
    if (forcePlatform === AppPlatform.IOS) {
      p = 'ios';
    } else if (forcePlatform === AppPlatform.ANDROID) {
      p = 'android';
    } else if (forcePlatform === AppPlatform.WEB) {
      p = 'web';
    }
  }
  return (platformValues as any)[p] ?? defaultValue;
};
