import React, {useMemo} from 'react';
import {Text, StyleSheet, View} from 'react-native';

import {COLOR_LIGHT, FONT_SUB_TITLE} from '../Styles/DesignSystem-chancer';

type ScoreSize = 'small' | 'medium' | 'large';
interface IProps {
  size?: ScoreSize;
  score: number;
}

export const MultiGameScore: React.FC<IProps> = (props) => {
  const {size} = props;
  const styles = useMemo(() => getStyles(size ?? 'medium'), [size]);
  const formattedScore = useMemo(() => {
    return props.score.toLocaleString('en-us', {maximumFractionDigits: 2});
  }, [props.score]);

  return (
    <View style={styles.container}>
      <Text style={styles.score} numberOfLines={1} ellipsizeMode="tail">
        {formattedScore}
      </Text>
    </View>
  );
};

const getStyles = (size: ScoreSize) =>
  StyleSheet.create({
    container: {
      pointerEvents: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    score: {
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'right',
      userSelect: 'none',
      color: COLOR_LIGHT,
      fontFamily: FONT_SUB_TITLE,
      fontSize: size === 'small' ? 11 : size === 'medium' ? 16 : 21,
    },
  });
