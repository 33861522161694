import {Reducer} from 'redux';

import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import {IAction} from '../actions/Actions';
import {IAppState, IApiData} from '../state/AppState';
import {authReducer} from './auth/AuthReducer';
import {localUserReducer} from './user/LocalUserReducer';
import {localAnswersReducer} from './answers/LocalAnswersReducer';
import {messageReducer} from './message/MessageReducer';
import {configReducer} from './config/ConfigReducers';
import {viewStateReducer} from './view/ViewStateReducer';
import {followedEntriesReducer} from './user/FollowedEntriesReducer';

export const rootReducer: Reducer<IAppState, IAction<any>> = (
  state: IAppState | undefined,
  action: IAction<any>,
): IAppState => {
  if (!state) {
    throw undefinedInReducerError;
  }
  return {
    auth: authReducer(state.auth, action),
    config: configReducer(state.config, action),
    localAnswers: localAnswersReducer(state.localAnswers, action),
    localUser: localUserReducer(state.localUser, action),
    followedEntries: followedEntriesReducer(state.followedEntries, action),
    messageData: messageReducer(state.messageData, action),
    viewState: viewStateReducer(state.viewState, action),
  };
};

/**
 * Generic server request handler for updating an IApiData instance
 */
export const handleServerRequest = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: null,
    isInvalidated: false,
    isUpdating: true,
    request: processPayload(action.payload),
  };
};

/**
 * Generic server response handler for updating an IApiData instance
 */
export const handleServerResponse = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: null,
    isInvalidated: false,
    isUpdating: false,
    lastUpdated: new Date(),
    response: processPayload(action.payload),
  };
};

/**
 * Generic server error handler for updating an IApiData instance
 */
export const handleServerError = (
  state: IApiData<any, any, any>,
  action: IAction<any>,
): IApiData<any, any, any> => {
  return {
    ...state,
    error: processPayload(action.payload),
    isInvalidated: false,
    isUpdating: false,
    lastUpdated: new Date(),
    response: null,
  };
};

const processPayload = (payload: any): any => {
  const responseType: string = typeof payload;
  let response: any;
  if (
    responseType === 'string' ||
    responseType === 'number' ||
    responseType === 'boolean' ||
    responseType === 'undefined' ||
    payload === null
  ) {
    response = payload;
  } else if (Array.isArray(payload)) {
    response = [...payload];
  } else {
    response = {...payload};
  }
  return response;
};
