import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Observable, Subscription} from 'rxjs';

import {ClientStatus} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {AchievementType} from '@chancer/common/lib/interfaces/achievements/Achievements';
import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {TLeader} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  IColorConfiguration,
  getColorConfiguration,
} from '@chancer/common/lib/utils/ColorUtils';
import {QuestionAnswerState} from '../Answers/QuestionAnswer';
import {LeaderboardAvatar} from '../Avatar/LeaderboardAvatar';
import {LabelBadge} from '../Status/LabelBadge';
import {UserScores} from '../Status/UserScores';
import {
  COLOR_DARK,
  COLOR_GREEN,
  COLOR_GREY_6,
  COLOR_LIGHT,
  COLOR_RED,
  COLOR_YELLOW,
  FONT_COPY_BOLD,
  FONT_COPY_MEDIUM,
  FONT_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {
  AchievementWinnerIcon,
  LeaderDownArrowIcon,
  LeaderUpArrowIcon,
  VendorScoreIcon,
} from '../Svg/SvgIcons';
import {Score} from './Score';

export interface IEntrantItemProps {
  position: number;
  visible?: boolean;
  colorConfig?: IColorConfiguration;
  player: TLeader;
  isCurrentUser: boolean;
  user: TFirebaseUser | null;
  compVendor: string;
  compStatus: ClientStatus | null;
  winner: boolean;
  isFollowing: boolean;
  isSummary: boolean;
  achievements?: number[];
  entryBreakdown?: EntryBreakdown;
  getUserById: (userId: string) => Observable<TFirebaseUser>;
  onPress?: (user: TFirebaseUser) => void;
}

export type EntryBreakdown = {
  [key in QuestionAnswerState]: number;
};

export const EntrantItem: React.FC<IEntrantItemProps> = (props) => {
  const {
    getUserById,
    onPress,
    isCurrentUser,
    entryBreakdown,
    colorConfig = getColorConfiguration(undefined),
  } = props;
  const visible = props.visible ?? true;
  const userId = props.player.u;

  const [user, setUser] = useState<TFirebaseUser | null>(props.user);

  const isOpen = useMemo(() => {
    return (
      props.compStatus === ClientStatus.OPEN ||
      props.compStatus === ClientStatus.PRE_START
    );
  }, [props.compStatus]);

  // Deal with other users
  useEffect(() => {
    let sub: Subscription | undefined;
    if (visible && user === null) {
      sub = getUserById(userId).subscribe({
        next: (u) => {
          setUser(u);
        },
      });
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [visible, isCurrentUser, userId, user, getUserById]);

  const showBreakdown = useMemo(() => {
    return isCurrentUser && entryBreakdown;
  }, [isCurrentUser, entryBreakdown]);

  const contextualContainer: CompositedViewStyle = useMemo(
    () => [
      styles.container,
      ...(isCurrentUser
        ? [{backgroundColor: colorConfig.leaderboardUsersItemBackground}]
        : [{backgroundColor: colorConfig.leaderboardItemsBackground}]),
      showBreakdown ? {height: 80} : isOpen ? {paddingLeft: 16} : undefined,
    ],
    [isCurrentUser, showBreakdown, isOpen, colorConfig],
  );

  const _onPress = useCallback(() => {
    if (user !== null && onPress) {
      onPress(user);
    }
  }, [user, onPress]);

  const _wrapWithWinner = (
    component: ReactElement<any, any>,
    isWinner: boolean,
  ) => {
    if (isWinner) {
      return (
        <View style={styles.winnerContainer}>
          <Text style={styles.winnerHeader}>WINNER</Text>
          {component}
        </View>
      );
    }
    return component;
  };
  return _wrapWithWinner(
    <Pressable
      style={contextualContainer}
      disabled={onPress === undefined}
      onPress={_onPress}>
      {visible && (
        <>
          <View style={styles.positionContainer}>
            {!isOpen && <Text style={styles.position}>{props.position}</Text>}
            {!isOpen && (
              <View style={styles.arrowContainer}>
                {props.player.d !== undefined && props.player.d > 0 && (
                  <LeaderUpArrowIcon
                    color={COLOR_GREEN}
                    width={10}
                    height={14}
                  />
                )}
                {props.player.d !== undefined && props.player.d < 0 && (
                  <LeaderDownArrowIcon
                    color={COLOR_RED}
                    width={10}
                    height={14}
                  />
                )}
              </View>
            )}
          </View>
          <LeaderboardAvatar
            user={user}
            isWinner={props.winner}
            isFollowing={props.isFollowing}
            isCurrentUser={isCurrentUser}
          />
          <View style={styles.copyContainer}>
            <View style={styles.nameContainer}>
              <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
                {user?.name}
              </Text>
              {isCurrentUser && (
                <LabelBadge
                  labelColor={COLOR_DARK}
                  backgroundColor={COLOR_YELLOW}
                  label="YOU"
                  style={styles.currentUserBadge}
                />
              )}
              {user?.vendorLeaders?.[props.compVendor] && (
                <>
                  <VendorScoreIcon color={COLOR_GREY_6} />
                  <Text style={styles.achievementValue}>
                    {user?.vendorLeaders?.[props.compVendor].s}
                  </Text>
                </>
              )}
              {user?.vendorAchievements?.[props.compVendor]?.[
                AchievementType.WINNER
              ] !== undefined &&
                user?.vendorAchievements?.[props.compVendor][
                  AchievementType.WINNER
                ] > 0 && (
                  <>
                    <View style={styles.achievementIcon}>
                      <AchievementWinnerIcon
                        color={COLOR_GREY_6}
                        width={12}
                        height={14}
                      />
                    </View>
                    <Text style={styles.achievementValue}>
                      {user?.vendorAchievements?.[props.compVendor][0]}
                    </Text>
                  </>
                )}
            </View>
            {(!isCurrentUser ||
              props.compStatus === ClientStatus.OPEN ||
              props.compStatus === ClientStatus.PRE_START) && (
              <Text
                style={styles.tagline}
                numberOfLines={1}
                ellipsizeMode="tail">
                {getTagline(user)}
              </Text>
            )}
            {showBreakdown && (
              <View style={styles.breakdownContainer}>
                <UserScores
                  correctCount={
                    entryBreakdown?.[QuestionAnswerState.CORRECT] ?? 0
                  }
                  incorrectCount={
                    entryBreakdown?.[QuestionAnswerState.WRONG] ?? 0
                  }
                  pendingCount={
                    (entryBreakdown?.[QuestionAnswerState.IN_PLAY] ?? 0) +
                    (entryBreakdown?.[QuestionAnswerState.OPEN] ?? 0) +
                    (entryBreakdown?.[QuestionAnswerState.CHANGEABLE] ?? 0)
                  }
                />
              </View>
            )}
          </View>
          {props.compStatus !== ClientStatus.OPEN &&
            props.compStatus !== ClientStatus.PRE_START && (
              <Score score={props.player.s} />
            )}
        </>
      )}
    </Pressable>,
    props.winner,
  );
};

const styles = StyleSheet.create({
  container: {
    userSelect: 'none',
    position: 'relative',
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    marginTop: 2,
  },
  positionContainer: {
    marginTop: 13,
    marginBottom: 16,
    alignSelf: 'flex-start',
  },
  position: {
    flexShrink: 0,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
    width: 36,
    textAlign: 'center',
    alignSelf: 'flex-start',
  },
  arrowContainer: {
    width: 36,
    alignItems: 'center',
  },
  copyContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginLeft: 12,
    marginRight: 8,
    overflow: 'hidden',
  },
  nameContainer: {
    flexDirection: 'row',
    marginTop: 13,
    overflow: 'hidden',
    alignItems: 'center',
  },
  name: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
    marginRight: 8,
  },
  currentUserBadge: {alignSelf: 'flex-end', marginRight: 8, marginBottom: 1},
  achievementIcon: {
    marginTop: 2,
    marginLeft: 6,
  },
  achievementValue: {
    flexGrow: 0,
    marginLeft: 2,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    color: COLOR_GREY_6,
  },
  tagline: {
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    marginRight: 4,
  },
  winnerContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: COLOR_YELLOW,
    borderRadius: 3,
  },
  winnerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 64,
    backgroundColor: COLOR_YELLOW,
    padding: 8,
    fontFamily: FONT_TITLE,
    fontSize: 24,
  },
  breakdownContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
  },
});

export const getTagline = (u: TFirebaseUser | null): string => {
  let data = '';
  if (u) {
    data += `${u.tagline ?? ''}`;
    data += `${u.team && u.tagline ? ' ‧ ' : ''}`;
    data += `${u.team ?? ''}`;
    data += `${u.hometown && (u.team || u.tagline) ? ' ‧ ' : ''}`;
    data += `${u.hometown ?? ''}`;
  }
  return data;
};
