// TODO: Remove this file. We aren't using sky and it's redundant

export const COLOR_DARK = '#000000';
export const COLOR_DARK_2 = 'rgba(0, 0, 0, 0.2)';
export const COLOR_DARK_4 = 'rgba(0, 0, 0, 0.4)';
export const COLOR_DARK_6 = 'rgba(0, 0, 0, 0.6)';
export const COLOR_DARK_8 = 'rgba(0, 0, 0, 0.8)';
export const COLOR_LIGHT = '#ffffff';
export const COLOR_GREY_1 = 'rgba(255, 255, 255, 0.1)';
export const COLOR_GREY_2 = 'rgba(255, 255, 255, 0.2)';
export const COLOR_GREY_4 = 'rgba(255, 255, 255, 0.4)';
export const COLOR_GREY_6 = 'rgba(255, 255, 255, 0.6)';
export const COLOR_GREY_8 = 'rgba(255, 255, 255, 0.8)';
export const COLOR_LOADING_GREY = '#222222';
export const COLOR_BLUE = '#36a5f0';
export const COLOR_DARK_BLUE = '#00a8e0';
export const COLOR_PINK = '#fd416a';
export const COLOR_RED = '#FD416A';
export const COLOR_GREEN = '#63b700';
export const COLOR_PURPLE = '#445ff0';
export const COLOR_YELLOW = '#f6b700';
export const COLOR_YELLOW_50 = '#bc8c00';

export const FONT_TITLE = 'BlackSans-Black';
export const FONT_SUB_TITLE = 'BlackSans-Bold';
export const FONT_COPY = 'BlackSans-CondensedBook';
export const FONT_COPY_MEDIUM = 'BlackSans-CondensedMedium';
export const FONT_COPY_BOLD = 'BlackSans-CondensedBold';
