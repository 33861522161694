import {createSelector} from 'reselect';
import semverGte from 'semver/functions/gte';
import {getConfig, getRemoteConfig} from '../config/ConfigSelectors';
import {RemoteConfigBoolean} from '@chancer/common/lib/core/config/RemoteConfig';

export const getIsCompetitionChatEnabled = createSelector(
  getConfig,
  (config): boolean => {
    return (
      !config.isMobile ||
      (config.version !== undefined && semverGte(config.version, '1.22.11'))
    );
  },
);

export const getIsChallengesEnabled = createSelector(
  getConfig,
  getRemoteConfig,
  (config, remoteConfig): boolean => {
    return (
      remoteConfig.feature_challenges_enabled === RemoteConfigBoolean.TRUE &&
      (!config.isMobile ||
        (config.version !== undefined && semverGte(config.version, '1.32.0')))
    );
  },
);
