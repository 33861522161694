import {Reducer} from 'redux';

import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import {ActionType, IAction} from '../../actions/Actions';
import {IViewState} from '../../state/AppState';

export const viewStateReducer: Reducer<IViewState, IAction<any>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.SET_INITIAL_VIEW_STATE:
      return action.payload;
    case ActionType.SET_PAGE_INDEX:
      return {...state, currentPageIndex: action.payload};
  }
  return state;
};
