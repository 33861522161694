// https://www.kelche.co/blog/web/fingerprinting/
// https://gist.github.com/abrahamjuliot/7baf3be8c451d23f7a8693d7e28a35e2

export const getFingerprint = () => {
  return btoa(
    JSON.stringify({
      screenResolution: getScreenResolution(),
      colorDepth: getColorDepth(),
      availableScreenResolution: getAvailableScreenResolution(),
      pixelRatio: getPixelRatio(),
      timezoneOffset: getTimezoneOffset(),
      sessionStorage: getSessionStorage(),
      localStorage: getLocalStorage(),
      indexedDB: getIndexedDB(),
      cookiesEnabled: getCookiesEnabled(),
      touchSupport: getTouchSupport(),
      languages: getLanguages(),
      doNotTrack: getDoNotTrack(),
      hardwareConcurrency: getHardwareConcurrency(),
      platform: getPlatform(),
      plugins: getPlugins(),
      pdfViewerEnabled: getPdfViewerEnabled(),
      forcedColors: getForcedColors(),
    }),
  );
};

const getScreenResolution = () => {
  return `${window.screen.width} x ${window.screen.height}`;
};

const getColorDepth = () => screen.colorDepth;

const getAvailableScreenResolution = () => {
  return `${screen.availWidth}x${screen.availHeight}`;
};

const getPixelRatio = () => window.devicePixelRatio;

const getTimezoneOffset = () => new Date().getTimezoneOffset();

const getSessionStorage = () => {
  try {
    return !!window.sessionStorage;
  } catch (e) {
    return true;
  }
};

const getLocalStorage = () => {
  try {
    return !!window.localStorage;
  } catch (e) {
    return true;
  }
};

const getIndexedDB = () => {
  try {
    return !!window.indexedDB;
  } catch (e) {
    return true;
  }
};

const getCookiesEnabled = () => navigator.cookieEnabled;

const getTouchSupport = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const getLanguages = () => navigator.languages;

const getDoNotTrack = () => {
  if (navigator.doNotTrack) {
    return navigator.doNotTrack;
  } else {
    return 'unknown';
  }
};

const getHardwareConcurrency = () => navigator.hardwareConcurrency;

const getPlatform = () => navigator.platform;

const getPlugins = () => {
  return Array.from(navigator.plugins).map((plugin) => plugin.name);
};

const getPdfViewerEnabled = () => navigator.pdfViewerEnabled;

const getForcedColors = () => {
  return window.matchMedia('(forced-colors)').matches;
};
