import {IUploadingMediaPayload} from '@chancer/common/lib/app/AppMessage';
import {IRemoteConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {IAuthUserInfo} from '@chancer/common/lib/interfaces/auth/AuthInterfaces';
import {
  EnterCompStatus,
  IAppConfig,
  ILocalAnswersForComp,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseChallengeGroup,
  TFirebaseChatMessage,
  TFirebaseComp,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompStatus,
  TFirebaseCompSummary,
  TFirebaseGroupCounts,
  TFirebaseLeaderboard,
  TFirebaseLeaders,
  TFirebaseQuestion,
  TFirebaseTempEntry,
  TFirebaseUser,
  TFirebaseUserFollowList,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';

export const defaultAppState = (): IAppState => ({
  auth: {
    isLogginIn: true,
    user: null,
    temporaryUserScore: null,
  },
  config: {
    config: null,
    remoteConfig: null,
  },
  localAnswers: null,
  localUser: {
    name: null,
    imageUrl: null,
  },
  followedEntries: [],
  messageData: {
    enterCompStatus: EnterCompStatus.NOT_ENTERED,
    upcomingCompetitions: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competition: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competitionSummary: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competitionCounts: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competitionVips: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competitionStatus: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competitionChat: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competitionChatRead: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    competitionUsersEntry: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    leaderboardConfiguration: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    leaders: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    questions: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    user: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    userFollowing: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    userChallenges: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    userChallengeCounts: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    userChallengeReadCounts: {
      error: null,
      isInvalidated: false,
      isUpdating: false,
      lastUpdated: null,
      request: null,
      response: null,
    },
    profileImageUpload: {
      imageUploadProgress: 0,
      localImageBeingUploaded: null,
    },
    mute: false,
  },
  viewState: {
    currentPageIndex: -1,
    isAuthRequired: null,
    isEntryRequired: null,
    isQuestionsRequired: null,
    isSignupNameRequired: null,
    questionsToAnswer: [],
  },
});

export interface IAppState {
  auth: IAuth;
  config: {
    config: IAppConfig | null;
    remoteConfig: IRemoteConfig | null;
  };
  localAnswers: ILocalAnswersForComp | null;
  localUser: ILocalUser;
  followedEntries: IFollowedEntry[];
  messageData: IMessageDataApi;
  viewState: IViewState;
}

export interface IFollowedEntry {
  user: TFirebaseUser;
  entry: TFirebaseCompEntry;
}

export interface IAuth {
  isLogginIn: boolean;
  user: IAuthUserInfo | null;
  temporaryUserScore: number | null;
}

export interface ILocalUser {
  name: string | null;
  imageUrl: string | null;
}

export interface IMessageDataApi {
  enterCompStatus: EnterCompStatus;
  upcomingCompetitions: IApiData<string, ICompSummaryAndEntry[], string>;
  competition: IApiData<string, TFirebaseComp, string>;
  competitionSummary: IApiData<string, TFirebaseCompSummary, string>;
  questions: IApiData<string, TFirebaseQuestion[], string>;
  user: IApiData<string, TFirebaseUser, string>;
  userFollowing: IApiData<string, TFirebaseUserFollowList, string>;
  userChallenges: IApiData<string, TFirebaseChallengeGroup[], string>;
  userChallengeCounts: IApiData<
    string,
    {[challengeId: string]: TFirebaseGroupCounts},
    string
  >;
  userChallengeReadCounts: IApiData<
    string,
    {[challengeId: string]: number},
    string
  >;
  leaderboardConfiguration: IApiData<null, TFirebaseLeaderboard, string>;
  leaders: IApiData<null, TFirebaseLeaders, string>;
  competitionUsersEntry: IApiData<string, TFirebaseCompEntry, string>;
  competitionCounts: IApiData<string, TFirebaseCompCounts, string>;
  competitionVips: IApiData<string, TFirebaseUser[], string>;
  competitionStatus: IApiData<string, TFirebaseCompStatus, string>;
  competitionChat: IApiData<string, TFirebaseChatMessage[], string>;
  competitionChatRead: IApiData<string, number, string>;
  profileImageUpload: IUploadingMediaPayload;
  mute: boolean;
}

export interface IFirebaseTempEntryWithComp extends TFirebaseTempEntry {
  competitionId: string;
}

export interface IApiData<R, T, E> {
  lastUpdated: Date | null;
  isUpdating: boolean;
  isInvalidated: boolean;
  request: R | null;
  response: T | null;
  error: E | null;
}

export interface IViewState {
  currentPageIndex: number;
  isQuestionsRequired: boolean | null;
  isAuthRequired: boolean | null;
  isSignupNameRequired: boolean | null;
  isEntryRequired: boolean | null;
  questionsToAnswer: TFirebaseQuestion[];
}
