import React, {useEffect, useState} from 'react';

import ISignupNameScreenProps from './Signup/SignupNameScreenProps';
import ICompetitionDetailsScreenProps from './Leaderboard/CompetitionDetailsScreenProps';
import ILeaderboardScreenProps from './Leaderboard/LeaderboardScreenProps';
import IQuestionsCompleteScreenProps from './Complete/QuestionsCompleteScreenProps';
import IQuestionScreenProps from './Question/QuestionScreenProps';
import log from '@chancer/common/lib/utils/Log';

const loadSignupNameScreen = () =>
  import(
    /* webpackChunkName: "SignupNameScreen" */ './Signup/SignupNameScreen'
  );

const loadCompetitionDetailsScreen = () =>
  import(
    /* webpackChunkName: "CompetitionDetailsScreen" */ './Leaderboard/CompetitionDetailsScreen'
  );

const loadLeaderboardScreen = () =>
  import(
    /* webpackChunkName: "LeaderboardScreen" */ './Leaderboard/LeaderboardScreen'
  );

const loadQuestionScreen = () =>
  import(
    /* webpackChunkName: "QuestionScreenV2" */ './Question/v2/QuestionScreen'
  );

const loadSocialQuestionScreen = () =>
  import(
    /* webpackChunkName: "QuestionScreenV2" */ './Question/v2/SocialQuestionScreen'
  );

const loadQuestionsCompleteScreen = () =>
  import(
    /* webpackChunkName: "QuestionsComplete" */ './Complete/QuestionsCompleteScreen'
  );

export const LazySignupNameScreen: React.FC<ISignupNameScreenProps> = (
  props,
) => {
  return (
    <LazyLoader loadImport={loadSignupNameScreen}>
      {(Module: any) =>
        Module.SignupNameScreen ? <Module.SignupNameScreen {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazyCompetitionDetailsScreen: React.FC<
  ICompetitionDetailsScreenProps
> = (props) => {
  return (
    <LazyLoader loadImport={loadCompetitionDetailsScreen}>
      {(Module: any) =>
        Module.CompetitionDetailsScreen ? (
          <Module.CompetitionDetailsScreen {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

export const LazyLeaderboardScreen: React.FC<ILeaderboardScreenProps> = (
  props,
) => {
  return (
    <LazyLoader loadImport={loadLeaderboardScreen}>
      {(Module: any) =>
        Module.LeaderboardScreen ? (
          <Module.LeaderboardScreen {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

export const LazyQuestionScreen: React.FC<IQuestionScreenProps> = (props) => {
  return (
    <LazyLoader loadImport={loadQuestionScreen}>
      {(Module: any) =>
        Module.QuestionScreen ? <Module.QuestionScreen {...props} /> : null
      }
    </LazyLoader>
  );
};

export const LazySocialQuestionScreen: React.FC<IQuestionScreenProps> = (
  props,
) => {
  return (
    <LazyLoader loadImport={loadSocialQuestionScreen}>
      {(Module: any) =>
        Module.SocialQuestionScreen ? (
          <Module.SocialQuestionScreen {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

export const LazyQuestionsCompleteScreen: React.FC<
  IQuestionsCompleteScreenProps
> = (props) => {
  return (
    <LazyLoader loadImport={loadQuestionsCompleteScreen}>
      {(Module: any) =>
        Module.QuestionsCompleteScreen ? (
          <Module.QuestionsCompleteScreen {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

interface IProps {
  children: any;
  loadImport: () => Promise<any>;
}

const LazyLoader: React.FC<IProps> = (props) => {
  const [_module, setModule] = useState<any>(null);
  const [_error, setError] = useState<any>(null);
  const {loadImport} = props;

  useEffect(() => {
    loadImport()
      .then((mod: any) => {
        log.debug('mod', mod);
        setModule(
          // handle both es imports and cjs
          mod.default ? mod.default : mod,
        );
      })
      .catch((err) => {
        log.error('Failed to load module', err);
        setError(err);
      });
  }, [loadImport]);

  if (_module) {
    const t: string = typeof props.children;
    if (props.children && t === 'function') {
      return (props.children as (module: any) => JSX.Element)(_module);
    }
  }
  if (_error) {
    return (
      <div
        className="screen-container__container"
        style={{padding: '16px;', textAlign: 'center'}}>
        We have had an issue loading part of the game. <br />
        Please refresh your browser window
      </div>
    );
  }
  return <div className="screen-container__container" />;
};
