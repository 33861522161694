import React, {useState, useCallback, CSSProperties, useMemo} from 'react';

import {TLeader} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {applyPropClassName} from '@chancer/common/lib/utils/ComponentUtils';
import {
  ClientStatus,
  QuestionResolutionStatus,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';

import {UserScoresHeader} from '@chancer/components/lib/Leaderboard/UserScoresHeader';
import {IntersectionElement} from '../../Core/Intersection/IntersectionElement';

import './UsersEntrantItem.scss';

interface IProps {
  style?: CSSProperties;
  className?: string;
  player: TLeader;
  playerPosition: number;
  playerCount: number;
  compStatus: ClientStatus;
  currentUser: TFirebaseUser;
  usersResults: QuestionResolutionStatus[];
  useFindMeState?: boolean;
  onFindMe: (user: TFirebaseUser) => void;
  onEditProfile: (user: TFirebaseUser) => void;
}

export const UsersEntrantItem: React.FC<IProps> = (props) => {
  const useFindMeState =
    props.useFindMeState ??
    (props.compStatus === ClientStatus.IN_PLAY ||
      props.compStatus === ClientStatus.RESULTED ||
      props.compStatus === ClientStatus.VERIFIED);
  const {currentUser, usersResults} = props;
  const [visible, setVisible] = useState(false);

  const _visible = useCallback(
    (entry: IntersectionObserverEntry) => setVisible(entry.isIntersecting),
    [],
  );

  const _onFindMe = (isFindMe: boolean) => {
    if (isFindMe) {
      props.onFindMe(currentUser);
    } else {
      props.onEditProfile(currentUser);
    }
  };

  interface IQuestionCounts {
    incorrect: number;
    correct: number;
    open: number;
  }
  const questionCounts: IQuestionCounts = useMemo(
    () =>
      usersResults.reduce(
        (
          acc: IQuestionCounts,
          current: QuestionResolutionStatus,
          index: number,
        ) => {
          if (current === QuestionResolutionStatus.CORRECT) {
            acc.correct++;
          } else if (current === QuestionResolutionStatus.INCORRECT) {
            acc.incorrect++;
          } else {
            acc.open++;
          }
          return acc;
        },
        {correct: 0, incorrect: 0, open: 0},
      ),
    [usersResults],
  );

  return (
    <IntersectionElement
      className={applyPropClassName('users-entrant-item__container', props)}
      style={props.style}
      onVisibilityChange={_visible}>
      {visible && (
        <UserScoresHeader
          userImageUrl={currentUser.media?.image?.url}
          correctCount={questionCounts.correct}
          incorrectCount={questionCounts.incorrect}
          pendingCount={questionCounts.open}
          totalPlayers={props.playerCount}
          userPosition={props.playerPosition}
          userScore={props.player.s}
          useFindMeState={useFindMeState}
          onUserPress={_onFindMe}
        />
      )}
    </IntersectionElement>
  );
};
