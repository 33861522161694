import React, {ComponentType, useMemo} from 'react';
import {View, StyleSheet} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Color from 'color';

import {TMediaEntry} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {COLOR_BACKGROUND} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  children?: React.ReactNode;
  style?: CompositedViewStyle;
  containerStyle?: CompositedViewStyle; // Override style used for children container
  backgroundColor?: string;
  media?: TMediaEntry;
  MediaComponent: ComponentType<{media: TMediaEntry}>;
}

const GRADIENT_LOCATIONS = [0, 0.15, 0.24, 0.47, 0.62, 1];
const GRADIENT_LOCATIONS_NO_MEDIA = [0, 1];

export const LeaderboardImage: React.FC<IProps> = (props) => {
  const {backgroundColor = COLOR_BACKGROUND, media} = props;

  const gradientColors = useMemo(() => {
    const color = Color(backgroundColor);
    if (media) {
      return [
        color.fade(0.3).rgb().string(),
        color.fade(0.7).rgb().string(), // 'rgba(0, 0, 0, 0.5)',
        color.fade(0.85).rgb().string(), // 'rgba(0, 0, 0, 0.3)',
        color.fade(0.85).rgb().string(), // 'rgba(0, 0, 0, 0.3)',
        color.fade(0.7).rgb().string(), //'rgba(0, 0, 0, 0.7)',
        color.fade(0.3).rgb().string(),
      ];
    } else {
      return [
        color.fade(0.3).rgb().string(),
        color.fade(0.85).rgb().string(), //'rgba(0, 0, 0, 0.7)',
      ];
    }
  }, [backgroundColor, media]);

  return (
    <View style={[styles.container, props.style]}>
      {media && (
        <View style={styles.mediaContainer}>
          <props.MediaComponent media={media} />
        </View>
      )}
      <LinearGradient
        locations={media ? GRADIENT_LOCATIONS : GRADIENT_LOCATIONS_NO_MEDIA}
        colors={gradientColors}
        style={styles.linearGradient}
      />
      <View style={[styles.foregroundContainer, props.containerStyle]}>
        {props.children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    pointerEvents: 'box-none',
    aspectRatio: 1 / 1,
    overflow: 'hidden',
    backgroundColor: COLOR_BACKGROUND,
  },
  mediaContainer: {pointerEvents: 'none', height: '100%', width: '100%'},
  linearGradient: {
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  foregroundContainer: {
    pointerEvents: 'box-none',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    paddingTop: 24,
    paddingBottom: 32,
    paddingHorizontal: 32,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});
