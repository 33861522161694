import {TLiveScoreSummary} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {
  COLOR_LIGHT,
  FONT_COPY,
  FONT_COPY_BOLD,
} from '../Styles/DesignSystem-chancer';
import {CompositedTextStyle, CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  liveScore?: TLiveScoreSummary;
  nameStyle?: CompositedTextStyle;
  scoreStyle?: CompositedTextStyle;
}

export const LiveScore: React.FC<IProps> = (props) => {
  return (
    props.liveScore !== undefined && (
      <View style={[styles.container, props.style]}>
        <View style={styles.nameContainer}>
          <Text
            style={[styles.name, props.nameStyle]}
            numberOfLines={1}
            ellipsizeMode="tail">
            {props.liveScore.homeName}
          </Text>
          <Text
            style={[styles.name, props.nameStyle]}
            numberOfLines={1}
            ellipsizeMode="tail">
            {props.liveScore.awayName}
          </Text>
        </View>
        <View style={styles.scoreContainer}>
          <Text
            style={[styles.score, props.scoreStyle]}
            numberOfLines={1}
            ellipsizeMode="middle">
            {props.liveScore.homeScore}
          </Text>
          <Text
            style={[styles.score, props.scoreStyle]}
            numberOfLines={1}
            ellipsizeMode="middle">
            {props.liveScore.awayScore}
          </Text>
        </View>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY,
    fontSize: 16,
    marginRight: 8,
  },
  score: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
    textAlign: 'right',
  },
  nameContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  scoreContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});
