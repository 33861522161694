import React, {useEffect, useMemo, useState} from 'react';

import {
  ICompSummaryAndEntry,
  IVendorDetails,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  TFirebaseChatMessage,
  TFirebaseQuestion,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  ChatMessageType,
  QuestionStatus,
  TLeaderboardSummary,
  TOpenGraph,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {assertType} from '@chancer/common/lib/utils/TypeUtils';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Observable, Subscription} from 'rxjs';
import {Avatar} from '../Avatar/Avatar';
import {
  ILeaderboardSummaryProps,
  LeaderboardSummary,
} from '../Leaderboard/LeaderboardSummary';
import {
  COLOR_BLUE,
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_COPY_BOLD,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {CHAT_URL_REGEX, ChatMessageGroup} from './Chat';
import {ChatDate} from './ChatDate';
import {MediaCard} from './MediaCard';
import {OpenGraphCard} from './OpenGraphCard';
import {PublisherBar} from './PublisherBar';
import {QuestionCard} from './QuestionCard';
import {QuestionCard2} from './QuestionCard2';
import {TitleHeader} from './TitleHeader';

interface IProps {
  context: 'group' | 'comp' | 'challenge';
  userId: string;
  group: ChatMessageGroup;
  message: TFirebaseChatMessage;
  vendorById: Map<string, IVendorDetails>;
  cdnUrl: string;
  title: string | undefined;
  getComp: (compId: string) => ICompSummaryAndEntry | undefined;
  getQuestion: (questionId: string) => TFirebaseQuestion | undefined;
  getHostname: (url: string) => string | undefined;
  getLeaderboardSummaryProps: () => ILeaderboardSummaryProps | undefined;
  getOpenGraph: (url: string) => Observable<TOpenGraph>;
  onGoToGame: (comp: ICompSummaryAndEntry, contentId: string) => void;
  onGoToLeaderboard: (vendor: string, leaderboard: TLeaderboardSummary) => void;
  onUrlPress: (url: string | undefined) => void;
}

export const ChatMessageSystem: React.FC<IProps> = (props) => {
  const {
    context,
    message,
    group,
    title,
    getComp,
    getQuestion,
    getHostname,
    getLeaderboardSummaryProps,
    onGoToGame,
    onGoToLeaderboard,
    onUrlPress,
  } = props;

  const messageParts = useMemo(
    () =>
      message.sender
        ? message.message.split(CHAT_URL_REGEX)
        : [message.message],
    [message.message, message.sender],
  );

  const messageUrls = useMemo(
    () =>
      message.sender
        ? messageParts.filter((part) => part.match(CHAT_URL_REGEX))
        : [],
    [message.sender, messageParts],
  );

  const comp = useMemo(
    () =>
      message.payload?.compId !== undefined
        ? getComp(message.payload?.compId)
        : undefined,
    [message.payload?.compId, getComp],
  );
  const colorConfig = getColorConfiguration(comp?.summary.primaryColor);

  const question = useMemo(
    () =>
      message.payload?.questionId !== undefined
        ? getQuestion(message.payload?.questionId)
        : undefined,
    [message.payload?.questionId, getQuestion],
  );

  const vendor = useMemo(
    () =>
      comp && comp.summary.vendor
        ? props.vendorById.get(comp.summary.vendor)
        : undefined,
    [comp, props.vendorById],
  );

  const leaderboard = useMemo(() => {
    return comp
      ? comp.summary.leaderboards.find((l) => l.id === message.payload?.compId)
      : undefined;
  }, [message.payload?.compId, comp]);

  const showIcon = useMemo(() => {
    return (
      (group === ChatMessageGroup.NONE ||
        group === ChatMessageGroup.GROUP_START) &&
      context === 'group' &&
      vendor &&
      message.type !== ChatMessageType.SYSTEM_NEW_QUESTION
    );
  }, [context, group, message.type, vendor]);

  const showTitleHeader = useMemo(() => {
    return messageUrls.length > 0 && context === 'comp';
  }, [context, messageUrls.length]);

  const showOgCard = useMemo(() => {
    return messageUrls.length > 0 && context === 'group';
  }, [context, messageUrls.length]);

  const [openGraph, setOpenGraph] = useState<TOpenGraph | null>(null);
  useEffect(() => {
    if (showTitleHeader) {
      let sub: Subscription;
      if (messageUrls.length > 0 && openGraph === null) {
        sub = props.getOpenGraph(messageUrls[0]).subscribe({
          next: (og) => {
            setOpenGraph(og);
          },
        });
      }

      return () => {
        if (sub) {
          sub.unsubscribe();
        }
      };
    }
  }, [openGraph, showTitleHeader, messageUrls, props]);

  const showMessageText = useMemo(() => {
    return (
      message.type !== ChatMessageType.SYSTEM_NEW_COMP &&
      message.type !== ChatMessageType.SYSTEM_NEW_QUESTION &&
      message.type !== ChatMessageType.SYSTEM_LEADERBOARD_UPDATE &&
      messageParts.filter((p) => p).length > 0 &&
      (!showTitleHeader ||
        !openGraph ||
        !openGraph.image ||
        messageParts.filter((p) => p).length > 1 ||
        !messageUrls.includes(messageParts.filter((p) => p)[0]) ||
        (showTitleHeader && (!openGraph || !openGraph.image)))
    );
  }, [message.type, messageParts, messageUrls, openGraph, showTitleHeader]);

  const showAnswerButton = useMemo(() => {
    return (
      message.type === ChatMessageType.SYSTEM_NEW_QUESTION &&
      comp &&
      comp.entry &&
      message.payload?.questionId !== undefined &&
      comp.entry.answers[message.payload.questionId] === undefined
    );
  }, [message.type, comp, message.payload?.questionId]);

  const {buttonLabel, onPress, children} = useMemo(() => {
    if (message.type === ChatMessageType.SYSTEM_NEW_COMP && comp) {
      const chldrn = (
        <MediaCard
          style={[
            styles.children,
            {backgroundColor: colorConfig.leaderboardItemsBackground},
          ]}
          copy={message.message}
          imageUrl={comp.summary.media.image?.url}
        />
      );
      if (!comp.entry) {
        return {
          buttonLabel: 'Play Now',
          onPress: () => onGoToGame(comp, `${context}_chat_system_new_comp`),
          children: chldrn,
        };
      } else if (leaderboard) {
        return {
          buttonLabel: 'Leaderboard',
          onPress: () => onGoToLeaderboard(comp.summary.vendor, leaderboard),
          children: chldrn,
        };
      }
    }

    if (
      message.type === ChatMessageType.SYSTEM_NEW_QUESTION &&
      comp &&
      question
    ) {
      const chldrn =
        context === 'group' ? (
          <QuestionCard
            style={[
              (group === ChatMessageGroup.NONE ||
                group === ChatMessageGroup.GROUP_START) &&
              context === 'group' &&
              vendor
                ? styles.childrenWithoutHeaderWithAvatar
                : styles.childrenWithoutHeader,
              {backgroundColor: colorConfig.leaderboardItemsBackground},
            ]}
            question={question}
            getHostname={getHostname}
            showAnswerButton={
              (!comp.entry ||
                (message.payload?.questionId !== undefined &&
                  comp.entry?.answers[message.payload.questionId] ===
                    undefined)) &&
              (question.status === QuestionStatus.OPEN ||
                question.status === QuestionStatus.OPEN_CHANGEABLE)
            }
            onPressAnswerButton={() =>
              onGoToGame(comp, `${context}_chat_system_new_question`)
            }
            onPressUrl={() => onUrlPress(question.link?.url)}
          />
        ) : (
          <QuestionCard2
            style={[
              styles.childrenWithoutHeader,
              {backgroundColor: colorConfig.leaderboardItemsBackground},
              !showAnswerButton
                ? styles.titleHeaderForAnsweredQuestion
                : undefined,
            ]}
            question={question}
            showAnswerButton={
              (!comp.entry ||
                (message.payload?.questionId !== undefined &&
                  comp.entry?.answers[message.payload.questionId] ===
                    undefined)) &&
              (question.status === QuestionStatus.OPEN ||
                question.status === QuestionStatus.OPEN_CHANGEABLE)
            }
            onPressAnswerButton={() =>
              onGoToGame(comp, `${context}_chat_system_new_question`)
            }
            onPressUrl={() => onUrlPress(question.link?.url)}
          />
        );
      return {
        children: chldrn,
      };
    }

    if (
      message.type === ChatMessageType.SYSTEM_RESOLVED_QUESTION &&
      comp &&
      leaderboard
    ) {
      return {
        onPress: () => onGoToLeaderboard(comp.summary.vendor, leaderboard),
      };
    }

    if (
      message.type === ChatMessageType.SYSTEM_VERIFIED_COMP &&
      comp &&
      leaderboard
    ) {
      return {
        buttonLabel: 'Leaderboard',
        onPress: () => onGoToLeaderboard(comp.summary.vendor, leaderboard),
      };
    }

    if (message.type === ChatMessageType.SYSTEM_PRE_START_COMP) {
      if (comp && !comp.entry) {
        return {
          onPress: () =>
            onGoToGame(comp, `${context}_chat_system_pre_start_comp`),
        };
      } else if (comp && comp.entry && leaderboard) {
        return {
          onPress: () => onGoToLeaderboard(comp.summary.vendor, leaderboard),
        };
      }
    }

    if (
      message.type === ChatMessageType.SYSTEM_LEADERBOARD &&
      comp &&
      leaderboard
    ) {
      return {
        buttonLabel: 'Leaderboard',
        onPress: () => onGoToLeaderboard(comp.summary.vendor, leaderboard),
      };
    }

    if (message.type === ChatMessageType.SYSTEM_LEADERBOARD_UPDATE) {
      const leaderboardSummaryProps = getLeaderboardSummaryProps();
      if (leaderboardSummaryProps !== undefined) {
        return {
          children: <LeaderboardSummary {...leaderboardSummaryProps} />,
        };
      }
    }

    return {};
  }, [
    message.type,
    message.message,
    message.payload?.questionId,
    comp,
    question,
    leaderboard,
    colorConfig.leaderboardItemsBackground,
    onGoToGame,
    context,
    onGoToLeaderboard,
    group,
    vendor,
    getHostname,
    showAnswerButton,
    onUrlPress,
    getLeaderboardSummaryProps,
  ]);

  const {contextualContainer, contextualMessage, contextualTitleHeader} =
    useMemo(
      () => ({
        contextualContainer: assertType<CompositedViewStyle>([
          styles.container,
          group === ChatMessageGroup.GROUP_START ||
          group === ChatMessageGroup.GROUP
            ? context === 'group'
              ? {marginBottom: 8}
              : {marginBottom: 16}
            : undefined,
        ]),
        contextualMessage: assertType<CompositedViewStyle>([
          styles.message,
          {backgroundColor: colorConfig.leaderboardItemsBackground},
          showIcon ? {marginTop: 8, paddingTop: 20} : undefined,
          message.type === ChatMessageType.SYSTEM_NEW_QUESTION
            ? showAnswerButton
              ? {
                  paddingTop: 0,
                }
              : {
                  paddingTop: 0,
                  paddingBottom: 0,
                }
            : undefined,
          message.type === ChatMessageType.SYSTEM_LEADERBOARD_UPDATE ||
          showTitleHeader
            ? {
                paddingTop: 0,
                paddingBottom: showMessageText ? 16 : 0,
              }
            : undefined,
        ]),
        contextualTitleHeader: assertType<CompositedViewStyle>([
          styles.titleHeader,
          showMessageText
            ? {marginBottom: 8}
            : {borderBottomLeftRadius: 16, borderBottomRightRadius: 16},
        ]),
      }),
      [
        group,
        context,
        colorConfig.leaderboardItemsBackground,
        showIcon,
        message.type,
        showTitleHeader,
        showMessageText,
        showAnswerButton,
      ],
    );

  const showPublisherBar = useMemo(() => {
    return (
      context === 'comp' &&
      ((showTitleHeader && openGraph && openGraph.image) ||
        (message.type === ChatMessageType.SYSTEM_NEW_QUESTION &&
          question &&
          (question.link?.publisher?.caption ||
            question.link?.publisher?.image?.url)))
    );
  }, [context, message.type, openGraph, question, showTitleHeader]);

  const hidden = useMemo(() => {
    return (
      message.type === ChatMessageType.SYSTEM_NEW_QUESTION &&
      (question === undefined ||
        !(showAnswerButton || question.link?.image?.url))
    );
  }, [message.type, question, showAnswerButton]);

  const _urlWithProtocol = (url: string) => {
    return url.match(/^[a-zA-Z]+:\/\//) ? url : `https://${url}`;
  };

  return (
    !hidden && (
      <View style={contextualContainer}>
        {(group === ChatMessageGroup.NONE ||
          group === ChatMessageGroup.GROUP_START) && (
          <ChatDate
            date={props.message.created?.toDate()}
            title={context === 'group' ? title : undefined}
          />
        )}
        {showPublisherBar && (
          <PublisherBar
            name={question ? question.link?.publisher?.caption : undefined}
            imageUrl={
              question ? question?.link?.publisher?.image?.url : openGraph?.icon
            }
            url={
              question ? question.link?.url : _urlWithProtocol(messageUrls[0])
            }
            onPress={props.onUrlPress}
            getHostname={getHostname}
          />
        )}
        <Pressable
          style={contextualMessage}
          onPress={context !== 'challenge' ? onPress : undefined}>
          {showIcon && (
            <Avatar
              style={styles.icon}
              imageUrl={vendor!.media.image?.url}
              highlightWidth={0}
              radius={8}
              size={24}
            />
          )}
          {showTitleHeader &&
            (openGraph && openGraph.image ? (
              <TitleHeader
                style={contextualTitleHeader}
                image={openGraph.image}
                title={openGraph.title}
                onPress={() => props.onUrlPress(openGraph.url)}
              />
            ) : (
              showMessageText && <View style={styles.titleHeaderEmpty} />
            ))}
          {showMessageText && (
            <View style={styles.messageTextContainer}>
              <Text>
                {messageParts.map((part, index) =>
                  messageUrls.includes(part) ? (
                    <Text
                      key={index}
                      style={styles.messageLink}
                      onPress={() => props.onUrlPress(_urlWithProtocol(part))}>
                      {props.getHostname(_urlWithProtocol(part))}
                    </Text>
                  ) : (
                    <Text key={index} style={styles.messageText}>
                      {part}
                    </Text>
                  ),
                )}
              </Text>
            </View>
          )}
          {children !== undefined && children}
          {context !== 'challenge' && buttonLabel !== undefined && (
            <View style={styles.buttonContainer}>
              <Text style={styles.button}>{buttonLabel}</Text>
            </View>
          )}
        </Pressable>
        {showOgCard && (
          <OpenGraphCard
            containerStyle={styles.og}
            url={_urlWithProtocol(messageUrls[0])}
            getHostname={props.getHostname}
            getOpenGraphStream={props.getOpenGraph}
            onPress={props.onUrlPress}
          />
        )}
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    alignSelf: 'center',
    position: 'absolute',
    top: -8,
    zIndex: 1,
    height: 24,
    width: 24,
    opacity: 0.8,
  },
  message: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 16,
    width: '100%',
  },
  messageTextContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 16,
  },
  messageText: {
    fontSize: 16,
    color: COLOR_GREY_8,
    fontFamily: FONT_COPY,
  },
  messageLink: {
    fontSize: 16,
    fontFamily: FONT_COPY,
    color: COLOR_BLUE,
    textDecorationLine: 'underline',
  },
  buttonContainer: {
    alignSelf: 'center',
    flexGrow: 0,
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  button: {
    fontFamily: FONT_COPY_BOLD,
    textTransform: 'uppercase',
    fontSize: 13,
    color: COLOR_LIGHT,
  },
  children: {
    marginTop: 0,
  },
  childrenWithoutHeader: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  childrenWithoutHeaderWithAvatar: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingTop: 16,
  },
  og: {
    alignSelf: 'flex-start',
    marginTop: 16,
  },
  titleHeader: {
    width: '100%',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  titleHeaderEmpty: {
    marginTop: 16,
  },
  titleHeaderForAnsweredQuestion: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});
