import React from 'react';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';

import {PrimaryGroupsButton} from '../Button/PrimaryGroupsButton';
import {
  COLOR_GREY_2,
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  imageUrl?: string;
  onPress: () => void;
}

export const ChallengeCard: React.FC<IProps> = (props) => {
  return (
    <Pressable style={[styles.container, props.style]} onPress={props.onPress}>
      <Text style={styles.title}>CHALLENGE A FRIEND</Text>
      <Text style={styles.description}>
        Send your exclusive invite link to a mate and battle it out for the
        crown. Play and chat live.
      </Text>
      {props.imageUrl && (
        <View style={styles.imageContainer}>
          <Image source={{uri: props.imageUrl}} style={styles.image} />
        </View>
      )}
      <PrimaryGroupsButton
        style={styles.button}
        label="Start a friend’s game"
        onPress={props.onPress}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_GREY_2,
    borderRadius: 16,
    padding: 16,
    marginBottom: 24,
    borderColor: COLOR_GREY_2,
    borderWidth: 1,
  },
  title: {
    fontSize: 16,
    fontFamily: FONT_TITLE,
    color: COLOR_LIGHT,
    marginBottom: 8,
  },
  description: {
    fontSize: 14,
    fontFamily: FONT_COPY,
    color: COLOR_GREY_8,
  },
  imageContainer: {
    marginTop: 16,
    paddingHorizontal: 8,
  },
  image: {
    borderRadius: 16,
    overflow: 'hidden',
    width: '100%',
    aspectRatio: 594 / 332,
  },
  button: {
    marginTop: 16,
    width: '100%',
  },
});
