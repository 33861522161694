import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {TFirebaseQuestion} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {PrimaryButton} from '../Button/PrimaryButton';
import {
  COLOR_LIGHT,
  FONT_COPY,
  FONT_COPY_BOLD,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {TitleHeader} from './TitleHeader';

interface IProps {
  style?: CompositedViewStyle;
  titleHeaderStyle?: CompositedViewStyle;
  question: TFirebaseQuestion;
  showAnswerButton: boolean;
  onPressAnswerButton: () => void;
  onPressUrl: (url: string | undefined) => void;
}

export const QuestionCard2: React.FC<IProps> = (props) => {
  const {question} = props;

  return (
    <View style={[styles.container, props.style]}>
      {question.link && (
        <TitleHeader
          style={props.titleHeaderStyle}
          image={question.link?.image?.url}
          title={question.link?.caption}
          onPress={() => props.onPressUrl(question.link?.url)}
        />
      )}
      {props.showAnswerButton && (
        <View style={styles.copyContainer}>
          <Text style={styles.number}>{`Q${props.question.index + 1} ${
            props.question.name
          }`}</Text>
          <Text style={styles.copy}>{props.question.question}</Text>
          <PrimaryButton
            style={styles.answerButton}
            size="medium"
            label="Answer Question"
            onPress={props.onPressAnswerButton}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  copyContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  number: {
    fontSize: 16,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    paddingBottom: 8,
  },
  copy: {
    fontSize: 16,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY,
  },
  answerButton: {
    marginTop: 16,
    width: '100%',
  },
});
