import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {
  COLOR_GREY_6,
  COLOR_GREY_8,
  COLOR_LOADING_GREY,
  FONT_COPY_MEDIUM,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  loading?: boolean;
  title: string;
  subTitle: string;
}

export const SectionSubTitle: React.FC<IProps> = (props) => {
  return (
    <View style={props.style}>
      <Text style={[styles.title, props.loading ? styles.loading : undefined]}>
        {props.title}
      </Text>
      <Text
        style={[styles.subTitle, props.loading ? styles.loading : undefined]}>
        {props.subTitle}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 18,
    marginTop: 32,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  subTitle: {
    color: COLOR_GREY_8,
    fontFamily: FONT_SUB_TITLE,
    fontSize: 14,
    textTransform: 'uppercase',
    marginBottom: 32,
    textAlign: 'center',
  },
  loading: {color: COLOR_LOADING_GREY},
});
