import {
  ICompSummaryAndEntry,
  IVendorDetails,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  TFirebaseChatMessage,
  TFirebaseQuestion,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  ChatMessageType,
  TLeaderboardSummary,
  TOpenGraph,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import React from 'react';

import {Observable} from 'rxjs';
import {ILeaderboardSummaryProps} from '../Leaderboard/LeaderboardSummary';
import {ChatMessage} from './ChatMessage';
import {ChatMessageSystem} from './ChatMessageSystem';

// https://stackoverflow.com/q/24140345
export const CHAT_URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /\b((?:https?:(?:\/{1,3}|[a-z0-9%])|[a-z0-9.\-]+[.](?:com|net|org|edu|gov|mil|aero|asia|biz|cat|coop|info|int|jobs|mobi|museum|name|post|pro|tel|travel|xxx|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cs|cu|cv|cx|cy|cz|dd|de|dj|dk|dm|do|dz|ec|ee|eg|eh|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|Ja|sk|sl|sm|sn|so|sr|ss|st|su|sv|sx|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)\/)(?:[^\s()<>{}\[\]]+|\([^\s()]*?\([^\s()]+\)[^\s()]*?\)|\([^\s]+?\))+(?:\([^\s()]*?\([^\s()]+\)[^\s()]*?\)|\([^\s]+?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’])|(?:[a-z0-9]+(?:[.\-][a-z0-9]+)*[.](?:com|net|org|edu|gov|mil|aero|asia|biz|cat|coop|info|int|jobs|mobi|museum|name|post|pro|tel|travel|xxx|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cs|cu|cv|cx|cy|cz|dd|de|dj|dk|dm|do|dz|ec|ee|eg|eh|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|Ja|sk|sl|sm|sn|so|sr|ss|st|su|sv|sx|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)\b\/?(?!@)))/gi;

export enum ChatMessageGroup {
  NONE,
  GROUP_START,
  GROUP,
  GROUP_END,
}

export interface IChatProps {
  context: 'group' | 'comp' | 'challenge';
  userId: string;
  messages: TFirebaseChatMessage[];
  vendorById: Map<string, IVendorDetails>;
  invalidationKey: string;
  privateKey?: string;
  cdnUrl: string;
  getOpenGraph: (url: string) => Observable<TOpenGraph>;
  getHostname: (url: string) => string | undefined;
  getComp: (compId: string) => ICompSummaryAndEntry | undefined;
  getQuestion: (questionId: string) => TFirebaseQuestion | undefined;
  getLeaderboardSummaryProps: () => ILeaderboardSummaryProps | undefined;
  onGoToGame: (comp: ICompSummaryAndEntry, contentId: string) => void;
  onGoToLeaderboard: (vendor: string, leaderboard: TLeaderboardSummary) => void;
  onUrlPress: (url: string | undefined) => void;
}

export const messageRendererFactory = (
  props: IChatProps,
  message: TFirebaseChatMessage,
  nextMessage: TFirebaseChatMessage | undefined,
  previousMessage: TFirebaseChatMessage | undefined,
  inverted: boolean,
  addKeys: boolean,
  grouped: boolean,
) =>
  message.type === ChatMessageType.DEFAULT ? (
    <ChatMessage
      key={addKeys ? message.id : undefined}
      group={
        grouped
          ? groupChatMessages(message, nextMessage, previousMessage, inverted)
          : ChatMessageGroup.NONE
      }
      message={message}
      isUser={message.userId === props.userId}
      getOpenGraph={props.getOpenGraph}
      getHostname={props.getHostname}
      onUrlPress={props.onUrlPress}
    />
  ) : (
    <ChatMessageSystem
      key={addKeys ? message.id : undefined}
      context={props.context}
      group={
        grouped
          ? groupChatMessages(message, nextMessage, previousMessage, inverted)
          : ChatMessageGroup.NONE
      }
      userId={props.userId}
      message={message}
      vendorById={props.vendorById}
      cdnUrl={props.cdnUrl}
      title={!grouped ? getTitle(message.type) : undefined}
      getComp={props.getComp}
      getQuestion={props.getQuestion}
      getHostname={props.getHostname}
      getLeaderboardSummaryProps={props.getLeaderboardSummaryProps}
      getOpenGraph={props.getOpenGraph}
      onGoToGame={props.onGoToGame}
      onGoToLeaderboard={props.onGoToLeaderboard}
      onUrlPress={props.onUrlPress}
    />
  );

const groupChatMessages = (
  message: TFirebaseChatMessage,
  nextMessage: TFirebaseChatMessage | undefined,
  previousMessage: TFirebaseChatMessage | undefined,
  inverted: boolean,
): ChatMessageGroup => {
  const isGroupedWithNext = isGroupedWith(nextMessage, message);
  const isGroupedWithPrevious = isGroupedWith(message, previousMessage);
  if (isGroupedWithNext && isGroupedWithPrevious) {
    return ChatMessageGroup.GROUP;
  }
  if (isGroupedWithNext) {
    return inverted ? ChatMessageGroup.GROUP_START : ChatMessageGroup.GROUP_END;
  }
  if (isGroupedWithPrevious) {
    return inverted ? ChatMessageGroup.GROUP_END : ChatMessageGroup.GROUP_START;
  }
  return ChatMessageGroup.NONE;
};

const isGroupedWith = (
  current: TFirebaseChatMessage | undefined,
  previous: TFirebaseChatMessage | undefined,
) => {
  if (!current) {
    return false;
  }
  if (!previous) {
    return false;
  }

  if (current.created === undefined) {
    // Message can have null created date when firestore hasn't resolved the update with the server
    current = {
      ...current,
      created: {seconds: Date.now() / 1000, nanoseconds: 0} as any,
    };
  }
  if (previous.created === undefined) {
    // Message can have null created date when firestore hasn't resolved the update with the server
    previous = {
      ...previous,
      created: {seconds: Date.now() / 1000, nanoseconds: 0} as any,
    };
  }

  if (
    (current.type === ChatMessageType.DEFAULT ||
      previous.type === ChatMessageType.DEFAULT) &&
    current.type !== previous.type
  ) {
    // Show user name when user message received after a system message or vice versa
    return false;
  } else if (
    current.type !== ChatMessageType.DEFAULT &&
    previous.type !== ChatMessageType.DEFAULT &&
    current.sender !== previous.sender
  ) {
    // Don't group game manager messages with other system messages
    return false;
  } else if (current.type === ChatMessageType.DEFAULT) {
    // Group if 2 user messages from same user within 60 seconds
    return current.userId === previous.userId &&
      current.created.seconds - previous.created.seconds < 60
      ? true
      : false;
  } else {
    // Group if system messages within 60 seconds
    return current.created.seconds - previous.created.seconds < 60
      ? true
      : false;
  }
};

const getTitle = (type: ChatMessageType) => {
  switch (type) {
    case ChatMessageType.SYSTEM_DEFAULT:
    case ChatMessageType.SYSTEM_PRE_START_COMP:
      return 'Game Update';
    case ChatMessageType.SYSTEM_NEW_COMP:
      return 'New Game';
    case ChatMessageType.SYSTEM_NEW_QUESTION:
      return 'New Question';
    case ChatMessageType.SYSTEM_RESOLVED_QUESTION:
      return 'Result';
    case ChatMessageType.SYSTEM_VERIFIED_COMP:
      return 'Result';
    case ChatMessageType.SYSTEM_NEW_ENTRY:
      return 'New Entry';
    case ChatMessageType.SYSTEM_LEADERBOARD:
      return 'Result';
    case ChatMessageType.SYSTEM_LEADERBOARD_UPDATE:
      return 'Leaderboard Update';
    default:
      return undefined;
  }
};
