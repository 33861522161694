import {IAction, ActionType} from '../Actions';
import {IFollowedEntry, IViewState} from '../../state/AppState';
import {
  TFirebaseCompEntry,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';

export const startup = (): IAction<null> => ({
  type: ActionType.STARTUP,
  payload: null,
});

export const setIntialViewState = (state: IViewState): IAction<IViewState> => ({
  type: ActionType.SET_INITIAL_VIEW_STATE,
  payload: state,
});

export const setCurrentPageIndex = (index: number): IAction<number> => ({
  type: ActionType.SET_PAGE_INDEX,
  payload: index,
});

export const followedEntry = (
  user: TFirebaseUser,
  entry: TFirebaseCompEntry,
): IAction<IFollowedEntry> => ({
  type: ActionType.FOLLOWED_ENTRY,
  payload: {user, entry},
});
