import {TLeader} from '../firestore/FirestoreInterfaces';

export enum AchievementType {
  // Player finishes in the top tier
  WINNER = 0,
  // Player finishes in the top tier by themselves
  OUTRIGHT_WINNER = 1,
  // Player gets 100% correct in a game
  ALL_CORRECT = 2,
  // Player gets 0
  ZERO_CORRECT = 3,
  // Player is assigned the winner of a game
  GAME_WINNER = 4,
  // Participated in a game
  PARTICIPANT = 5,
}

export const EMPTY_ACHIEVEMENTS = new Array(
  AchievementType.GAME_WINNER + 1,
).fill(0);

export const incrementAchievement = (
  currentAchievements: number[],
  ...achievement: AchievementType[]
): number[] => {
  const a = [...currentAchievements];
  for (const ach of achievement)
    for (let i = 0; i <= ach; i++) {
      if (a[i] === undefined) {
        a[i] = 0;
      }
      if (i === ach) {
        a[ach]++;
      }
    }
  return a;
};

export const mergeAchievements = (
  currentAchievements: number[],
  newAchievements: number[],
): number[] => {
  let a = [...currentAchievements];
  for (let i = 0; i < newAchievements.length; i++) {
    const count = newAchievements[i];
    for (let j = 0; j < count; j++) {
      a = incrementAchievement(a, i);
    }
  }
  return a;
};

export const calculateAwardedAchievementsForComp = (
  numQuestions: number,
  leaders: TLeader[],
) => {
  const users: {[userId: string]: number[]} = {};
  if (leaders.length > 0 && leaders[0].s > 0) {
    const topScorers: string[] = [];
    const allCorrect: string[] = [];
    const noneCorrect: string[] = [];
    const winners: string[] = [];

    const topScore = leaders[0].s;
    leaders.forEach((l) => {
      if (l.w === true) {
        winners.push(l.u);
      }

      if (l.s === topScore) {
        topScorers.push(l.u);
        if (l.s === numQuestions) {
          allCorrect.push(l.u);
        }
      }

      if (l.s === 0) {
        noneCorrect.push(l.u);
      }
    });

    addNewAchievements(users, winners, AchievementType.GAME_WINNER);
    addNewAchievements(users, topScorers, AchievementType.WINNER);
    if (topScorers.length === 1) {
      addNewAchievements(users, topScorers, AchievementType.OUTRIGHT_WINNER);
    }

    addNewAchievements(users, allCorrect, AchievementType.ALL_CORRECT);
    addNewAchievements(users, noneCorrect, AchievementType.ZERO_CORRECT);
  }
  return users;
};

const addNewAchievements = (
  userMap: {[userId: string]: number[]},
  ids: string[],
  achievement: AchievementType,
) => {
  if (ids.length > 0) {
    for (const userId of ids) {
      if (userMap[userId] === undefined) {
        userMap[userId] = [];
      }
      userMap[userId] = incrementAchievement(userMap[userId], achievement);
    }
  }
};
