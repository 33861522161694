import React, {memo, useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {
  COLOR_DARK,
  COLOR_YELLOW,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';
import {CompositedTextStyle, CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  size?: 'small' | 'medium' | 'large';
  count?: number;
  maxCount?: number;
  textStyle?: CompositedTextStyle;
}

export const Badge: React.FC<IProps> = memo((props) => {
  const styles = useMemo(() => getStyles(props.size ?? 'medium'), [props.size]);
  const copy =
    props.count !== undefined && props.maxCount !== undefined
      ? props.count > props.maxCount
        ? `${props.maxCount}+`
        : props.count
      : props.count;

  return (
    <View style={[styles.container, props.style]}>
      {props.count !== undefined && (
        <Text style={[styles.count, props.textStyle]}>{copy}</Text>
      )}
    </View>
  );
});

const getStyles = (size: 'small' | 'medium' | 'large') => {
  const containerSize = size === 'small' ? 14 : size === 'medium' ? 18 : 22;
  return StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: COLOR_YELLOW,
      overflow: 'hidden',
      minWidth: containerSize,
      height: containerSize,
      borderRadius: containerSize / 2,
    },
    count: {
      color: COLOR_DARK,
      fontFamily: FONT_COPY_MEDIUM,
      paddingHorizontal: 3,
      marginBottom: 2,
      fontSize: size === 'small' ? 9 : size === 'medium' ? 12 : 16,
    },
  });
};
