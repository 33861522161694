import React, {useMemo} from 'react';
import {StyleSheet} from 'react-native';
import {
  COLOR_BACKGROUND,
  COLOR_GREY_6,
  COLOR_GREY_8,
  COLOR_LIGHT,
} from '../Styles/DesignSystem-chancer';

import {ButtonSize, IButtonProps, PrimaryButton} from './PrimaryButton';

export const SecondaryButton: React.FC<IButtonProps> = (props) => {
  const {size} = props;
  const styles = useMemo(() => getStyles(size ?? 'large'), [size]);
  return (
    <PrimaryButton
      style={[styles.container, props.style]}
      size={props.size}
      labelStyle={[styles.label, props.labelStyle]}
      disabledStyle={props.disabledStyle}
      disabled={props.disabled}
      label={props.label}
      secondLabel={props.secondLabel}
      secondLabelStyle={[styles.secondLabel, props.secondLabelStyle]}
      onPress={props.onPress}>
      {props.children}
    </PrimaryButton>
  );
};

const getStyles = (size: ButtonSize) =>
  StyleSheet.create({
    container: {
      backgroundColor: COLOR_BACKGROUND,
      borderColor: size === 'small' ? COLOR_GREY_6 : COLOR_LIGHT,
      borderStyle: 'solid',
      borderWidth: size === 'small' ? 1 : size === 'medium' ? 1 : 2,
    },
    label: {
      color: size === 'small' ? COLOR_GREY_8 : COLOR_LIGHT,
    },
    secondLabel: {
      color: size === 'small' ? COLOR_GREY_8 : COLOR_LIGHT,
    },
  });
