import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {IVendorDetails} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_GREY_6,
  COLOR_LIGHT,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';

interface IProps {
  vendor: IVendorDetails;
  onSelected: (comp: IVendorDetails) => void;
}

export const CompVendorListItem: React.FC<IProps> = (props) => {
  const _selected = () => {
    props.onSelected(props.vendor);
  };

  return (
    <Pressable style={styles.container} onPress={_selected}>
      <Avatar
        size={48}
        imageUrl={props.vendor.media.image?.url}
        highlightWidth={0}
        highlightGap={0}
        radius={10}
      />
      <View style={styles.copyContainer}>
        <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
          {props.vendor.name}
        </Text>
        <Text style={styles.strapline} numberOfLines={1} ellipsizeMode="tail">
          {props.vendor.strapline}
        </Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    marginLeft: 12,
    marginRight: 4,
  },
  copyContainer: {
    flex: 1,
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 4,
  },
  name: {
    fontFamily: FONT_SUB_TITLE,
    color: COLOR_LIGHT,
    fontSize: 18,
    marginBottom: 4,
  },
  strapline: {
    color: COLOR_GREY_6,
    fontSize: 14,
  },
});
