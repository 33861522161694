import React, {Fragment} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {
  TFirebaseCompEntry,
  TFirebaseQuestion,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_GREY_2,
  COLOR_GREY_4,
  COLOR_GREY_8,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_COPY,
  FONT_SUB_TITLE,
  FONT_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {EditIcon} from '../Svg/SvgIcons';

const MAX_QUESTIONS = 3;

interface IProps {
  style?: CompositedViewStyle;
  live: boolean;
  changeableQuestions: TFirebaseQuestion[];
  entry: TFirebaseCompEntry;
  onPress: () => void;
}

export const EditAnswersCard: React.FC<IProps> = (props) => {
  return (
    <Pressable style={[styles.container, props.style]} onPress={props.onPress}>
      <View style={styles.headerContainer}>
        <Text style={styles.label}>YOUR PICKS</Text>
        {props.live && <LabelBadge style={styles.badge} label="LIVE" />}
      </View>
      {props.changeableQuestions.slice(0, MAX_QUESTIONS).map((q) => (
        <Fragment key={q.id}>
          <Text style={styles.question}>{q.question}</Text>
          <Text style={styles.answer}>
            {props.entry.answers[q.id] !== undefined
              ? q.answers[props.entry.answers[q.id]].label
              : 'Unanswered'}
          </Text>
        </Fragment>
      ))}
      {props.changeableQuestions.length > MAX_QUESTIONS && (
        <Text style={styles.more}>
          +{props.changeableQuestions.length - MAX_QUESTIONS} MORE
        </Text>
      )}
      <View style={styles.footerContainer}>
        <EditIcon width={16} height={16} color={COLOR_YELLOW} />
        <Text style={styles.change}>CHANGE</Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_GREY_2,
    borderRadius: 16,
    padding: 16,
    marginBottom: 32,
    borderColor: COLOR_GREY_2,
    borderWidth: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  label: {
    fontSize: 16,
    fontFamily: FONT_TITLE,
    color: COLOR_LIGHT,
  },
  badge: {
    marginTop: 1,
    marginLeft: 8,
  },
  question: {
    fontSize: 14,
    fontFamily: FONT_COPY,
    color: COLOR_GREY_8,
    marginBottom: 1,
  },
  answer: {
    fontSize: 14,
    fontFamily: FONT_COPY,
    color: COLOR_YELLOW,
    marginBottom: 6,
  },
  more: {
    fontSize: 14,
    fontFamily: FONT_COPY,
    color: COLOR_GREY_4,
  },
  footerContainer: {
    marginTop: 16,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  change: {
    marginLeft: 8,
    fontSize: 14,
    fontFamily: FONT_SUB_TITLE,
    color: COLOR_YELLOW,
  },
});
