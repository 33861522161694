import {Reducer} from 'redux';

import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import {ActionType, IAction} from '../../actions/Actions';
import {IAuth} from '../../state/AppState';

export const authReducer: Reducer<IAuth, IAction<any>> = (state, action) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.AUTH_MESSAGE:
      return {...state, isLogginIn: false, user: action.payload};
    case ActionType.AUTH_TEMPORARY_USER_SCORE_MESSAGE:
      return {...state, temporaryUserScore: action.payload};
  }
  return state;
};
