import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {createFallbackUserIcon} from '@chancer/common/lib/utils/UserUtils';
import {Avatar} from '../Avatar/Avatar';
import {Badge} from '../Status/Badge';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_BACKGROUND,
  COLOR_DARK,
  COLOR_DARK_6,
  COLOR_GREY_3,
  COLOR_LIGHT,
  COLOR_RED,
  COLOR_YELLOW,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {VsIcon} from '../Svg/SvgIcons';

interface IProps {
  style?: CompositedViewStyle;
  currentUser: TFirebaseUser;
  challenger: TFirebaseUser;
  challenged: TFirebaseUser;
  showLabels: boolean;
  badge: number;
  compImage?: string;
  compPrimaryColor?: string;
  result?: number[];
  size?: 'medium' | 'large';
  onSelected: (userA: TFirebaseUser, userB: TFirebaseUser) => void;
}

enum CHALLENGE_STATE {
  OPEN,
  WON,
  LOST,
  DRAW,
}

export const ChallengeButton: React.FC<IProps> = (props) => {
  const size = props.size || 'large';
  const imageSize = size === 'large' ? 112 : 72;
  const styles = getStyles(size, imageSize);

  const _onPress = () => {
    props.onSelected(props.challenger, props.challenged);
  };

  const challengeState =
    props.result && props.result.length === 2
      ? props.result[0] === props.result[1]
        ? CHALLENGE_STATE.DRAW
        : props.currentUser.id === props.challenger.id &&
            props.result[0] > props.result[1]
          ? CHALLENGE_STATE.WON
          : props.currentUser.id === props.challenged.id &&
              props.result[0] < props.result[1]
            ? CHALLENGE_STATE.WON
            : CHALLENGE_STATE.LOST
      : CHALLENGE_STATE.OPEN;

  // Always put the other user at the top for a consistent look
  const userA =
    props.currentUser.id === props.challenged.id
      ? props.challenger
      : props.challenged;
  const userB =
    userA === props.challenger ? props.challenged : props.challenger;

  const userAImage =
    userA.media?.image?.url ?? createFallbackUserIcon(userA.id, userA.name);
  const userBImage =
    props.compImage ??
    userB.media?.image?.url ??
    createFallbackUserIcon(userB.id, userB.name);

  return (
    <Pressable style={[styles.container, props.style]} onPress={_onPress}>
      <View style={[styles.userContainer, styles.upperUserContainer]}>
        <Avatar
          style={styles.image}
          highlightGap={0}
          highlightWidth={0}
          size={imageSize}
          radius={0}
          imageUrl={userAImage}
        />
        {size === 'large' &&
          props.showLabels &&
          userA.id !== props.currentUser.id && (
            <LabelBadge
              style={styles.label}
              backgroundColor={COLOR_DARK_6}
              labelColor={COLOR_LIGHT}
              label={userA.name.toUpperCase()}
              ellipsizeMode="tail"
              numberOfLines={2}
            />
          )}
      </View>
      {size === 'large' ? (
        <View style={[styles.userContainer, styles.lowerUserContainer]}>
          <Avatar
            style={styles.image}
            highlightGap={0}
            highlightWidth={0}
            size={imageSize}
            radius={0}
            imageUrl={userBImage}
          />
          {props.showLabels && userB.id !== props.currentUser.id ? (
            <LabelBadge
              style={styles.label2}
              backgroundColor={COLOR_DARK_6}
              labelColor={COLOR_LIGHT}
              label={userB.name.toUpperCase()}
              ellipsizeMode="tail"
              numberOfLines={2}
            />
          ) : (
            <ChallengeLabel state={challengeState} style={styles.label2} />
          )}
        </View>
      ) : (
        <>
          <ChallengeLabel
            state={challengeState}
            style={styles.label2Absolute}
          />
          <View
            style={[
              styles.labelContainer,
              {backgroundColor: props.compPrimaryColor},
            ]}>
            <LabelBadge
              style={styles.label2}
              backgroundColor={props.compPrimaryColor ?? COLOR_DARK_6}
              labelColor={COLOR_LIGHT}
              label={userA.name.toUpperCase()}
              ellipsizeMode="tail"
              numberOfLines={1}
            />
          </View>
        </>
      )}
      <View style={styles.vsIcon}>
        <VsIcon
          color={COLOR_LIGHT}
          width={size === 'large' ? 24 : 11}
          height={size === 'large' ? 46 : 20}
        />
      </View>
      {props.badge > 0 && (
        <Badge
          style={styles.badge}
          size="medium"
          count={props.badge}
          maxCount={9}
        />
      )}
    </Pressable>
  );
};

const getStyles = (size: 'medium' | 'large', imageSize: number) =>
  StyleSheet.create({
    container: {
      width: imageSize,
      height: size === 'large' ? imageSize * 2 : imageSize + 32,
      overflow: 'visible',
      borderRadius: 16,
      justifyContent: 'space-between',
      borderWidth: size === 'large' ? 2 : 0,
      borderColor: COLOR_GREY_3,
      backgroundColor: COLOR_BACKGROUND,
      marginTop: size === 'large' ? 4 : 6,
    },
    userContainer: {
      position: 'relative',
      height: size === 'large' ? imageSize - 2 : imageSize,
      width: size === 'large' ? imageSize - 4 : imageSize,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingHorizontal: 16,
      paddingVertical: 8,
      overflow: 'hidden',
    },
    upperUserContainer: {borderTopLeftRadius: 14, borderTopRightRadius: 14},
    lowerUserContainer: {
      justifyContent: 'flex-end',
      borderBottomLeftRadius: 14,
      borderBottomRightRadius: 14,
    },
    labelContainer: {
      flex: 1,
      justifyContent: 'center',
      overflow: 'hidden',
      borderBottomLeftRadius: 14,
      borderBottomRightRadius: 14,
    },
    image: {position: 'absolute', top: 0},
    label: {maxWidth: imageSize - 32, alignSelf: 'center', marginBottom: 8},
    label2: {
      maxWidth: size === 'large' ? imageSize - 32 : imageSize - 8,
      alignSelf: 'center',
    },
    label2Absolute: {
      maxWidth: size === 'large' ? imageSize - 32 : imageSize - 8,
      width: size === 'large' ? undefined : imageSize - 8,
      alignSelf: 'center',
      position: size === 'large' ? 'relative' : 'absolute',
      top: size === 'large' ? undefined : imageSize - 8,
      left: size === 'large' ? undefined : 8 / 2,
      zIndex: size === 'large' ? 0 : 1,
    },
    vsIcon: {
      position: 'absolute',
      top: size === 'large' ? imageSize - 46 / 2 : imageSize - 28,
      left: size === 'large' ? imageSize / 2 - 24 / 2 : imageSize / 2 - 11 / 2,
    },
    badge: {
      position: 'absolute',
      top: -6,
      right: -6,
      overflow: 'visible',
    },
  });

interface IChallengeProps {
  state: CHALLENGE_STATE;
  style: CompositedViewStyle;
}

const ChallengeLabel: React.FC<IChallengeProps> = (props) => {
  switch (props.state) {
    case CHALLENGE_STATE.WON:
      return (
        <LabelBadge
          style={props.style}
          label="WON"
          labelColor={COLOR_DARK}
          backgroundColor={COLOR_YELLOW}
        />
      );
    case CHALLENGE_STATE.LOST:
      return (
        <LabelBadge
          style={props.style}
          label="LOST"
          labelColor={COLOR_LIGHT}
          backgroundColor={COLOR_RED}
        />
      );
    case CHALLENGE_STATE.DRAW:
      return (
        <LabelBadge
          style={props.style}
          label="DRAW"
          labelColor={COLOR_LIGHT}
          backgroundColor={COLOR_DARK}
        />
      );
    default:
      return null;
  }
};
