import React from 'react';
import {View, StyleSheet, Text} from 'react-native';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_DARK,
  COLOR_GREY_2,
  COLOR_LIGHT,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {LabelState, QuestionState} from './QuestionItem';
import {CompQuestionType} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';

interface IProps {
  questionNumber: number;
  questionType: CompQuestionType;
  userQuestionState: QuestionState;
  compareQuestionState: QuestionState | undefined;
}

export const QuestionItemCompareHeader: React.FC<IProps> = (props) => {
  return (
    <View style={styles.container}>
      <View style={styles.badgeContainer}>
        <View style={styles.badge}>
          {props.compareQuestionState !== undefined ? (
            <LabelState
              state={props.compareQuestionState}
              type={props.questionType}
            />
          ) : (
            <LabelBadge
              label="LOADING..."
              backgroundColor={`${COLOR_DARK}00`}
              labelColor={COLOR_GREY_2}
              borderColor={COLOR_GREY_2}
            />
          )}
        </View>
      </View>
      <Text style={styles.questionNumber}>Q{props.questionNumber}</Text>
      <View style={[styles.badgeContainer, styles.badgeRight]}>
        <View style={styles.badge}>
          <LabelState
            state={props.userQuestionState}
            type={props.questionType}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 24,
  },
  badgeContainer: {
    flexGrow: 0,
    minWidth: 120,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  badgeRight: {
    alignItems: 'flex-end',
  },
  badge: {},
  questionNumber: {
    flexGrow: 1,
    color: COLOR_LIGHT,
    fontFamily: FONT_SUB_TITLE,
    fontSize: 16,
    marginRight: 8,
    textAlign: 'center',
  },
});
