import React from 'react';

interface IProps {
  style?: React.CSSProperties | undefined;
  embed: string;
}

export const SocialEmbed: React.FC<IProps> = (props) => {
  const [opacity, setOpacity] = React.useState(0);
  React.useEffect(() => {
    window.setTimeout(() => setOpacity(1), 200);
  }, []);
  return (
    <iframe
      srcDoc={`<html><body>${props.embed}</body></html>`}
      style={{
        width: '100%',
        height: getEmbedHeight(props.embed),
        maxWidth: 550, // Matches value set on twitter embeds returned by the admin
        opacity: opacity,
        border: 0,
        transitionProperty: 'opacity',
        transitionDuration: '0.6s',
        ...props.style,
      }}
      allowFullScreen={true}
      allow="encrypted-media;"
    />
  );
};

const getEmbedHeight = (embed: string) => {
  if (
    embed.includes('//www.instagram.com/embed.js') ||
    embed.includes('//platform.twitter.com/widgets.js') ||
    embed.includes('//platform.x.com/widgets.js')
  ) {
    return '70%';
  }
  return '100%';
};
