import React from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {COLOR_BACKGROUND} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  children?: React.ReactNode;
  style?: CompositedViewStyle;
  url: string | undefined;
  onPress: () => void;
}

export const HeaderImage: React.FC<IProps> = (props) => {
  return (
    <Pressable
      style={[styles.container, props.style]}
      onPress={() => props.onPress()}>
      <View style={[styles.container, props.style]}>
        <Image
          style={styles.image}
          resizeMode="cover"
          source={{uri: props.url}}
        />
        <LinearGradient
          locations={[0, 0.5, 0.55, 0.7, 1]}
          colors={[
            'rgba(0, 0, 0, 0)',
            'rgba(0 , 0, 0, 0)',
            'rgba(0, 0, 0, 0.1)',
            'rgba(0, 0, 0, 0.7)',
            'rgba(0, 0, 0, 0.8)',
            COLOR_BACKGROUND,
          ]}
          style={styles.linearGradient}
        />
        <View style={[styles.foregroundContainer]}>{props.children}</View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    pointerEvents: 'box-none',
    aspectRatio: 1 / 1,
    overflow: 'hidden',
    backgroundColor: COLOR_BACKGROUND,
  },
  image: {
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  linearGradient: {
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  foregroundContainer: {
    pointerEvents: 'box-none',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 16,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});
