type Props = {
  root: Element | null;
  rootMargin?: string;
  threshold?: number[];
};

type QueuedItem = {
  child: Element;
  onChange: (entry: IntersectionObserverEntry) => any;
};

export default class IntersectionObserverWrapper {
  observedQueue: QueuedItem[] = [];
  observedMap: WeakMap<Element, (entry: IntersectionObserverEntry) => any> =
    new WeakMap();
  activeObservers: Element[] = [];
  observer?: IntersectionObserver;
  props?: Props;

  constructor(props: Props) {
    if (typeof IntersectionObserver === 'undefined') {
      return;
    }
    this.createObserver(props);
    this.flushQueue();
  }

  createObserver({
    root,
    rootMargin = '0px 0px 0px 0px',
    threshold = [0, 1],
  }: Props) {
    this.observer = new IntersectionObserver(this.fireListeners, {
      root,
      rootMargin,
      threshold,
    });
  }

  flushQueue() {
    this.observedQueue.forEach(({child, onChange}: QueuedItem) => {
      this.observedMap.set(child, onChange);
      if (this.observer) {
        this.observer.observe(child);
      }
    });
    if (this.observer) {
      this.observedQueue = [];
    }
  }

  fireListener = (entry: IntersectionObserverEntry) => {
    const onChange = this.observedMap.get(entry.target);
    if (onChange) {
      onChange(entry);
    }
  };

  fireListeners = (entries: IntersectionObserverEntry[]) =>
    entries.forEach(this.fireListener);

  observe = (
    child: Element,
    onChange: (entry: IntersectionObserverEntry) => any,
  ) => {
    this.observedQueue.push({child, onChange});
    this.activeObservers.push(child);
    this.flushQueue();
  };

  unobserve = (child: Element) => {
    this.observedMap.delete(child);
    if (this.observer) {
      this.observer.unobserve(child);
    }
    this.activeObservers.splice(this.activeObservers.indexOf(child), 1);
  };

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
