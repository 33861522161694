import {Epic, ofType} from 'redux-observable';
import {fromEvent, merge, of} from 'rxjs';
import {
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import log from '@chancer/common/lib/utils/Log';

import {processSerialisedTimestamps} from '@chancer/common/lib/utils/FirebaseUtils';
import {ActionType, IAction, noOpAction} from '../../actions/Actions';
import {
  authTemporaryUserScore,
  competitionChatLastRead,
  competitionChatMessage,
  competitionCountsMessage,
  competitionMessage,
  competitionStatusMessage,
  competitionSummaryMessage,
  competitionVipsMessage,
  enterCompStatusMessage,
  leaderboardConfigMessage,
  leaderboardMessage,
  muteMessage,
  profileImageUploadMessage,
  questionsMessage,
  upcomingCompetitionsMessage,
  userChallengeCountsMessage,
  userChallengeReadCountsMessage,
  userChallengesMessage,
  userEntryMessage,
  userFollowingMessage,
  userMessage,
} from '../../actions/messages/MessageActions';
import {IAppState} from '../../state/AppState';

import {
  AppAction,
  AppMessageName,
  AppNavigation,
  IApiMessage,
  IEnterCompPayload,
  createApiMessage,
} from '@chancer/common/lib/app/AppMessage';
import {
  IQuestionAnswer,
  IQuestionLike,
} from '@chancer/common/lib/core/actions/competitions/CompetitionActions';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {IAnalyticsPayload} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {IOverlay} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {localAnswersMessage} from '../../actions/answers/AnswersActions';
import {authResponse} from '../../actions/auth/AuthActions';
import {setConfig, setRemoteConfig} from '../../actions/config/ConfigActions';
import {setCurrentPageIndex} from '../../actions/startup/StartupActions';
import {getCurrentCompetitionsLocalAnswers} from '../../selectors/answers/AnswersSelectors';
import {
  getCompetitionId,
  getUsersCompetitionEntry,
} from '../../selectors/competitions/CompetitionSelectors';
import {getLaunchChallenge} from '../../selectors/groups/GroupsSelectors';
import {
  COMP_DETAILS_INDEX,
  HOME_PAGE_INDEX,
} from '../../selectors/startup/StartupSelectors';

export const startupEpic: Epic<IAction<any>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.STARTUP),
    switchMap(() =>
      merge(
        of(
          sendMessageToApp(createApiMessage(AppMessageName.APP_READY, null)),
        ).pipe(
          delay(4000),
          tap(() => log.info('Re-sending APP_READY')),
          tap(() =>
            sendMessageToApp(createApiMessage(AppMessageName.APP_READY, null)),
          ),
          map(() => noOpAction()),
          takeUntil(action$.pipe(ofType(ActionType.CONFIG_MESSAGE))),
        ),
        fromEvent<MessageEvent>(window, 'message').pipe(
          // Filter out dev-tools messages
          filter(
            (message) =>
              message.data?.hasOwnProperty('name') &&
              message.data?.hasOwnProperty('payload'),
          ),
          filter((event) => {
            if (
              (event.data as IApiMessage<any>).name ===
                AppMessageName.ON_CONFIG ||
              event.origin.includes('mixnpik.com') ||
              event.origin.includes('chancerhq.com') ||
              event.origin.includes('192.168.') ||
              event.origin.includes('10.0.2.2:') || // Allows android emulator on localhost
              event.origin.includes('localhost:') // Allows 3002 on web and 3007 on mobile
            ) {
              return true;
            } else {
              log.info('Ignoring message from', event.origin);
              return false;
            }
          }),
          map((event) => event.data),
          tap((message) => log.info('[Message]', message)),
          map((message) => handleMessage(message)),
        ),
      ),
    ),
  );

const handleMessage = (message: IApiMessage<any>): IAction<any> => {
  switch (message.name) {
    case AppMessageName.ON_CONFIG:
      return setConfig(message.payload);
    case AppMessageName.ON_REMOTE_CONFIG:
      return setRemoteConfig(message.payload);
    case AppMessageName.ON_COMP:
      return competitionMessage(processSerialisedTimestamps(message.payload));
    case AppMessageName.ON_COMP_SUMMARY:
      return competitionSummaryMessage(
        processSerialisedTimestamps(message.payload),
      );
    case AppMessageName.ON_COMP_QUESTIONS:
      return questionsMessage(
        (message.payload as []).map((q) => processSerialisedTimestamps(q)),
      );
    case AppMessageName.ON_COMP_STATUS:
      return competitionStatusMessage(
        processSerialisedTimestamps(message.payload),
      );
    case AppMessageName.ON_COMP_COUNTS:
      return competitionCountsMessage(message.payload);
    case AppMessageName.ON_COMP_VIPS:
      return competitionVipsMessage(message.payload);
    case AppMessageName.ON_COMP_CHAT:
      return competitionChatMessage(
        (message.payload as []).map((q) => processSerialisedTimestamps(q)),
      );
    case AppMessageName.ON_COMP_CHAT_LAST_READ:
      return competitionChatLastRead(message.payload);
    case AppMessageName.ON_COMP_LEADERBOARD_CONFIG:
      return leaderboardConfigMessage(message.payload);
    case AppMessageName.ON_COMP_LEADERBOARD:
      return leaderboardMessage(message.payload);
    case AppMessageName.ON_UPCOMING_COMPS:
      return upcomingCompetitionsMessage(
        (message.payload as ICompSummaryAndEntry[]).map((entry) =>
          processSerialisedTimestamps(entry),
        ),
      );
    case AppMessageName.ON_AUTH_USER:
      return authResponse(message.payload);
    case AppMessageName.ON_USER:
      return userMessage(message.payload);
    case AppMessageName.ON_USER_FOLLOWING:
      return userFollowingMessage(message.payload);
    case AppMessageName.ON_USER_ENTRY:
      return userEntryMessage(message.payload);
    case AppMessageName.ON_USER_CHALLENGES:
      return userChallengesMessage(message.payload);
    case AppMessageName.ON_USER_CHALLENGE_COUNTS:
      return userChallengeCountsMessage(message.payload);
    case AppMessageName.ON_USER_CHALLENGE_READ_COUNTS:
      return userChallengeReadCountsMessage(message.payload);
    case AppMessageName.ON_COMP_ENTRY_STATUS:
      return enterCompStatusMessage(message.payload);
    case AppMessageName.ON_LOCAL_ANSWERS:
      return localAnswersMessage(message.payload);
    case AppMessageName.ON_PROFILE_IMAGE_UPLOAD:
      return profileImageUploadMessage(message.payload);
    case AppMessageName.ON_MUTE:
      return muteMessage(message.payload);
    case AppMessageName.ON_AUTH_TEMPORARY_USER_SCORE:
      return authTemporaryUserScore(message.payload);
    case AppMessageName.ON_APP_ACTION:
      const action = message.payload as AppAction;
      if (action === AppAction.GO_HOME) {
        return setCurrentPageIndex(HOME_PAGE_INDEX);
      } else if (action === AppAction.GO_LEADERBOARD) {
        return setCurrentPageIndex(COMP_DETAILS_INDEX);
      }
      log.warning('Unknown app action', message.payload);
      return noOpAction();
    default:
      log.warning('Unhandled message type', message);
      return noOpAction();
  }
};

export const sendNavigateEpic: Epic<
  IAction<AppNavigation>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_NAVIGATE),
    sendMessageStream(AppMessageName.SET_NAVIGATE),
  );

export const sendLoginEpic: Epic<IAction<any>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.SEND_LOGIN),
    sendMessageStream(AppMessageName.REQUEST_LOGIN),
  );

export const sendQuestionAnsweredEpic: Epic<
  IAction<IQuestionAnswer>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_QUESTION_ANSWERED),
    sendMessageStream(AppMessageName.SET_ANSWER),
  );

export const sendQuestionLikedEpic: Epic<
  IAction<IQuestionLike>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_QUESTION_LIKED),
    sendMessageStream(AppMessageName.SET_LIKE),
  );

export const sendFollowEpic: Epic<IAction<string>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.SEND_FOLLOW_USER),
    sendMessageStream(AppMessageName.SET_FOLLOWING),
  );

export const sendUnfollowEpic: Epic<
  IAction<string>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_UNFOLLOW_USER),
    sendMessageStream(AppMessageName.SET_UNFOLLOWING),
  );

export const sendOverlayAddEpic: Epic<
  IAction<IOverlay>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_OVERLAY_ADD),
    sendMessageStream(AppMessageName.REQUEST_OVERLAY),
  );

export const sendAnalyticsEpic: Epic<
  IAction<IAnalyticsPayload>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_ANALYTICS),
    sendMessageStream(AppMessageName.SET_ANALYTICS),
  );

export const sendCurrentScreenEpic: Epic<
  IAction<IAnalyticsPayload>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_CURRENT_SCREEN),
    sendMessageStream(AppMessageName.SET_CURRENT_SCREEN),
  );

export const sendEnterCompetitionEpic: Epic<
  IAction<string>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_ENTER_COMPETITION),
    map((action) => ({
      ...action,
      payload: createCompetitionEntry(state$.value, action.payload),
    })),
    sendMessageStream(AppMessageName.SET_COMP_ENTRY),
  );

export const sendChangeAnswerEpic: Epic<
  IAction<string>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_CHANGE_ANSWER),
    map((action) => ({
      ...action,
      payload: createCompetitionEntry(state$.value, action.payload),
    })),
    sendMessageStream(AppMessageName.SET_ANSWER_CHANGE),
  );

export const sendEditProfileEpic: Epic<
  IAction<string>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SEND_EDIT_PROFILE),
    sendMessageStream(AppMessageName.SET_EDIT_PROFILE),
  );

export const sendMuteEpic: Epic<IAction<string>, IAction<any>, IAppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ActionType.SEND_MUTE),
    sendMessageStream(AppMessageName.SET_MUTE),
  );

const sendMessageStream = (messageName: AppMessageName) =>
  mergeMap((action: IAction<any>) =>
    of(messageName).pipe(
      tap(() =>
        sendMessageToApp(createApiMessage(messageName, action.payload)),
      ),
      filter(() => false),
      map(() => noOpAction()),
    ),
  );

const sendMessageToApp = (message: IApiMessage<any>) => {
  if (window.hasOwnProperty('ReactNativeWebView')) {
    // Running in RN, send to app https://github.com/react-native-community/react-native-webview/blob/master/docs/Guide.md#communicating-between-js-and-native
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
  } else if (window.parent) {
    window.parent.postMessage(message, '*');
  }
};

const createCompetitionEntry = (
  state: IAppState,
  name: string,
): IEnterCompPayload => ({
  name: name,
  competitionId: getCompetitionId(state) as string,
  answers: getCurrentCompetitionsLocalAnswers(state),
  isEntry: getUsersCompetitionEntry(state) === null,
  challengeId: getLaunchChallenge(state)?.id,
});
