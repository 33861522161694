import {ILocalAnswersForComp} from '../interfaces/client/ClientInterfaces';

export enum AppMessageName {
  APP_READY = 'APP_READY',

  ON_CONFIG = 'ON_CONFIG',
  ON_REMOTE_CONFIG = 'ON_REMOTE_CONFIG',

  ON_LOCAL_ANSWERS = 'ON_LOCAL_ANSWERS',
  ON_UPCOMING_COMPS = 'ON_UPCOMING_COMPS',
  ON_COMP = 'ON_COMP',
  ON_COMP_SUMMARY = 'ON_COMP_SUMMARY',
  ON_COMP_QUESTIONS = 'ON_COMP_QUESTIONS',
  ON_COMP_STATUS = 'ON_COMP_STATUS',
  ON_COMP_COUNTS = 'ON_COMP_COUNTS',
  ON_COMP_VIPS = 'ON_COMP_VIPS',
  ON_COMP_CHAT = 'ON_COMP_CHAT',
  ON_COMP_CHAT_LAST_READ = 'ON_COMP_CHAT_LAST_READ',
  ON_COMP_LEADERBOARD_CONFIG = 'ON_COMP_LEADERBOARD_CONFIG',
  ON_COMP_LEADERBOARD = 'ON_COMP_LEADERBOARD',
  ON_AUTH_USER = 'ON_AUTH_USER',
  ON_AUTH_TEMPORARY_USER_SCORE = 'ON_AUTH_TEMPORARY_USER_SCORE',
  ON_USER = 'ON_USER',
  ON_USER_PRIVATE = 'ON_USER_PRIVATE',
  ON_USER_FOLLOWING = 'ON_USER_FOLLOWING',
  ON_USER_ENTRY = 'ON_USER_ENTRY',
  ON_COMP_ENTRY_STATUS = 'ON_COMP_ENTRY_STATUS',
  ON_APP_ACTION = 'ON_APP_ACTION',
  ON_PROFILE_IMAGE_UPLOAD = 'ON_PROFILE_IMAGE_UPLOAD',
  ON_MUTE = 'ON_MUTE',
  ON_USER_CHALLENGES = 'ON_USER_CHALLENGES',
  ON_USER_CHALLENGE_COUNTS = 'ON_USER_CHALLENGE_COUNTS',
  ON_USER_CHALLENGE_READ_COUNTS = 'ON_USER_CHALLENGE_READ_COUNTS',

  SET_NAVIGATE = 'SET_NAVIGATE',
  SET_FOLLOWING = 'SET_FOLLOWING',
  SET_UNFOLLOWING = 'SET_UNFOLLOWING',
  SET_ANSWER = 'SET_ANSWER',
  SET_LIKE = 'SET_LIKE',
  SET_COMP_ENTRY = 'SET_COMP_ENTRY',
  SET_ANSWER_CHANGE = 'SET_ANSWER_CHANGE',
  SET_ANALYTICS = 'SET_ANALYTICS',
  SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN',
  SET_EDIT_PROFILE = 'SET_EDIT_PROFILE',
  SET_MUTE = 'SET_MUTE',

  REQUEST_LOGIN = 'REQUEST_LOGIN',
  REQUEST_OVERLAY = 'REQUEST_OVERLAY',
  REQUEST_USER_BY_ID = 'REQUEST_USER_BY_ID',
}

export enum AppNavigation {
  HOME = 'HOME',
  BACK = 'BACK',
  PATH = 'COMP',
  VENDOR = 'VENDOR',
  LEADERBOARD = 'LEADERBOARD',
  URL = 'URL',
  RELOAD = 'RELOAD',
  GROUP_CHALLENGE_NEW = 'GROUP_CHALLENGE_NEW',
}

export enum AppAction {
  GO_HOME = 'GO_HOME',
  GO_LEADERBOARD = 'GO_LEADERBOARD',
}

export enum GameScreenNames {
  COMPETITION = 'Competition',
  QUESTIONS_COMPLETE = 'QuestionsComplete',
  LEADERBOARD = 'Leaderboard',
  CHAT = 'Chatt', // Needed a unique name to avoid conflict with older clients
  LATEST = 'Latest',
  ANSWERS = 'Answers',
  PRIZES = 'Prizes',
  QUESTION_PREFIX = 'Question_',
  ENTRY_CONFIRMED = 'GameEntryConfirmed',
  ENTRY_LOGIN = 'GameEntryLogin',
  ENTRY_USERNAME = 'GameEntryUsername',
}

export interface IApiMessage<T> {
  name: AppMessageName;
  payload: T;
}

export const createApiMessage = <T>(
  name: AppMessageName,
  payload: T,
): IApiMessage<T> => ({name: name, payload: payload});

export interface IEnterCompPayload {
  competitionId: string;
  name?: string;
  answers: ILocalAnswersForComp;
  isEntry: boolean;
  challengeId?: string;
}

export interface INavigatePayload {
  type: AppNavigation;
  path?: string;
}

export interface IUploadingMediaPayload {
  localImageBeingUploaded: string | null;
  imageUploadProgress: number;
}
