import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {
  COLOR_GREEN,
  COLOR_GREY_6,
  COLOR_RED,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';

import {
  CircleCheckMarkIcon,
  CircleCrossIcon,
  CircleStopWatchIcon,
} from '../Svg/SvgIcons';

interface IProps {
  correctCount: number;
  incorrectCount: number;
  pendingCount: number;
}

export const UserScores: React.FC<IProps> = (props) => {
  return (
    <View style={styles.container}>
      <CircleCheckMarkIcon color={COLOR_GREEN} />
      <Text style={styles.number}>{props.correctCount}</Text>
      <CircleCrossIcon color={COLOR_RED} />
      <Text style={styles.number}>{props.incorrectCount}</Text>
      <CircleStopWatchIcon color={COLOR_GREY_6} />
      <Text style={styles.number}>{props.pendingCount}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    userSelect: 'none',
    flexDirection: 'row',
    alignItems: 'center',
  },
  number: {
    marginLeft: 4,
    marginRight: 8,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 16,
    color: COLOR_GREY_6,
  },
});
