import React from 'react';

import {
  IChatProps,
  messageRendererFactory,
} from '@chancer/components/lib/Chat/Chat';

import {ChatMessageType} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import './Chat.scss';

export const Chat: React.FC<IChatProps> = (props) => {
  // Only include the first message of type ChatMessageType.SYSTEM_LEADERBOARD_UPDATE
  // and remove any the others
  const firstLeaderboardUpdateIdx = props.messages.findIndex(
    (message) => message.type === ChatMessageType.SYSTEM_LEADERBOARD_UPDATE,
  );
  const messages = props.messages.filter(
    (message, idx) =>
      (message.type !== ChatMessageType.SYSTEM_DEFAULT || message.sender) &&
      message.type !== ChatMessageType.SYSTEM_RESOLVED_QUESTION &&
      message.type !== ChatMessageType.SYSTEM_NEW_COMP &&
      message.type !== ChatMessageType.SYSTEM_LEADERBOARD &&
      (message.type !== ChatMessageType.SYSTEM_LEADERBOARD_UPDATE ||
        idx === firstLeaderboardUpdateIdx),
  );

  return (
    <div className="chat__container">
      {messages.map((message, idx) =>
        messageRendererFactory(
          props,
          message,
          props.messages[idx - 1],
          props.messages[idx + 1],
          false,
          true,
          false,
        ),
      )}
    </div>
  );
};
