import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {COLOR_LIGHT, FONT_COPY} from '../Styles/DesignSystem-chancer';
import {PlayersIcon} from '../Svg/SvgIcons';
import {formatNumber} from '@chancer/common/lib/utils/NumberUtils';

interface IProps {
  suffix?: string;
  withIcon: boolean;
  count: number;
}

export const CompPlayers: React.FC<IProps> = (props) => {
  return (
    <View style={styles.playersContainer}>
      {props.withIcon && <PlayersIcon height={11} />}
      <Text style={styles.playersText}>
        {formatNumber(props.count)}
        {props.suffix ? ` ${props.suffix}` : ''}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  playersContainer: {
    opacity: 0.8,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
  },
  playersText: {
    marginLeft: 1,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY,
    fontSize: 14,
  },
});
