interface IClassNameProps {
  className?: string;
}

export const applyPropClassName = (
  className: string,
  props: IClassNameProps,
) => {
  return `${className} ${props.className !== undefined ? props.className : ''}`;
};

export const applyWindowsScrollStyle = (className: string) =>
  navigator.appVersion.indexOf('Win') !== -1
    ? `${className} windows_scroll-style`
    : className;
