import React, {ComponentType, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Observable} from 'rxjs';

import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  TLeaderboardPrize,
  TLeaderboardSummaryAndLeaders,
  TMediaEntry,
  TMultiGameLeader,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {filter} from '@chancer/common/lib/utils/LeaderboardUtils';
import {assertType} from '@chancer/common/lib/utils/TypeUtils';
import {PrimaryButton} from '../Button/PrimaryButton';
import {COLOR_BACKGROUND} from '../Styles/DesignSystem-chancer';
import {MultiGameEntrantItem} from './MultiGameEntrantItem';
import {SummaryHeader} from './SummaryHeader';

interface IProps {
  visible?: boolean;
  privateMode?: boolean;
  invalidationKey: string;
  leaderboardId: string;
  title?: string;
  vendorImageUrl?: string;
  primaryColor: string;
  currentUser: TFirebaseUser | null;
  users?: string[];
  followingMap: {[key: string]: boolean};
  MediaComponent: ComponentType<{media: TMediaEntry}>;
  leadersIfOverMax?: number;
  minLeaderboardLength?: number;
  maxLeaderboardLength?: number;
  prizes?: TLeaderboardPrize[];
  getUserById: (userId: string) => Observable<TFirebaseUser>;
  getMultiGameLeaderboardSummaryById: (
    leaderboardId: string,
    invalidationKey?: string,
    length?: number,
  ) => Observable<TLeaderboardSummaryAndLeaders>;
  onUserSelected: (user: TFirebaseUser) => void;
  onGoToLeaderboard: () => void;
}

export const MultiGameLeaderboardSummary: React.FC<IProps> = (props) => {
  const {
    invalidationKey,
    visible = true,
    privateMode = false,
    leaderboardId,
    title,
    prizes,
    vendorImageUrl,
    currentUser,
    primaryColor,
    onGoToLeaderboard,
    getMultiGameLeaderboardSummaryById,
    users,
    leadersIfOverMax,
    minLeaderboardLength = 3,
    maxLeaderboardLength = 3,
  } = props;

  const colorConfig = getColorConfiguration(primaryColor);

  const [leaderboardSummaryAndLeaders, setLeaderboardSummaryAndLeaders] =
    useState<TLeaderboardSummaryAndLeaders | null>(null);
  const [currentInvalidation, setCurrentInvalidation] =
    useState(invalidationKey);
  const [showLeaderboardButton, setShowLeaderboardButton] = useState(false);

  useEffect(() => {
    const nextHandler = (l: TLeaderboardSummaryAndLeaders) => {
      const sliceLength =
        l.leaders.length > maxLeaderboardLength
          ? leadersIfOverMax ?? maxLeaderboardLength
          : maxLeaderboardLength;
      setShowLeaderboardButton(l.leaders.length > maxLeaderboardLength);
      setLeaderboardSummaryAndLeaders(
        assertType<TLeaderboardSummaryAndLeaders>({
          ...l,
          leaders: l.leaders.slice(0, sliceLength),
        }),
      );
    };

    if (
      leaderboardSummaryAndLeaders === null ||
      invalidationKey !== currentInvalidation
    ) {
      setCurrentInvalidation(invalidationKey ?? '');
      if (users === undefined) {
        getMultiGameLeaderboardSummaryById(
          leaderboardId,
          invalidationKey,
          maxLeaderboardLength + 1,
        ).subscribe({
          next: nextHandler,
        });
      } else {
        getMultiGameLeaderboardSummaryById(
          leaderboardId,
          invalidationKey,
        ).subscribe({
          next: (l) => {
            const leaders = filter(l.leaders, (leader) =>
              users.includes(leader.u),
            );
            nextHandler({
              ...l,
              leaders,
              totalLeadersCount: leaders.length,
            });
          },
        });
      }
    }
  }, [
    getMultiGameLeaderboardSummaryById,
    invalidationKey,
    currentInvalidation,
    leaderboardSummaryAndLeaders,
    maxLeaderboardLength,
    leadersIfOverMax,
    leaderboardId,
    users,
  ]);

  return (
    visible &&
    leaderboardSummaryAndLeaders &&
    leaderboardSummaryAndLeaders.leaders.length >= minLeaderboardLength && (
      <View style={styles.container}>
        <SummaryHeader
          vendorImageUrl={vendorImageUrl}
          backgroundColor={colorConfig.leaderboardSummaryHeaderBackground}
          title={title ?? leaderboardSummaryAndLeaders.shortName}
          subTitle={
            privateMode
              ? leaderboardSummaryAndLeaders.privateCaption ??
                leaderboardSummaryAndLeaders.media.caption ??
                ''
              : leaderboardSummaryAndLeaders.media.caption ?? ''
          }
          players={leaderboardSummaryAndLeaders.totalLeadersCount}
          media={leaderboardSummaryAndLeaders.media}
          prizes={prizes}
          MediaComponent={props.MediaComponent}
          onPress={onGoToLeaderboard}
        />
        <View style={styles.contentContainer}>
          {leaderboardSummaryAndLeaders.leaders.map((player, index) => {
            const isCurrentUser = currentUser?.id === player.u;
            return (
              <View
                key={player.u}
                style={
                  isCurrentUser
                    ? styles.currentUserLeaderContainer
                    : styles.leaderContainer
                }>
                <MultiGameEntrantItem
                  position={index + 1}
                  visible={visible}
                  player={player as any as TMultiGameLeader}
                  currentUser={isCurrentUser ? currentUser : null}
                  isFollowing={props.followingMap[player.u] === true}
                  colorConfig={colorConfig}
                  getUserById={props.getUserById}
                  onPress={props.onUserSelected}
                />
              </View>
            );
          })}
          <View
            style={[
              styles.footerContainer,
              {backgroundColor: colorConfig.leaderboardFooterBackground},
            ]}>
            {showLeaderboardButton && (
              <PrimaryButton
                style={styles.button}
                label="Show all fans"
                onPress={onGoToLeaderboard}
              />
            )}
          </View>
        </View>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {},
  leaderContainer: {},
  currentUserLeaderContainer: {},
  contentContainer: {
    backgroundColor: COLOR_BACKGROUND,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    overflow: 'hidden',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 2,
    padding: 16,
    minHeight: 56,
  },
  button: {
    width: '100%',
  },
});
