import {IAction, ActionType} from '../Actions';
import {IRemoteConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {IAppConfig} from '@chancer/common/lib/interfaces/client/ClientInterfaces';

export const setConfig = (config: IAppConfig): IAction<IAppConfig> => ({
  type: ActionType.CONFIG_MESSAGE,
  payload: config,
});

export const setRemoteConfig = (
  config: IRemoteConfig,
): IAction<IRemoteConfig> => ({
  type: ActionType.REMOTE_CONFIG_MESSAGE,
  payload: config,
});
