import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import React from 'react';
import {Avatar} from './Avatar';
import {COLOR_YELLOW} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  size?: number;
  user: TFirebaseUser | null;
  isFollowing?: boolean;
  isWinner?: boolean;
  isCurrentUser?: boolean;
  isEnteredSoFar?: boolean;
}

const HIGHLIGHT_WIDTH = 3;
const HIGHLIGHT_GAP = 0;

export const LeaderboardAvatar: React.FC<IProps> = (props) => {
  const size = props.size ?? 48;
  const isFollowing = props.isFollowing === true;
  const isCurrentUser = props.isCurrentUser === true;

  const hasHighlight = isFollowing || isCurrentUser;

  return (
    <Avatar
      style={props.style}
      size={size}
      imageUrl={props.user?.media?.image?.url}
      highlightColor={COLOR_YELLOW}
      highlightGap={
        hasHighlight ? HIGHLIGHT_GAP : HIGHLIGHT_WIDTH + HIGHLIGHT_GAP
      }
      highlightWidth={hasHighlight ? HIGHLIGHT_WIDTH : 0}
    />
  );
};
