import {
  TFirebaseChatMessage,
  TFirebaseComp,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompStatus,
  TFirebaseCompSummary,
  TFirebaseGroup,
  TFirebaseGroupCounts,
  TFirebaseLeaderboard,
  TFirebaseLeaders,
  TFirebaseQuestion,
  TFirebaseUser,
  TFirebaseUserFollowList,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';

import {
  AppNavigation,
  INavigatePayload,
  IUploadingMediaPayload,
} from '@chancer/common/lib/app/AppMessage';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  EnterCompStatus,
  IAnalyticsPayload,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {ActionType, IAction} from '../Actions';

export const sendNavigate = (
  nav: AppNavigation,
  path?: string,
): IAction<INavigatePayload> => ({
  type: ActionType.SEND_NAVIGATE,
  payload: {type: nav, path: path},
});

export const sendLogin = (isAnonymous: boolean): IAction<boolean> => ({
  type: ActionType.SEND_LOGIN,
  payload: isAnonymous,
});

export const sendAnalytics = (
  event: IAnalyticsPayload,
): IAction<IAnalyticsPayload> => ({
  type: ActionType.SEND_ANALYTICS,
  payload: event,
});

export const sendCurrentScreen = (screenName: string): IAction<string> => ({
  type: ActionType.SEND_CURRENT_SCREEN,
  payload: screenName,
});

export const sendEnterCompetition = (name: string): IAction<string> => ({
  type: ActionType.SEND_ENTER_COMPETITION,
  payload: name,
});

export const sendChangeAnswer = (name: string): IAction<string> => ({
  type: ActionType.SEND_CHANGE_ANSWER,
  payload: name,
});

export const sendFollowUser = (userId: string): IAction<string> => ({
  type: ActionType.SEND_FOLLOW_USER,
  payload: userId,
});

export const sendUnfollowUser = (userId: string): IAction<string> => ({
  type: ActionType.SEND_UNFOLLOW_USER,
  payload: userId,
});

export const sendEditProfile = (): IAction<null> => ({
  type: ActionType.SEND_EDIT_PROFILE,
  payload: null,
});

export const sendMute = (): IAction<null> => ({
  type: ActionType.SEND_MUTE,
  payload: null,
});

export const enterCompetitionStatusMessage = (
  competition: EnterCompStatus,
): IAction<EnterCompStatus> => ({
  type: ActionType.ENTER_COMP_STATUS_MESSAGE,
  payload: competition,
});

export const upcomingCompetitionsMessage = (
  competitions: ICompSummaryAndEntry[],
): IAction<ICompSummaryAndEntry[]> => ({
  type: ActionType.UPCOMING_COMPETITIONS_MESSAGE,
  payload: competitions,
});

export const competitionMessage = (
  competition: TFirebaseComp,
): IAction<TFirebaseComp> => ({
  type: ActionType.COMPETITION_MESSAGE,
  payload: competition,
});

export const competitionSummaryMessage = (
  competition: TFirebaseCompSummary,
): IAction<TFirebaseCompSummary> => ({
  type: ActionType.COMPETITION_SUMMARY_MESSAGE,
  payload: competition,
});

export const questionsMessage = (
  questions: TFirebaseQuestion[],
): IAction<TFirebaseQuestion[]> => ({
  type: ActionType.QUESTIONS_MESSAGE,
  payload: questions,
});

export const userMessage = (user: TFirebaseUser): IAction<TFirebaseUser> => ({
  type: ActionType.USER_MESSAGE,
  payload: user,
});

export const userFollowingMessage = (
  user: TFirebaseUserFollowList,
): IAction<TFirebaseUserFollowList> => ({
  type: ActionType.USER_FOLLOWING_MESSAGE,
  payload: user,
});

export const leaderboardConfigMessage = (
  leaderboardConfig: TFirebaseLeaderboard,
): IAction<TFirebaseLeaderboard> => ({
  type: ActionType.LEADERBOARD_CONFIG_MESSAGE,
  payload: leaderboardConfig,
});

export const leaderboardMessage = (
  entrants: TFirebaseLeaders,
): IAction<TFirebaseLeaders> => ({
  type: ActionType.LEADERBOARD_MESSAGE,
  payload: entrants,
});

export const competitionCountsMessage = (
  competitionCounts: TFirebaseCompCounts,
): IAction<TFirebaseCompCounts> => ({
  type: ActionType.COMPETITION_COUNTS_MESSAGE,
  payload: competitionCounts,
});

export const competitionVipsMessage = (
  competitionVips: TFirebaseUser[],
): IAction<TFirebaseUser[]> => ({
  type: ActionType.COMPETITION_VIPS_MESSAGE,
  payload: competitionVips,
});

export const competitionStatusMessage = (
  status: TFirebaseCompStatus,
): IAction<TFirebaseCompStatus> => ({
  type: ActionType.COMPETITION_STATUS_MESSAGE,
  payload: status,
});

export const competitionChatMessage = (
  chat: TFirebaseChatMessage[],
): IAction<TFirebaseChatMessage[]> => ({
  type: ActionType.COMPETITION_CHAT_MESSAGE,
  payload: chat,
});

export const competitionChatLastRead = (
  readDateMs: number,
): IAction<number> => ({
  type: ActionType.COMPETITION_CHAT_READ_DATE,
  payload: readDateMs,
});

export const userEntryMessage = (
  competitionEntryStatus: TFirebaseCompEntry,
): IAction<TFirebaseCompEntry> => ({
  type: ActionType.USER_ENTRY_MESSAGE,
  payload: competitionEntryStatus,
});
export const enterCompStatusMessage = (
  competitionEntryStatus: TFirebaseCompEntry,
): IAction<TFirebaseCompEntry> => ({
  type: ActionType.ENTER_COMP_STATUS_MESSAGE,
  payload: competitionEntryStatus,
});

export const profileImageUploadMessage = (
  payload: IUploadingMediaPayload,
): IAction<IUploadingMediaPayload> => ({
  type: ActionType.PROFILE_IMAGE_UPLOAD,
  payload,
});

export const muteMessage = (payload: boolean): IAction<boolean> => ({
  type: ActionType.MUTE,
  payload,
});

export const userChallengesMessage = (
  payload: TFirebaseGroup[],
): IAction<TFirebaseGroup[]> => ({
  type: ActionType.USER_CHALLENGES,
  payload,
});

export const userChallengeCountsMessage = (payload: {
  [challengeId: string]: TFirebaseGroupCounts;
}): IAction<{[challengeId: string]: TFirebaseGroupCounts}> => ({
  type: ActionType.USER_CHALLENGE_COUNTS,
  payload,
});

export const userChallengeReadCountsMessage = (payload: {
  [challengeId: string]: number;
}): IAction<{[challengeId: string]: number}> => ({
  type: ActionType.USER_CHALLENGE_READ_COUNTS,
  payload,
});

export const authTemporaryUserScore = (payload: number): IAction<number> => ({
  type: ActionType.AUTH_TEMPORARY_USER_SCORE_MESSAGE,
  payload,
});
