import {getFormattedMessageDate} from '@chancer/common/lib/utils/DateUtils';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {COLOR_GREY_6, FONT_COPY} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  date: Date | undefined;
  title: string | undefined;
}

export const ChatDate: React.FC<IProps> = (props) =>
  props.date ? (
    <View style={[styles.container, props.style]}>
      <Text style={styles.copy}>
        {getFormattedMessageDate(props.date)}
        {props.title ? ` · ${props.title}` : ''}
      </Text>
    </View>
  ) : null;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 8,
  },
  copy: {
    fontSize: 13,
    fontFamily: FONT_COPY,
    color: COLOR_GREY_6,
  },
});
