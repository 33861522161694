import {Epic, ofType} from 'redux-observable';
import {map, filter} from 'rxjs/operators';
import {ActionType, IAction} from '../../actions/Actions';
import {IAppState, ILocalUser} from '../../state/AppState';
import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {getLocalUser} from '../../selectors/user/AppUserSelectors';
import {setLocalUser} from '../../actions/user/UserActions';

export const userUpdatedEpic: Epic<
  IAction<TFirebaseUser>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.USER_MESSAGE),
    filter((action) =>
      doesUserNeedUpdate(action.payload, getLocalUser(state$.value)),
    ),
    map((action) =>
      getUpdatedLocalUser(action.payload, getLocalUser(state$.value)),
    ),
    map((localUser) => setLocalUser(localUser.name, localUser.imageUrl)),
  );

const doesUserNeedUpdate = (user: TFirebaseUser, localUser: ILocalUser) => {
  return (
    (user.name !== localUser.name && isValidString(user.name)) ||
    (user.media?.image?.url !== localUser.imageUrl &&
      isValidString(user.media?.image?.url))
  );
};

const getUpdatedLocalUser = (
  user: TFirebaseUser,
  localUser: ILocalUser,
): ILocalUser => {
  return {
    name: isValidString(user.name) ? user.name : localUser.name,
    imageUrl: isValidString(user.media?.image?.url)
      ? (user.media?.image?.url as string)
      : localUser.imageUrl,
  };
};

const isValidString = (value: string | null | undefined): value is string =>
  value !== null && value !== undefined && value !== '';
