import {Reducer} from 'redux';

import {IRemoteConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import {IAppConfig} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {ActionType, IAction} from '../../actions/Actions';

export const configReducer: Reducer<
  {
    config: IAppConfig | null;
    remoteConfig: IRemoteConfig | null;
  },
  IAction<IAppConfig | IRemoteConfig>
> = (state, action) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.CONFIG_MESSAGE:
      return {
        ...state,
        config: action.payload as IAppConfig,
      };
    case ActionType.REMOTE_CONFIG_MESSAGE:
      return {
        ...state,
        remoteConfig: action.payload as IRemoteConfig,
      };
  }
  return state;
};
