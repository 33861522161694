import {Reducer} from 'react';

import {undefinedInReducerError} from '@chancer/common/lib/core/reducers/ReducerUtils';
import {ActionType, IAction} from '../../actions/Actions';
import {ILocalUser} from '../../state/AppState';

export const localUserReducer: Reducer<ILocalUser, IAction<ILocalUser>> = (
  state,
  action,
) => {
  if (state === undefined) {
    throw undefinedInReducerError;
  }
  switch (action.type) {
    case ActionType.SET_LOCAL_USER:
      return action.payload;
  }
  return state;
};
