import Color from 'color';
import React, {useMemo} from 'react';
import {Dimensions, StyleSheet, Text, View} from 'react-native';
import {Observable} from 'rxjs';

import {TFirebaseChatMessage} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {TOpenGraph} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_BLUE,
  COLOR_LIGHT,
  COLOR_PURPLE,
  FONT_COPY,
} from '../Styles/DesignSystem-chancer';
import {CHAT_URL_REGEX, ChatMessageGroup} from './Chat';
import {ChatDate} from './ChatDate';
import {ChatImage} from './ChatImage';
import {OpenGraphCard} from './OpenGraphCard';

interface IProps {
  isUser: boolean;
  group: ChatMessageGroup;
  message: TFirebaseChatMessage;
  getOpenGraph: (url: string) => Observable<TOpenGraph>;
  getHostname: (url: string) => string | undefined;
  onUrlPress: (url: string | undefined) => void;
}

const MAX_SIZE = Math.round(
  Dimensions.get('window').width - (16 + 16 + 32 + 8),
);

export const ChatMessage: React.FC<IProps> = (props) => {
  const {message, group, isUser} = props;

  const messageParts = useMemo(
    () => message.message.split(CHAT_URL_REGEX),
    [message.message],
  );

  const messageUrls = useMemo(
    () => messageParts.filter((part) => part.match(CHAT_URL_REGEX)),
    [messageParts],
  );

  const iconContainerStyle = useMemo(
    () => [
      styles.iconContainer,
      ...(group === ChatMessageGroup.GROUP_START ||
      group === ChatMessageGroup.GROUP
        ? [styles.groupedMessageSpacer]
        : []),
    ],
    [group],
  );

  const imageContainerStyle = useMemo(
    () => [isUser ? styles.imageContainerUser : styles.imageContainer],
    [isUser],
  );

  const imageStyle = useMemo(
    () => [
      isUser ? styles.imageUser : styles.image,
      ...(group === ChatMessageGroup.GROUP_START ||
      group === ChatMessageGroup.GROUP ||
      message.message.length > 0
        ? [isUser ? styles.messageUserStart : styles.messageStart]
        : []),
      ...(group === ChatMessageGroup.GROUP_END ||
      group === ChatMessageGroup.GROUP
        ? [isUser ? styles.messageUserEnd : styles.messageEnd]
        : []),
    ],
    [isUser, group, message],
  );

  const ogStyle = useMemo(
    () => [
      {width: MAX_SIZE},
      isUser ? styles.messageUserEnd : styles.messageEnd,
      ...(group === ChatMessageGroup.GROUP_START ||
      group === ChatMessageGroup.GROUP
        ? [isUser ? styles.messageUserStart : styles.messageStart]
        : []),
    ],
    [isUser, group],
  );

  const messageBubbleStyle = useMemo(
    () => [
      isUser
        ? [
            styles.messageUser,
            ...(group === ChatMessageGroup.GROUP_START ||
            group === ChatMessageGroup.GROUP ||
            messageUrls.length > 0
              ? [styles.messageUserStart]
              : []),
            ...(group === ChatMessageGroup.GROUP_END ||
            group === ChatMessageGroup.GROUP ||
            message.payload?.imageUrl
              ? [styles.messageUserEnd]
              : []),
          ]
        : [
            styles.message,
            ...(group === ChatMessageGroup.GROUP_START ||
            group === ChatMessageGroup.GROUP ||
            messageUrls.length > 0
              ? [styles.messageStart]
              : []),
            ...(group === ChatMessageGroup.GROUP_END ||
            group === ChatMessageGroup.GROUP ||
            message.payload?.imageUrl
              ? [styles.messageEnd]
              : []),
          ],
    ],
    [group, isUser, message.payload?.imageUrl, messageUrls.length],
  );

  const _urlWithProtocol = (url: string) => {
    return url.match(/^[a-zA-Z]+:\/\//) ? url : `https://${url}`;
  };

  return (
    <View style={styles.dateContainer}>
      {(group === ChatMessageGroup.NONE ||
        group === ChatMessageGroup.GROUP_START) && (
        <ChatDate date={props.message.created?.toDate()} title={undefined} />
      )}
      <View style={iconContainerStyle}>
        {!props.isUser &&
        (group === ChatMessageGroup.NONE ||
          group === ChatMessageGroup.GROUP_END) ? (
          <Avatar
            style={styles.icon}
            imageUrl={props.message.iconUrl}
            highlightWidth={0}
            size={32}
          />
        ) : (
          <View style={styles.icon} />
        )}
        <View style={imageContainerStyle}>
          {message.payload?.imageUrl && (
            <ChatImage
              style={imageStyle}
              imageUrl={message.payload.imageUrl}
              maxSize={isUser ? MAX_SIZE : MAX_SIZE - 16}
            />
          )}
          {message.message.length > 0 && (
            <View style={messageBubbleStyle}>
              {!props.isUser &&
                (group === ChatMessageGroup.NONE ||
                  group === ChatMessageGroup.GROUP_START) && (
                  <View style={styles.senderContainer}>
                    <Text style={styles.sender}>{props.message.sender}</Text>
                  </View>
                )}
              <Text>
                {messageParts.map((part, index) =>
                  messageUrls.includes(part) ? (
                    <Text
                      key={index}
                      style={styles.messageLink}
                      onPress={() => props.onUrlPress(_urlWithProtocol(part))}>
                      {part}
                    </Text>
                  ) : (
                    <Text key={index} style={styles.messageText}>
                      {part}
                    </Text>
                  ),
                )}
              </Text>
            </View>
          )}
          {messageUrls.length > 0 && (
            <OpenGraphCard
              containerStyle={ogStyle}
              imageStyle={ogStyle}
              url={_urlWithProtocol(messageUrls[0])}
              getHostname={props.getHostname}
              getOpenGraphStream={props.getOpenGraph}
              onPress={props.onUrlPress}
            />
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dateContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  icon: {
    marginRight: 8,
    height: 32,
    width: 32,
  },
  imageContainer: {
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 16,
    width: '100%',
  },
  imageContainerUser: {
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
    width: '100%',
  },
  image: {
    alignSelf: 'flex-start',
    borderRadius: 16,
    marginBottom: 4,
    overflow: 'hidden',
  },
  imageUser: {
    alignSelf: 'flex-end',
    borderRadius: 16,
    marginBottom: 4,
    overflow: 'hidden',
  },
  messageUser: {
    backgroundColor: COLOR_PURPLE,
    paddingHorizontal: 8,
    paddingVertical: 11,
    borderRadius: 16,
  },
  messageUserStart: {
    borderBottomRightRadius: 2,
  },
  messageUserEnd: {
    borderTopRightRadius: 2,
  },
  senderContainer: {
    marginBottom: 8,
  },
  sender: {
    fontSize: 14,
    fontFamily: FONT_COPY,
    color: COLOR_BLUE,
  },
  message: {
    backgroundColor: new Color(COLOR_PURPLE)
      .desaturate(0.6)
      .darken(0.6)
      .rgb()
      .string(),
    paddingHorizontal: 8,
    paddingVertical: 11,
    borderRadius: 16,
  },
  messageStart: {
    borderBottomLeftRadius: 2,
  },
  messageEnd: {
    borderTopLeftRadius: 2,
  },
  groupedMessageSpacer: {
    marginBottom: 8,
  },
  messageText: {
    fontSize: 16,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY,
    flexWrap: 'wrap',
  },
  messageLink: {
    fontSize: 16,
    fontFamily: FONT_COPY,
    color: COLOR_BLUE,
    textDecorationLine: 'underline',
    flexWrap: 'wrap',
  },
});
