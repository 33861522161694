import Color from 'color';

/**
 * If we get a better pattern develop regarding colors consider using more generic names
 */
export interface IColorConfiguration {
  color: string;
  leaderboardHeaderBackground: string;
  leaderboardSummaryHeaderBackground: string;
  leaderboardItemsBackground: string;
  leaderboardUsersItemBackground: string;
  leaderboardFooterBackground: string;
  questionBackgroundGradient: string;
}

const COLOR_MAP: Map<string, IColorConfiguration> = new Map();

const createColorConfiguration = (
  primaryColor: string,
): IColorConfiguration => ({
  color: primaryColor,
  leaderboardHeaderBackground: new Color(primaryColor)
    .darken(0.6)
    .desaturate(0.4)
    .fade(0.2)
    .rgb()
    .string(),
  leaderboardSummaryHeaderBackground: new Color(primaryColor)
    .darken(0.2)
    .fade(0.2)
    .rgb()
    .string(),
  leaderboardItemsBackground: new Color(primaryColor)
    .desaturate(0.7)
    .lightness(28)
    .rgb()
    .darken(0.25)
    .string(),
  leaderboardUsersItemBackground: new Color(primaryColor)
    .desaturate(0.7)
    .lightness(28)
    .rgb()
    .darken(0.5)
    .string(),
  leaderboardFooterBackground: new Color(primaryColor)
    .darken(0.2)
    .fade(0.5)
    .lightness(15)
    .rgb()
    .string(),
  questionBackgroundGradient: new Color(primaryColor)
    .desaturate(0.7)
    .lightness(28)
    .rgb()
    .darken(0.25)
    .string(),
});

export const getColorConfiguration = (primaryColor: string | undefined) => {
  primaryColor = primaryColor ?? '#000000';
  if (!COLOR_MAP.has(primaryColor)) {
    COLOR_MAP.set(primaryColor, createColorConfiguration(primaryColor));
  }
  return COLOR_MAP.get(primaryColor) as IColorConfiguration;
};
