import React, {useCallback, useEffect, useState} from 'react';
import {Animated, StyleSheet, Text, View} from 'react-native';

import {
  CompQuestionType,
  QuestionStatus,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  getFormattedStartTime,
  MILLISECONDS_IN_HOUR,
} from '@chancer/common/lib/utils/DateUtils';
import {platformValue} from '../PlatformUtils';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_DARK,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_COPY,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {LockIcon} from '../Svg/SvgIcons';
import {LabelState, QuestionState} from './QuestionItem';

interface IProps {
  questionNumber: number;
  questionState: QuestionState;
  questionStatus: QuestionStatus;
  questionType: CompQuestionType;
  starts?: Date;
  score?: number;
  updated?: boolean;
}

export const QuestionItemUserHeader: React.FC<IProps> = (props) => {
  const {starts, questionStatus, updated} = props;

  const getTimeLeft = useCallback(() => {
    if (
      starts === undefined ||
      questionStatus !== QuestionStatus.OPEN_CHANGEABLE
    ) {
      return undefined;
    }

    const msRemaining = starts.valueOf() - new Date().valueOf();
    return msRemaining > 0
      ? getFormattedStartTime(starts, {
          numUnits: 2,
          compressedFormat: true,
          showDurationFromMs: MILLISECONDS_IN_HOUR * 2,
        })
      : undefined;
  }, [starts, questionStatus]);

  const [fadeAnim] = useState(new Animated.Value(0));
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: updated ? 1 : 0,
      duration: 600,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim, updated]);

  useEffect(() => {
    if (timeLeft !== undefined && timeLeft.nextIntervalMs > 0) {
      let timeout = setTimeout(
        () => {
          setTimeLeft(getTimeLeft());
        },
        platformValue(timeLeft.nextIntervalMs, {
          android: Math.min(60000, timeLeft.nextIntervalMs),
        }),
      );
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [getTimeLeft, starts, timeLeft, updated]);

  return (
    <View style={styles.container}>
      <Text style={[styles.questionNumberFont, styles.questionNumber]}>
        Q{props.questionNumber}
      </Text>
      <Animated.View
        style={[styles.updatedBadgeContainer, {opacity: fadeAnim}]}>
        <LabelBadge
          style={styles.badge}
          labelColor={COLOR_DARK}
          label="UPDATED"
        />
      </Animated.View>
      <View style={styles.badgeContainer}>
        {props.questionStatus === QuestionStatus.OPEN_CHANGEABLE &&
          timeLeft !== undefined && (
            <Text style={styles.timeLeft}>{timeLeft.text}</Text>
          )}
        {(props.questionStatus === QuestionStatus.OPEN ||
          props.questionStatus === QuestionStatus.OPEN_CHANGEABLE) &&
          starts !== undefined && (
            <View style={styles.timeLeftLock}>
              <LockIcon color={COLOR_YELLOW} height={16} width={16} />
            </View>
          )}
        <LabelState
          style={styles.badge}
          state={props.questionState}
          type={props.questionType}
        />
      </View>
      {props.score !== undefined &&
        props.questionType !== CompQuestionType.SURVEY0 && (
          <Text style={[styles.questionNumberFont, styles.questionPoints]}>
            {props.score}
          </Text>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 24,
  },
  updatedBadgeContainer: {
    display: 'flex',
    marginLeft: 8,
  },
  timeLeft: {
    color: COLOR_YELLOW,
    fontFamily: FONT_COPY,
    fontSize: 13,
  },
  timeLeftLock: {
    marginLeft: 2,
    marginRight: 8,
    marginBottom: 4,
  },
  badgeContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  badge: {
    alignSelf: 'auto',
  },
  questionNumberFont: {
    color: COLOR_LIGHT,
    fontFamily: FONT_SUB_TITLE,
    fontSize: 16,
  },
  questionNumber: {
    marginRight: 8,
  },
  questionPoints: {
    marginLeft: 8,
  },
});
