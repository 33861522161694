import {Static, TSchema, Type} from '@sinclair/typebox';

export type BuildEnvironment =
  | 'development'
  | 'staging'
  | 'production'
  | 'local';

export const TTimestamp = Type.Recursive((TThis) =>
  Type.Object(
    {
      seconds: Type.Readonly(Type.Number()),
      nanoseconds: Type.Readonly(Type.Number()),
      toDate: Type.Function([], Type.Date()),
      toMillis: Type.Function([], Type.Number()),
      isEqual: Type.Function([TThis], Type.Boolean()),
      valueOf: Type.Function([], Type.String()),
    },
    {default: undefined},
  ),
);
export type TTimestamp = Static<typeof TTimestamp>;

export const MAX_USER_NAME_LENGTH = 25;
export const MAX_USER_TAGLINE_LENGTH = 50;
export const MAX_USER_TEAM_LENGTH = 50;
export const MAX_USER_HOMETOWN_LENGTH = 25;
export const MAX_GROUP_NAME_LENGTH = 25;

export const TImageMediaEntry = Type.Object({
  image: Type.Optional(
    Type.Object({
      url: Type.String(),
    }),
  ),
  caption: Type.Optional(Type.String()),
});
export type TImageMediaEntry = Static<typeof TImageMediaEntry>;

export const TVideoMediaEntry = Type.Object({
  video: Type.Optional(
    Type.Object({
      url: Type.String(),
    }),
  ),
  caption: Type.Optional(Type.String()),
});
export type TVideoMediaEntry = Static<typeof TVideoMediaEntry>;

export const TSocialMediaEntry = Type.Object({
  social: Type.Optional(
    Type.Object({
      embed: Type.String(),
    }),
  ),
  caption: Type.Optional(Type.String()),
});
export type TSocialMediaEntry = Static<typeof TSocialMediaEntry>;

export const TMediaEntry = Type.Composite([
  TImageMediaEntry,
  TVideoMediaEntry,
  TSocialMediaEntry,
]);
export type TMediaEntry = Static<typeof TMediaEntry>;

export const TLeader = Type.Object({
  u: Type.String(), // userId
  s: Type.Number(), // score
  o: Type.Number(), // sort
  d: Type.Optional(Type.Number()), // position change on last rank
  w: Type.Optional(Type.Boolean()), // winner
});
export type TLeader = Static<typeof TLeader>;

export const TMultiGameLeader = Type.Composite([
  TLeader,
  Type.Object({
    n: Type.Number(), // number of comps
    t: Type.Number(), // total points
    f: Type.Number(), // number of top tier finishes
  }),
]);
export type TMultiGameLeader = Static<typeof TMultiGameLeader>;

const TLeadersSchema = <L extends TSchema>(schema: L) =>
  Type.Object({leaders: Type.Array(schema)});
export const TLeaders = TLeadersSchema(TLeader);
export type TLeaders = Static<typeof TLeaders>;
export const TMultiGameLeaders = TLeadersSchema(TMultiGameLeader);
export type TMultiGameLeaders = Static<typeof TMultiGameLeaders>;

export enum LeaderboardType {
  CHANCER = 'chancer',
  MULTIGAME = 'multigame',
  INGAME = 'ingame',
  BEST_2 = 'best2',
  BEST_3 = 'best3',
  BEST_4 = 'best4',
  BEST_5 = 'best5',
  BEST_6 = 'best6',
  BEST_7 = 'best7',
  BEST_8 = 'best8',
  BEST_9 = 'best9',
  BEST_10 = 'best10',
}
export const TLeaderboardType = Type.Enum(LeaderboardType, {
  default: LeaderboardType.CHANCER,
});

export const TUserCompletedLeaderboard = Type.Object({
  id: Type.String(),
  type: TLeaderboardType,
  points: Type.Number(),
  position: Type.Number(),
  players: Type.Number(),
  winner: Type.Optional(Type.Boolean()),
});
export type TUserCompletedLeaderboard = Static<
  typeof TUserCompletedLeaderboard
>;

export const TUserCompletedComp = Type.Object({
  compId: Type.String(),
  leaderboards: Type.Array(TUserCompletedLeaderboard),
});
export type TUserCompletedComp = Static<typeof TUserCompletedComp>;

export const TUserCompletedChallenge = Type.Object({
  compId: Type.String(),
  challengeId: Type.String(),
  users: Type.Array(Type.String()),
  result: Type.Array(Type.Number()),
  streak: Type.Number(),
  longestStreak: Type.Array(Type.Number()),
  streakVendor: Type.Number(),
  longestStreakVendor: Type.Array(Type.Number()),
  wins: Type.Array(Type.Number()),
  winsVendor: Type.Array(Type.Number()),
  totalPlayed: Type.Number(),
  totalPlayedVendor: Type.Number(),
});
export type TUserCompletedChallenge = Static<typeof TUserCompletedChallenge>;

export const TUserCompletedAchievement = Type.Object({
  compId: Type.String(),
  score: Type.Number(),
  achievements: Type.Array(Type.Number()),
});
export type TUserCompletedAchievement = Static<
  typeof TUserCompletedAchievement
>;

export const TUserOptionalData = Type.Object({
  completedComps: Type.Optional(Type.Array(TUserCompletedComp)),
  completedChallenges: Type.Optional(Type.Array(TUserCompletedChallenge)),
  completedAchievements: Type.Optional(Type.Array(TUserCompletedAchievement)),
  achievementsNew: Type.Optional(Type.Array(Type.Number())),
  vendorAchievementsNew: Type.Optional(
    Type.Record(Type.String(), Type.Array(Type.Number())),
  ),
  hometown: Type.Optional(Type.String()),
  tagline: Type.Optional(Type.String()),
  team: Type.Optional(Type.String()),
});
export type TUserOptionalData = Static<typeof TUserOptionalData>;

export const TUser = Type.Composite([
  TUserOptionalData,
  Type.Object({
    created: Type.Optional(TTimestamp),
    updated: Type.Optional(TTimestamp),
    name: Type.String(),
    media: Type.Optional(TMediaEntry),
    achievements: Type.Array(Type.Number()),
    vendorAchievements: Type.Optional(
      Type.Record(Type.String(), Type.Array(Type.Number())),
    ),
    vendorLeaders: Type.Optional(Type.Record(Type.String(), TLeader)),
    vipVendors: Type.Optional(Type.Array(Type.String())),
    instruction: Type.Optional(Type.String()),
    temporary: Type.Optional(Type.Boolean()),
  }),
]);
export type TUser = Static<typeof TUser>;

export const TUserPrivateProfile = Type.Object({
  updated: Type.Optional(TTimestamp),
  subscriptions: Type.Object({
    emailUpdates: Type.Union([Type.Boolean(), Type.Undefined()]),
    emailAlerts: Type.Union([Type.Boolean(), Type.Undefined()]),
    emailPromotions: Type.Union([Type.Boolean(), Type.Undefined()]),
    sms: Type.Union([Type.Boolean(), Type.Undefined()]),
  }),
  fcmRegistered: Type.Union([Type.Boolean(), Type.Undefined()]),
  fcmTokens: Type.Array(Type.String()),
  disabledLeagues: Type.Optional(Type.Array(Type.String())),
  temporaryUserScore: Type.Optional(Type.Number()),
});
export type TUserPrivateProfile = Static<typeof TUserPrivateProfile>;

export const TUserInternalPrivateProfile = Type.Object({
  updated: Type.Optional(TTimestamp),
  notes: Type.Optional(Type.String()),
});
export type TUserInternalPrivateProfile = Static<
  typeof TUserInternalPrivateProfile
>;

export const TUserFollowList = Type.Object({
  userIds: Type.Array(Type.String()),
});
export type TUserFollowList = Static<typeof TUserFollowList>;

export const TCompLogo = Type.Composite([
  TImageMediaEntry,
  Type.Object({description: Type.Optional(Type.String())}),
]);
export type TCompLogo = Static<typeof TCompLogo>;

export const TCompBase = Type.Object({
  featured: Type.Boolean(),
  primaryColor: Type.String({default: '#000000'}),
  secondaryColor: Type.String({default: '#000000'}),
  teamColor: Type.String({default: '#000000'}),
  sport: Type.String(),
  league: Type.String(),
  starts: TTimestamp,
  closes: TTimestamp,
  name: Type.String(),
  shortName: Type.String(),
  roundName: Type.String(),
  logo: TCompLogo,
  sponsor: TImageMediaEntry,
  media: TMediaEntry,
  promo: TMediaEntry,
  feature: Type.Optional(TMediaEntry),
  vendor: Type.String(),
  key: Type.String(),
  strapline: Type.Optional(Type.String()),
});
export type TCompBase = Static<typeof TCompBase>;

export enum CompStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  SUSPENDED = 'SUSPENDED',
  INPLAY = 'INPLAY',
  RESULTED = 'RESULTED',
  VERIFIED = 'VERIFIED',
  AWARDED = 'AWARDED',
  NOTIFIED = 'NOTIFIED',
}
export const TCompStatus = Type.Enum(CompStatus, {default: CompStatus.DRAFT});

export const TLeaderboardLevelDetails = Type.Union([
  Type.String(),
  Type.Object({
    open: Type.Optional(Type.String()),
    inPlay: Type.Optional(Type.String()),
    resulted: Type.Optional(Type.String()),
  }),
]);
export type TLeaderboardLevelDetails = Static<typeof TLeaderboardLevelDetails>;

export const TLeaderboardLevel = Type.Object({
  title: Type.String(),
  details: Type.Optional(TLeaderboardLevelDetails),
});
export type TLeaderboardLevel = Static<typeof TLeaderboardLevel>;

export const TLeaderboardPrize = Type.Composite([
  TLeaderboardLevel,
  Type.Object({
    media: TMediaEntry,
  }),
]);
export type TLeaderboardPrize = Static<typeof TLeaderboardPrize>;

const TLeaderboardBase = Type.Object({
  published: Type.Optional(Type.Boolean()),
  type: TLeaderboardType,
  key: Type.String(),
  shortName: Type.String(),
  name: Type.String(),
  privateCaption: Type.Optional(Type.String()),
  media: TMediaEntry,
  prizes: Type.Array(TLeaderboardPrize),
});
type TLeaderboardBase = Static<typeof TLeaderboardBase>;

export const TLeaderboardSummary = Type.Composite([
  TLeaderboardBase,
  Type.Object({
    id: Type.String(),
  }),
]);
export type TLeaderboardSummary = Static<typeof TLeaderboardSummary>;

export const TLeaderboardSummaryAndLeaders = Type.Composite([
  TLeaderboardSummary,
  TLeaders,
  Type.Object({totalLeadersCount: Type.Number()}),
]);
export type TLeaderboardSummaryAndLeaders = Static<
  typeof TLeaderboardSummaryAndLeaders
>;

export const TLiveScoreBase = Type.Object({
  homeName: Type.String(),
  awayName: Type.String(),
  homeScore: Type.Number(),
  awayScore: Type.Number(),
});
export type TLiveScoreBase = Static<typeof TLiveScoreBase>;

export const TLiveScoreSummary = Type.Composite([
  TLiveScoreBase,
  Type.Object({
    isLive: Type.Boolean(),
    updated: TTimestamp,
  }),
]);
export type TLiveScoreSummary = Static<typeof TLiveScoreSummary>;

export const TCompSummary = Type.Composite([
  TCompBase,
  Type.Object({
    status: TCompStatus,
    entriesCount: Type.Number(),
    openQuestions: Type.Array(Type.String()),
    inPlayQuestions: Type.Array(Type.String()),
    leaderboards: Type.Array(TLeaderboardSummary),
    liveScore: Type.Optional(TLiveScoreSummary),
  }),
]);
export type TCompSummary = Static<typeof TCompSummary>;

export const TCompCompleteView = Type.Object({
  media: TMediaEntry,
});
export type TCompCompleteView = Static<typeof TCompCompleteView>;

export const TSignUpCompleteView = Type.Object({
  media: TMediaEntry,
  description: Type.Optional(Type.String()),
  webDescription: Type.Optional(Type.String()),
});
export type TSignUpCompleteView = Static<typeof TSignUpCompleteView>;

export const TOpenGraphMedia = Type.Composite([
  TImageMediaEntry,
  Type.Object({
    title: Type.Optional(Type.String()),
  }),
]);
export type TOpenGraphMedia = Static<typeof TOpenGraphMedia>;

export const TCompShareDetails = Type.Object({
  openGraph: TOpenGraphMedia,
  shareText: Type.String(),
});
export type TCompShareDetails = Static<typeof TCompShareDetails>;

export const TComp = Type.Composite([
  TCompBase,
  Type.Object({
    created: TTimestamp,
    details: Type.String(),
    completeView: TCompCompleteView,
    signUpCompleteView: TSignUpCompleteView,
    shareDetails: TCompShareDetails,
    leaderboards: Type.Array(Type.String()),
    shortUrl: Type.String(),
    published: Type.Boolean(),
    chat: Type.Boolean(),
    qrCode: Type.Optional(Type.String()),
    template: Type.Boolean({default: false}),
  }),
]);
export type TComp = Static<typeof TComp>;

export const TNotificationSent = Type.Object({
  gameOpen: Type.Optional(TTimestamp),
  gameInPlay: Type.Optional(TTimestamp),
  gameReminder: Type.Optional(TTimestamp),
  gameOutstandingQuestions: Type.Optional(TTimestamp),
  gameVerified: Type.Optional(TTimestamp),
  gamePrize: Type.Optional(TTimestamp),
  gameManual: Type.Optional(Type.Record(Type.String(), TTimestamp)),
});
export type TNotificationSent = Static<typeof TNotificationSent>;

export const TCompNotifications = Type.Object({
  templateGameVerified: Type.Union([Type.String(), Type.Null()]),
  templateGameInPlay: Type.Union([Type.String(), Type.Null()]),
  templateGameEntry: Type.Union([Type.String(), Type.Null()]),
  templateGamePrize: Type.Union([Type.String(), Type.Null()]),
  templateGameOutstandingQuestions: Type.Union([Type.String(), Type.Null()]),
  templateGameOpen: Type.Union([Type.String(), Type.Null()]),
  templateGamePreStart: Type.Union([Type.String(), Type.Null()]),
});
export type TCompNotifications = Static<typeof TCompNotifications>;

export const TEmailNotifications = Type.Composite([
  TCompNotifications,
  Type.Object({
    vendorListId: Type.Union([Type.String(), Type.Null()]),
    categoriesGameVerified: Type.Union([
      Type.Array(Type.String()),
      Type.Null(),
    ]),
    categoriesGameInPlay: Type.Union([Type.Array(Type.String()), Type.Null()]),
    categoriesGameEntry: Type.Union([Type.Array(Type.String()), Type.Null()]),
    categoriesGamePrize: Type.Union([Type.Array(Type.String()), Type.Null()]),
    categoriesGameOutstandingQuestions: Type.Union([
      Type.Array(Type.String()),
      Type.Null(),
    ]),
    categoriesGameOpen: Type.Union([Type.Array(Type.String()), Type.Null()]),
    categoriesGamePreStart: Type.Union([
      Type.Array(Type.String()),
      Type.Null(),
    ]),
  }),
]);
export type TEmailNotifications = Static<typeof TEmailNotifications>;

export const TNativeNotification = Type.Object({
  title: Type.String(),
  body: Type.String(),
});
export type TNativeNotification = Static<typeof TNativeNotification>;

export const TNativeNotifications = Type.Object({
  templateGameOpen: Type.Union([TNativeNotification, Type.Null()]),
  templateGamePreStart: Type.Union([TNativeNotification, Type.Null()]),
  templateGameOutstandingQuestions: Type.Union([
    TNativeNotification,
    Type.Null(),
  ]),
  templateGameInPlay: Type.Union([TNativeNotification, Type.Null()]),
  templateGameVerified: Type.Union([TNativeNotification, Type.Null()]),
  templateGamePrize: Type.Union([TNativeNotification, Type.Null()]),
});
export type TNativeNotifications = Static<typeof TNativeNotifications>;

export enum LiveScoreProvider {
  GENIUS = 'GENIUS',
  FLASHSCORE = 'FLASHSCORE',
  GOOGLE = 'GOOGLE',
  SOFASCORE = 'SOFASCORE',
  RADAR = 'RADAR',
}
export const TLiveScoreProvider = Type.Enum(LiveScoreProvider, {
  default: LiveScoreProvider.GENIUS,
});

export const TLiveScorePlayer = Type.Object({
  playerId: Type.String(),
  score: Type.Number(),
});
export type TLiveScorePlayer = Static<typeof TLiveScorePlayer>;

export const TLiveScoreConfiguration = Type.Object({
  enabled: Type.Boolean(),
  scheduleMatchId: Type.Optional(Type.String()),
  scheduleLeagueId: Type.Optional(Type.String()),
  provider: Type.Optional(TLiveScoreProvider),
  matchId: Type.Optional(Type.String()),
  autoResolve: Type.Boolean({default: false}),
  winnerMargin: Type.Optional(Type.Number()),
  firstToScoreX: Type.Optional(Type.Number()),
  homeAveragePlayers: Type.Optional(Type.Array(TLiveScorePlayer)),
  awayAveragePlayers: Type.Optional(Type.Array(TLiveScorePlayer)),
  homeLastPlayers: Type.Optional(Type.Array(TLiveScorePlayer)),
  awayLastPlayers: Type.Optional(Type.Array(TLiveScorePlayer)),
});
export type TLiveScoreConfiguration = Static<typeof TLiveScoreConfiguration>;

export const TLiveScorePrivate = Type.Composite([
  TLiveScoreBase,
  Type.Object({
    finished: Type.Boolean(),
    homePlayers: Type.Optional(Type.Array(TLiveScorePlayer)),
    awayPlayers: Type.Optional(Type.Array(TLiveScorePlayer)),
  }),
]);
export type TLiveScorePrivate = Static<typeof TLiveScorePrivate>;

export const TCompPrivate = Type.Object({
  notificationsSent: Type.Optional(TNotificationSent),
  emailNotifications: TEmailNotifications,
  smsNotifications: TCompNotifications,
  nativeNotifications: TNativeNotifications,
  autoInPlay: Type.Optional(Type.Boolean()),
  prizeCode: Type.Optional(Type.String()),
  liveScoreConfiguration: Type.Optional(TLiveScoreConfiguration),
  liveScore: Type.Optional(TLiveScorePrivate),
});
export type TCompPrivate = Static<typeof TCompPrivate>;

export enum QuestionStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  OPEN_CHANGEABLE = 'OPEN_CHANGEABLE',
  INPLAY = 'INPLAY',
  RESOLVED = 'RESOLVED',
}
export const TQuestionStatus = Type.Enum(QuestionStatus, {
  default: QuestionStatus.DRAFT,
});

export const TJunctionCompetitionsQuestions = Type.Object({
  competitionId: Type.String(),
  questionId: Type.String(),
  questionIndex: Type.Number(),
  status: TQuestionStatus,
});
export type TJunctionCompetitionsQuestions = Static<
  typeof TJunctionCompetitionsQuestions
>;

export const TCompAnswer = Type.Object({
  label: Type.String(),
  autoResolveId: Type.Optional(Type.String()),
});
export type TCompAnswer = Static<typeof TCompAnswer>;

export const TCompQuestionAuthor = Type.Optional(TImageMediaEntry);
export type TCompQuestionAuthor = Static<typeof TCompQuestionAuthor>;

export const TCompQuestionLink = Type.Composite([
  TImageMediaEntry,
  TVideoMediaEntry,
  Type.Object({
    url: Type.String(),
    text: Type.String(),
    publisher: Type.Optional(TImageMediaEntry),
  }),
]);
export type TCompQuestionLink = Static<typeof TCompQuestionLink>;

export enum CompQuestionPublishingType {
  WINNER_POLL = 'winner_poll',
  WINNING_MARGIN = 'winning_margin',
  PLAYER_OF_MATCH = 'player_of_match',
}
export const TCompQuestionPublishingType = Type.Enum(
  CompQuestionPublishingType,
  {
    default: CompQuestionPublishingType.WINNER_POLL,
  },
);

export enum CompQuestionTemplateType {
  DEFAULT = 'default',
  WINNER = 'winner',
  WINNER_WITH_DRAW = 'winner_with_draw',
  WINNER_MARGIN = 'winner_margin',
  WINNER_MARGIN_WITH_DRAW = 'winner_margin_with_draw',
  FIRST_TO_SCORE = 'first_to_score',
  FIRST_TO_SCORE_X = 'first_to_score_x',
  AVERAGE_HIGH_SCORER = 'average_high_scorer', // High scorers based on season average
  LAST_HIGH_SCORER = 'last_high_scorer', // High scorers based on last game
  HOME_ABOVE_AVERAGE_SCORERS = 'home_above_average_scorers', // Above season average scorers
  AWAY_ABOVE_AVERAGE_SCORERS = 'away_above_average_scorers', // Above season average scorers
}
export const TCompQuestionTemplateType = Type.Enum(CompQuestionTemplateType, {
  default: CompQuestionTemplateType.DEFAULT,
});

export const TCompQuestion = Type.Object({
  type: Type.String(),
  name: Type.String(),
  color: Type.String(),
  hint: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  question: Type.String(),
  answers: Type.Array(TCompAnswer),
  media: TMediaEntry,
  author: TCompQuestionAuthor,
  opens: Type.Optional(TTimestamp),
  starts: Type.Optional(TTimestamp),
  link: Type.Union([TCompQuestionLink, Type.Null()]),
  status: TQuestionStatus,
  resolved: Type.Union([Type.Number(), Type.Null(), Type.Array(Type.Number())]),
  publishingType: Type.Optional(TCompQuestionPublishingType),
  templateType: TCompQuestionTemplateType,
});
export type TCompQuestion = Static<typeof TCompQuestion>;

export enum CompQuestionType {
  MULTIANSWER = 'multianswer',
  MULTICHOICE = 'multichoice',
  POLL = 'poll',
  TRIVIA = 'trivia',
  SURVEY = 'survey',
  SURVEY0 = 'survey0pts',
}
export const TCompQuestionType = Type.Enum(CompQuestionType, {
  default: CompQuestionType.MULTIANSWER,
});

export const QUESTION_MISSED_ANSWER = -1;

export const TCompEntryAnswers = Type.Intersect([
  Type.Record(Type.String(), Type.Number()),
  Type.Object({length: Type.Optional(Type.Never())}),
]);
export type TCompEntryAnswers = Static<typeof TCompEntryAnswers>;

export const TCompEntryLikes = Type.Intersect([
  Type.Record(Type.String(), Type.Boolean()),
  Type.Object({length: Type.Optional(Type.Never())}),
]);
export type TCompEntryLikes = Static<typeof TCompEntryLikes>;

export const TCompEntry = Type.Object({
  created: Type.Optional(TTimestamp),
  updated: Type.Optional(TTimestamp),
  userId: Type.String(),
  compId: Type.String(),
  answers: TCompEntryAnswers,
  likes: TCompEntryLikes,
  attId: Type.Optional(Type.String()),
});
export type TCompEntry = Static<typeof TCompEntry>;

export const TCompAnswerBreakdown = Type.Record(Type.String(), Type.Number());
export type TCompAnswerBreakdown = Static<typeof TCompAnswerBreakdown>;

export const TCompResolvedQuestion = Type.Object({
  id: Type.String(),
  resolved: Type.Union([Type.Number(), Type.Array(Type.Number())]),
});
export type TCompResolvedQuestion = Static<typeof TCompResolvedQuestion>;

export const TCompCounts = Type.Object({
  numQuestions: Type.Number(),
  entriesCount: Type.Number(),
  answersBreakdown: Type.Record(Type.String(), TCompAnswerBreakdown),
  likesBreakdown: Type.Record(Type.String(), Type.Number()),
  resolvedQuestions: Type.Array(TCompResolvedQuestion),
});
export type TCompCounts = Static<typeof TCompCounts>;

export const TCompStatusUpdated = Type.Object({
  status: TCompStatus,
  updated: TTimestamp,
});
export type TCompStatusUpdated = Static<typeof TCompStatusUpdated>;

export const TTempEntry = Type.Object({
  answers: TCompEntryAnswers,
});
export type TTempEntry = Static<typeof TTempEntry>;

export const TLeaderboard = Type.Composite([
  TLeaderboardBase,
  Type.Object({
    vendor: Type.String(),
    details: Type.String(),
    comps: Type.Array(Type.String()),
    levels: Type.Array(TLeaderboardLevel),
    publishUserLeaders: Type.Boolean(),
  }),
]);
export type TLeaderboard = Static<typeof TLeaderboard>;

export const TMessage = Type.Object({
  created: TTimestamp,
  expireAt: Type.Optional(TTimestamp),
  userId: Type.String(),
  sender: Type.String(),
  message: Type.String(),
  iconUrl: Type.Union([Type.String(), Type.Null()]),
});
export type TMessage = Static<typeof TMessage>;

export enum ChatMessageType {
  UNKNOWN = -1,
  DEFAULT = 0,
  SYSTEM_DEFAULT = 1,
  SYSTEM_NEW_COMP = 2,
  SYSTEM_NEW_QUESTION = 3,
  SYSTEM_RESOLVED_QUESTION = 4,
  SYSTEM_VERIFIED_COMP = 5,
  SYSTEM_NEW_ENTRY = 6,
  SYSTEM_PRE_START_COMP = 7,
  SYSTEM_LEADERBOARD = 8,
  SYSTEM_LEADERBOARD_UPDATE = 9,
}
export const TChatMessageType = Type.Enum(ChatMessageType, {
  default: ChatMessageType.UNKNOWN,
});

export const TChatMessagePayload = Type.Object({
  compId: Type.Optional(Type.String()),
  userIds: Type.Optional(Type.Array(Type.String())),
  questionId: Type.Optional(Type.String()),
  imageUrl: Type.Optional(Type.String()),
});
export type TChatMessagePayload = Static<typeof TChatMessagePayload>;

export const TChatMessage = Type.Composite([
  TMessage,
  Type.Object({
    isVip: Type.Boolean(),
    type: TChatMessageType,
    version: Type.Optional(Type.Number()),
    payload: Type.Optional(TChatMessagePayload),
    summary: Type.Optional(Type.String()),
  }),
]);
export type TChatMessage = Static<typeof TChatMessage>;

export const TInboxMessage = Type.Composite([
  TMessage,
  Type.Object({
    opened: Type.Optional(TTimestamp),
    open: Type.Boolean(),
    nativeTitle: Type.Optional(Type.String()),
    nativeMessage: Type.Optional(Type.String()),
    nativeBadge: Type.Optional(Type.Boolean()),
    title: Type.String(),
  }),
]);
export type TInboxMessage = Static<typeof TInboxMessage>;

export const TAdminUser = Type.Object({
  email: Type.String(),
  role: Type.String(),
});
export type TAdminUser = Static<typeof TAdminUser>;

export enum GroupType {
  DEFAULT = 'DEFAULT',
  CHALLENGE = 'CHALLENGE',
}
export const TGroupType = Type.Enum(GroupType, {
  default: GroupType.DEFAULT,
});

const TBaseGroup = Type.Object({
  type: TGroupType,
  expired: Type.Boolean(),
  name: Type.String(),
  media: Type.Optional(TImageMediaEntry),
  description: Type.Optional(Type.String()),
  vendors: Type.Array(Type.String()),
  admins: Type.Array(Type.String()),
  users: Type.Array(Type.String()),
  created: TTimestamp,
  createdBy: Type.String(),
  updated: Type.Optional(TTimestamp),
  updatedBy: Type.Optional(Type.String()),
});
type TBaseGroup = Static<typeof TBaseGroup>;

export const TDefaultGroup = Type.Composite([
  TBaseGroup,
  Type.Object({
    type: Type.Literal(GroupType.DEFAULT),
  }),
]);
export type TDefaultGroup = Static<typeof TDefaultGroup>;

export const TChallengeGroup = Type.Composite([
  TBaseGroup,
  Type.Object({
    type: Type.Literal(GroupType.CHALLENGE),
    queryKey: Type.String(),
    competitionId: Type.String(),
    result: Type.Optional(Type.Array(Type.Number())),
    resulted: Type.Optional(TTimestamp),
  }),
]);
export type TChallengeGroup = Static<typeof TChallengeGroup>;

// You cannot use Value.Cast on a Union type, the easiest way to enforce this is to not create the TypeBox definition
export type TGroup = TDefaultGroup | TChallengeGroup;

export const TGroupCounts = Type.Object({
  messageCount: Type.Number(),
});
export type TGroupCounts = Static<typeof TGroupCounts>;

export const TGroupPrivate = Type.Object({
  key: Type.String(),
});
export type TGroupPrivate = Static<typeof TGroupPrivate>;

export const TOpenGraph = Type.Object({
  url: Type.String(),
  domain: Type.String(),
  title: Type.Optional(Type.String()),
  description: Type.Optional(Type.String()),
  image: Type.Optional(Type.String()),
  imageBase64: Type.Optional(Type.String()),
  siteName: Type.Optional(Type.String()),
  icon: Type.Optional(Type.String()),
  socialEmbed: Type.Optional(Type.String()),
  socialEmbedError: Type.Optional(Type.String()),
  captions: Type.Optional(Type.Array(Type.String())),
});
export type TOpenGraph = Static<typeof TOpenGraph>;

export const TPrediction = Type.Object({
  model: Type.String(),
  maxOutputTokens: Type.Number(),
  temperature: Type.Number(),
  topP: Type.Number(),
  topK: Type.Number(),
  executionTime: Type.Number(),
  prompt: Type.String(),
  promptTokens: Type.Number(),
  prediction: Type.String(),
  predictionTokens: Type.Number(),
});
export type TPrediction = Static<typeof TPrediction>;

export const TQuestionPrediction = Type.Object({
  question: TCompQuestion,
  prediction: TPrediction,
});
export type TQuestionPrediction = Static<typeof TQuestionPrediction>;

export const TPublishedCompAnswer = Type.Composite([
  TCompAnswer,
  Type.Object({
    count: Type.Number(),
    percentage: Type.Number(),
  }),
]);

export type TPublishedCompAnswer = Static<typeof TPublishedCompAnswer>;

export const TPublishedCompQuestion = Type.Composite([
  Type.Omit(TCompQuestion, [
    'author',
    'answers',
    'resolved',
    'color',
    'hint',
    'link',
    'media',
    'starts',
    'opens',
    'templateType',
  ]),
  Type.Object({
    answers: Type.Array(TPublishedCompAnswer),
  }),
]);
export type TPublishedCompQuestion = Static<typeof TPublishedCompQuestion>;

export const TPublishedComp = Type.Composite([
  Type.Omit(TCompBase, [
    'promo',
    'media',
    'logo',
    'sponsor',
    'closes',
    'featured',
    'starts',
    'strapline',
    'primaryColor',
    'secondaryColor',
    'teamColor',
    'shortName',
  ]),
  Type.Object({
    shortUrl: Type.String(),
    published: Type.Boolean(),
    qrCode: Type.Optional(Type.String()),
    status: TCompStatus,
    entriesCount: Type.Number(),
    description: Type.String(),
    prizeTitle: Type.String(),
    prizeImageUrl: Type.String(),
    questions: Type.Array(TPublishedCompQuestion),
  }),
]);
export type TPublishedComp = Static<typeof TPublishedComp>;
