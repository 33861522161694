import {
  TImageMediaEntry,
  TMediaEntry,
  TVideoMediaEntry,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getIsImage, getIsVideo} from '@chancer/common/lib/utils/MediaUtils';
import React from 'react';

import './MediaView.scss';

type HorizontalAlign = 'left' | 'center' | 'right';
type VerticalAlign = 'top' | 'center' | 'bottom';

interface IProps {
  style?: React.CSSProperties | undefined;
  className?: string;
  media: TMediaEntry | TImageMediaEntry | TVideoMediaEntry | null;
  horizontalAlign?: HorizontalAlign;
  verticalAlign?: VerticalAlign;
}

export const MediaView: React.FC<IProps> = (props) => {
  const horizontalAlign: HorizontalAlign = props.horizontalAlign ?? 'center';
  const verticalAlign: VerticalAlign = props.verticalAlign ?? 'top';

  if (props.media === null) {
    return <div style={props.style} className={props.className} />;
  }

  if (getIsImage(props.media)) {
    return (
      <div style={props.style} className={props.className}>
        <img
          alt={props.media.caption ? props.media.caption : ''}
          className="media__background--image"
          style={{objectPosition: `${horizontalAlign} ${verticalAlign}`}}
          src={props.media.image.url}
        />
      </div>
    );
  } else if (getIsVideo(props.media)) {
    return (
      <div style={props.style} className={props.className}>
        <video
          id="myVideo"
          className="media__background--video"
          style={{objectPosition: `${horizontalAlign} ${verticalAlign}`}}
          playsInline
          loop
          autoPlay
          muted>
          <source src={props.media.video.url} type="video/mp4" />
        </video>
      </div>
    );
  } else {
    return null;
  }
};
