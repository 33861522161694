import React, {useContext, useEffect, useRef} from 'react';
import log from '@chancer/common/lib/utils/Log';
import {IntersectionRootContext} from './IntersectionRoot';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  once?: boolean;
  onVisibilityChange: (entry: IntersectionObserverEntry) => any;
}

export const IntersectionElement: React.FC<IProps> = (props) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const {once, onVisibilityChange, ...divProps} = props;
  const {observe, unobserve} = useContext(IntersectionRootContext);

  useEffect(() => {
    if (!nodeRef.current) {
      log.warning('No node ref set');
      return;
    }
    const node = nodeRef.current;
    observe(node, (entry: IntersectionObserverEntry) => {
      onVisibilityChange(entry);
      if (once && entry.isIntersecting && node) {
        unobserve(node);
      }
    });
    return () => unobserve(node);
  }, [observe, unobserve, once, onVisibilityChange]);

  return (
    <div {...divProps} ref={nodeRef}>
      {props.children}
    </div>
  );
};
