import React, {useCallback} from 'react';
import {View, StyleSheet, Text, Pressable} from 'react-native';
import {Avatar} from '../Avatar/Avatar';
import {FindMeButton} from '../Button/FindMeButton';
import {LabelBadge} from '../Status/LabelBadge';
import {UserScores} from '../Status/UserScores';
import {
  COLOR_DARK,
  COLOR_GREY_6,
  FONT_COPY,
} from '../Styles/DesignSystem-chancer';
import {Score} from './Score';
import {MultiGameScore} from './MultiGameScore';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  userImageUrl: string | undefined;
  userScore: number;
  userPosition: number;
  totalPlayers: number;
  correctCount: number;
  incorrectCount: number;
  pendingCount: number;
  isMultiGame?: boolean;
  multiGameStatusLabel?: string;
  useFindMeState?: boolean;
  onUserPress?: (isFindMe: boolean) => void;
}

export const UserScoresHeader: React.FC<IProps> = (props) => {
  const {onUserPress} = props;
  const isMultiGame = props.isMultiGame ?? false;

  const _onFindMePress = useCallback(() => {
    if (onUserPress) {
      onUserPress(true);
    }
  }, [onUserPress]);

  const _onAvatarPress = useCallback(() => {
    if (onUserPress) {
      onUserPress(false);
    }
  }, [onUserPress]);
  return (
    <View style={[styles.container, props.style]}>
      {props.useFindMeState ? (
        <FindMeButton
          style={styles.findMe}
          imageUrl={props.userImageUrl}
          onPress={_onFindMePress}
        />
      ) : (
        <Pressable onPress={_onAvatarPress}>
          <Avatar
            style={styles.avatar}
            imageUrl={props.userImageUrl}
            size={36}
          />
        </Pressable>
      )}
      <View style={styles.detailsContainer}>
        {isMultiGame ? (
          <Text
            style={styles.multiGameStatus}
            numberOfLines={1}
            ellipsizeMode="tail">
            {props.multiGameStatusLabel ?? 'Your Ranking'}
          </Text>
        ) : (
          <View style={styles.statusContainer}>
            <>
              <LabelBadge style={styles.statusBadge} label="LIVE" />
              <UserScores
                correctCount={props.correctCount}
                incorrectCount={props.incorrectCount}
                pendingCount={props.pendingCount}
              />
            </>
          </View>
        )}
        <Text style={styles.playersCount}>
          {props.userPosition} of {props.totalPlayers}
        </Text>
      </View>
      {isMultiGame ? (
        <MultiGameScore size="large" score={props.userScore} />
      ) : (
        <Score score={props.userScore} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingHorizontal: 16,
    paddingTop: 24,
    backgroundColor: COLOR_DARK,
    pointerEvents: 'box-none',
  },
  findMe: {
    marginLeft: 0,
  },
  avatar: {
    marginLeft: 16,
  },
  detailsContainer: {
    pointerEvents: 'none',
    flexGrow: 1,
    marginLeft: 16,
  },
  statusContainer: {
    pointerEvents: 'none',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusBadge: {marginRight: 8, marginTop: 2},
  playersCount: {
    marginTop: 2,
    fontFamily: FONT_COPY,
    fontSize: 14,
    color: COLOR_GREY_6,
  },
  multiGameStatus: {
    fontFamily: FONT_COPY,
    fontSize: 14,
    color: COLOR_GREY_6,
    textTransform: 'capitalize',
  },
});
