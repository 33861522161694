import {IAnalyticsPayload} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {IAction, ActionType} from '../Actions';

export const trackException = (
  description?: string,
  fatal?: boolean,
): IAction<IAnalyticsPayload> => ({
  type: ActionType.SEND_ANALYTICS,
  payload: createEventPayload('exception', {
    description: description,
    fatal: fatal,
  }),
});

export const trackSelectContent = (
  content_id: string,
  content_type: 'button' | 'link' = 'button',
): IAction<IAnalyticsPayload> => ({
  type: ActionType.SEND_ANALYTICS,
  payload: createEventPayload('select_content', {content_id, content_type}),
});

export const trackScreenView = (screenName: string) => ({
  type: ActionType.SEND_ANALYTICS,
  payload: createEventPayload('screen_view', {
    screen_name: screenName,
  }),
});

export const trackCustomEvent = (
  name: string,
  props?: {[key: string]: any},
) => ({
  type: ActionType.SEND_ANALYTICS,
  payload: createEventPayload(name, props),
});

export const trackShare = (
  method?: string,
  contentType?: string,
  contentId?: string,
) => ({
  type: ActionType.SEND_ANALYTICS,
  payload: createEventPayload('share', {
    method: method,
    content_type: contentType,
    content_id: contentId,
  }),
});

const createEventPayload = (
  name: string,
  eventParams?: {[key: string]: any},
): IAnalyticsPayload => ({name, eventParams});
