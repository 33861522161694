import React, {ReactEventHandler, useEffect, useRef, useState} from 'react';
import log from '@chancer/common/lib/utils/Log';
import {TMediaEntry} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  getIsImage,
  getIsSocialEmbed,
  getIsVideo,
} from '@chancer/common/lib/utils/MediaUtils';

import './ScreenBackground.scss';
import {SocialEmbed} from './SocialEmbed';
import {IColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {COLOR_BACKGROUND} from '@chancer/components/lib/Styles/DesignSystem-chancer';

interface IProps {
  active: boolean;
  preload: boolean;
  mute: boolean;
  media: TMediaEntry | null;
  safeAreaTopPadding: number;
  colorConfig: IColorConfiguration;
  onMute: () => void;
}

const animateVolume = (
  ref: HTMLVideoElement | null,
  start: number,
  end: number,
  duration: number,
) => {
  if (start === end) {
    return;
  }
  var range = end - start;
  var current = start;
  var stepTime = 1000 / 60; // 60 fps
  var increment = range / (duration / stepTime);
  var timer = window.setInterval(() => {
    current += increment;
    if (current >= end) {
      current = end;
    }
    if (ref) {
      ref.volume = current;
    } else {
      clearInterval(timer);
    }
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
};

export const ScreenBackground: React.FC<IProps> = (props) => {
  const {active, onMute} = props;
  const [retryCount, setRetryCount] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    async function tryPlay() {
      if (videoRef.current !== null) {
        if (active) {
          try {
            animateVolume(videoRef.current, 0, 1, 2000);
            await videoRef.current.play();
          } catch (err) {
            log.warning('Error playing video:', err);
            if (err instanceof Error && err.name === 'NotAllowedError') {
              if (videoRef.current.muted === false) {
                log.debug('Trying to play muted video');
                videoRef.current.muted = true;
                await videoRef.current.play();
                onMute();
              }
            }
          }
        } else {
          videoRef.current.pause();
        }
      }
    }
    tryPlay();
  }, [active, retryCount, onMute]);

  const _onVideoError: ReactEventHandler<HTMLVideoElement> = (event) => {
    log.warning('Video error event', event);
    if (retryCount < 2) {
      setRetryCount(retryCount + 1);
    }
  };

  if (props.media === null) {
    return <div className="screen_container__background" />;
  }

  if (getIsImage(props.media)) {
    videoRef.current = null;
    return (
      <div
        className="screen_container__background"
        style={
          props.active
            ? {animationName: 'kenburns1', animationDuration: '45s'}
            : undefined
        }>
        {props.preload && (
          <>
            <div className="screen_container__background-gradient" />
            <img
              alt={props.media.caption ? props.media.caption : ''}
              className="screen_container__background--image"
              src={props.media.image.url}
            />
          </>
        )}
      </div>
    );
  } else if (getIsVideo(props.media)) {
    return (
      <div className="screen_container__background">
        {props.preload && (
          <>
            <div className="screen_container__background-gradient" />
            <video
              id="myVideo"
              key={`video${retryCount}`}
              ref={videoRef}
              className="screen_container__background--video"
              playsInline
              loop
              preload="auto"
              muted={props.mute}
              onError={_onVideoError}>
              <source src={props.media.video.url} type="video/mp4" />
            </video>
          </>
        )}
      </div>
    );
  } else if (getIsSocialEmbed(props.media)) {
    return (
      <div
        className="screen_container__embed-background"
        style={{
          paddingTop: props.safeAreaTopPadding + 48,
          backgroundImage: `linear-gradient(${COLOR_BACKGROUND} 40%, ${props.colorConfig.questionBackgroundGradient})`,
        }}>
        {props.active && (
          <>
            <div className="screen_container__background-gradient" />
            <SocialEmbed embed={props.media.social.embed} />
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
};
