export const createFallbackUserIcon = (id: string, name: string) => {
  const l = Math.min(3, id.length - 1);
  let count = 0;
  for (let i = 0; i < l; i++) {
    count += id.charCodeAt(i);
  }
  const colorIndex = count % 4;
  const colors = ['F6B700', '33BBED', '72B627', 'FD416A'];
  const color = colors[colorIndex];
  return `https://ui-avatars.com/api/?background=${color}&color=FFFFFF&length=1&size=64&format=png&font-size=0.55&bold=true&name=${encodeURIComponent(
    name,
  )}`;
};
