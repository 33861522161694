import {IAppState} from '../../state/AppState';
import {createSelector} from 'reselect';
import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';

export const getConfig = (state: IAppState) => {
  if (state.config.config === null) {
    throw new Error('App config not set');
  }
  return state.config.config;
};

export const getRemoteConfig = (state: IAppState) => {
  if (state.config.remoteConfig === null) {
    throw new Error('Remote config not set');
  }
  return state.config.remoteConfig;
};

export const getIsMobile = (state: IAppState) => {
  let isMobile = false;
  try {
    isMobile = getConfig(state).isMobile;
  } catch {}
  return isMobile;
};

export const getAppPlatform = (state: IAppState) => {
  let platform = AppPlatform.UNKNOWN;
  try {
    platform = getConfig(state).platform;
  } catch {
    if (getIsMobile(state)) {
      // Default to ios if mobile and not platform value is sent through
      platform = AppPlatform.IOS;
    } else {
      platform = AppPlatform.WEB;
    }
  }
  return platform;
};

export const getSafeAreaTopPadding = (state: IAppState) => {
  let padding = 0;
  try {
    padding = getConfig(state).topPadding;
  } catch {}
  return padding;
};

const isFacebookApp = () => {
  var isFb = false;
  try {
    const ua =
      navigator.userAgent ||
      navigator.vendor ||
      ((window as any).opera as string) ||
      '';
    isFb = ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
  } catch {}
  return isFb;
};

export const getSafeAreaBottomPadding = createSelector(
  getIsMobile,
  (isMobile) => {
    if (isMobile) {
      return 5;
    } else {
      return isFacebookApp() ? 50 : 0;
    }
  },
);
