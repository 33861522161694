import {Value} from '@sinclair/typebox/value';
import {merge, of} from 'rxjs';
import {ajax} from 'rxjs/ajax';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';

import {
  LeaderboardType,
  TLeaderboardSummaryAndLeaders,
} from '../interfaces/firestore/FirestoreInterfaces';
import log from './Log';
import {assertType} from './TypeUtils';

const summaries = new Map<string, TLeaderboardSummaryAndLeaders>();

export const getLeaderboardSummaryAndLeadersByIdStream =
  (gameUrl: string) =>
  (leaderboardId: string, invalidationKey?: string, length?: number) => {
    const requestStream = of(leaderboardId).pipe(
      delay(90),
      mergeMap((leaderboardId) =>
        ajax({
          url: `${gameUrl}/get_leaderboard_summary_and_leaders?id=${leaderboardId}${
            invalidationKey !== undefined ? `&ik=${invalidationKey}` : ''
          }${length !== undefined ? `&length=${length}` : ''}`,
          method: 'GET',
        }).pipe(
          map((response) =>
            Value.Cast(TLeaderboardSummaryAndLeaders, response.response),
          ),
          tap((summary) =>
            summaries.set(
              createCacheKey(leaderboardId, invalidationKey, length),
              summary,
            ),
          ),
        ),
      ),
      catchError((err) =>
        of(err).pipe(
          // tap(() => log.warning(err)),
          map(() =>
            assertType<TLeaderboardSummaryAndLeaders>({
              name: 'Unknown',
              media: {},
              id: '',
              type: LeaderboardType.INGAME,
              key: '',
              shortName: '',
              prizes: [],
              leaders: [],
              totalLeadersCount: 0,
            }),
          ),
        ),
      ),
    );
    const cacheStream = of(
      summaries.get(createCacheKey(leaderboardId, invalidationKey, length)),
    ).pipe(
      filter(
        (summary): summary is TLeaderboardSummaryAndLeaders =>
          summary !== undefined,
      ),
      delay(50),
      tap(() => log.debug('Updating with stored summaries', leaderboardId)),
    );

    return merge(cacheStream, requestStream).pipe(take(1));
  };

const createCacheKey = (
  leaderboardId: string,
  invalidationKey?: string,
  length?: number,
) => `${leaderboardId}-${invalidationKey ?? ''}-${length ?? ''}`;
