import {combineEpics} from 'redux-observable';
import {
  startupEpic,
  sendLoginEpic,
  sendQuestionAnsweredEpic,
  sendEnterCompetitionEpic,
  sendUnfollowEpic,
  sendFollowEpic,
  sendOverlayAddEpic,
  sendAnalyticsEpic,
  sendNavigateEpic,
  sendCurrentScreenEpic,
  sendEditProfileEpic,
  sendMuteEpic,
  sendQuestionLikedEpic,
  sendChangeAnswerEpic,
} from './messages/AppMessageEpics';
import {
  setInitialViewStateEpic,
  getUsersFollowingInCompAnswersEpic,
} from './startup/StartupEpics';
import {userUpdatedEpic} from './user/UserEpics';

export const rootEpic = combineEpics(
  startupEpic,
  userUpdatedEpic,
  sendNavigateEpic,
  sendLoginEpic,
  sendQuestionAnsweredEpic,
  sendQuestionLikedEpic,
  sendEnterCompetitionEpic,
  sendChangeAnswerEpic,
  sendFollowEpic,
  sendUnfollowEpic,
  sendOverlayAddEpic,
  sendAnalyticsEpic,
  sendCurrentScreenEpic,
  sendEditProfileEpic,
  sendMuteEpic,
  setInitialViewStateEpic,
  getUsersFollowingInCompAnswersEpic,
);
