import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Image, StyleSheet, Text, View} from 'react-native';
import {Point} from 'react-native-svg/lib/typescript/elements/Shape';

import {
  COLOR_DARK_2,
  COLOR_GREY_2,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';
import {CompositedImageStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedImageStyle;
  imageUrl: string;
  maxSize?: number;
}

const IMAGE_SIZE_CACHE = new Map<string, Point>();

export const ChatImage: React.FC<IProps> = (props) => {
  const {imageUrl, maxSize} = props;
  const [dimensions, setDimensions] = useState<Point>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    if (IMAGE_SIZE_CACHE.has(imageUrl)) {
      setDimensions(IMAGE_SIZE_CACHE.get(imageUrl));
      return;
    }
    Image.getSize(
      imageUrl,
      (width, height) => {
        if (maxSize) {
          if (height >= width && height > maxSize) {
            width = (width / height) * maxSize;
            height = maxSize;
          } else if (width > height && width > maxSize) {
            height = (height / width) * maxSize;
            width = maxSize;
          }
        }
        IMAGE_SIZE_CACHE.set(imageUrl, {x: width, y: height});
        setDimensions({x: width, y: height});
      },
      (_error) => {
        setError(true);
      },
    );
  }, [imageUrl, maxSize]);

  return dimensions ? (
    <View style={[{width: dimensions.x, height: dimensions.y}, props.style]}>
      <Image
        source={{uri: imageUrl}}
        style={{width: dimensions.x, height: dimensions.y}}
      />
    </View>
  ) : (
    <View style={[styles.loading, props.style]}>
      {error ? (
        <Text style={styles.failedCopy}>Failed to load Image</Text>
      ) : (
        <ActivityIndicator size="small" color={COLOR_GREY_2} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    backgroundColor: COLOR_DARK_2,
  },
  failedCopy: {
    color: COLOR_GREY_2,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    textAlign: 'center',
  },
});
