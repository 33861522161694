import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

import {COLOR_LIGHT, FONT_COPY} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  copy: string;
  imageUrl?: string;
}

export const MediaCard: React.FC<IProps> = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.copyContainer}>
        <Text style={styles.copy} numberOfLines={3} ellipsizeMode="tail">
          {props.copy}
        </Text>
      </View>
      {props.imageUrl ? (
        <Image
          style={styles.image}
          resizeMode="cover"
          source={{uri: props.imageUrl}}
        />
      ) : undefined}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  copyContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  copy: {
    fontFamily: FONT_COPY,
    fontSize: 16,
    color: COLOR_LIGHT,
    overflow: 'hidden',
  },
  image: {
    width: 64,
    height: 64,
    overflow: 'hidden',
    marginLeft: 16,
  },
});
