import React, {useEffect, useState} from 'react';
import IntersectionObserverWrapper from './IntersectionObserverWrapper';

interface IIntersectionRootContext {
  observe: (
    node: Element,
    onChange: (entry: IntersectionObserverEntry) => any,
  ) => any;
  unobserve: (node: Element) => any;
}

export const IntersectionRootContext: React.Context<IIntersectionRootContext> =
  React.createContext({
    observe: null as any,
    unobserve: null as any,
  });

interface IProps {
  margin?: string;
  threshold?: number[];
  children: React.ReactNode;
}

export const IntersectionRoot: React.FC<IProps> = (props) => {
  const {margin, threshold} = props;
  const [intersectionObserver, setIntersectionObserver] =
    useState<IntersectionObserverWrapper | null>(null);

  const [contextValue, setContextValue] =
    useState<IIntersectionRootContext | null>(null);

  useEffect(() => {
    if (!intersectionObserver) {
      const observer = new IntersectionObserverWrapper({
        root: null,
        rootMargin: margin,
        threshold: threshold,
      });
      const context = {
        observe: (
          child: Element,
          onChange: (entry: IntersectionObserverEntry) => any,
        ) => observer && observer.observe(child, onChange),
        unobserve: (child: Element) => observer && observer.unobserve(child),
      };
      setIntersectionObserver(intersectionObserver);
      setContextValue(context);
      return () => observer.disconnect();
    }
  }, [intersectionObserver, margin, threshold]);

  return contextValue ? (
    <IntersectionRootContext.Provider value={contextValue}>
      {props.children}
    </IntersectionRootContext.Provider>
  ) : null;
};
