/**
 * Formats a number with a specified number of decimal places.
 * @param num The number to format.
 * @param decimalPlaces The number of decimal places to include in the formatted number. Defaults to 0.
 * @returns A string representation of the formatted number.
 */
export const formatNumber = (num: number, decimalPlaces: number = 0) =>
  num.toLocaleString('en-us', {
    maximumFractionDigits: decimalPlaces,
  });

export const roundToTwo = (num: number) => {
  return +(Math.round(num + ('e+2' as any)) + 'e-2');
};
