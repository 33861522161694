import {TFirebaseChatMessage} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import React, {useEffect, useMemo, useState} from 'react';
import {UseSpringProps} from 'react-spring';

type OverwriteKeys<A, B> = {[K in keyof A]: K extends keyof B ? B[K] : A[K]};

export const useScrollChatContent = (
  scrollingRef: React.MutableRefObject<HTMLDivElement | null>,
  userId: string | null,
  windowHeight: number,
  competitionChat: TFirebaseChatMessage[],
  subView: string | undefined,
  setScrollProps: (
    ds: Partial<
      UseSpringProps<
        OverwriteKeys<
          {
            reset: unknown;
            immediate: unknown;
            scroll: unknown;
            from: unknown;
          },
          React.CSSProperties
        >
      >
    >,
  ) => void,
) => {
  const [previousSubView, setPreviousSubView] = useState<string | null>(null);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);

  useEffect(() => {
    const currentChatMessage =
      competitionChat.length > 0 ? competitionChat[0] : null;
    let forceToBottom = false;

    if (
      userId &&
      currentChatMessage &&
      currentChatMessage.id !== lastMessageId &&
      currentChatMessage.userId === userId
    ) {
      forceToBottom = true;
    }
    if (scrollingRef.current) {
      if (previousSubView !== subView) {
        scrollingRef.current.scrollTop =
          scrollingRef.current.scrollHeight -
          scrollingRef.current.clientHeight -
          200;
      }
      // debounce = window.setTimeout(() => {
      if (scrollingRef.current) {
        if (
          scrollingRef.current.scrollTop >=
            scrollingRef.current.scrollHeight -
              scrollingRef.current.clientHeight -
              500 ||
          forceToBottom
        ) {
          setScrollProps({
            reset: true,
            scroll:
              scrollingRef.current.scrollHeight -
              scrollingRef.current.clientHeight,
            from: {
              scroll: scrollingRef.current.scrollTop,
            },
          } as any);
        }
      }
    }

    if (lastMessageId !== currentChatMessage?.id ?? null) {
      setLastMessageId(currentChatMessage?.id ?? null);
    }

    if (subView !== previousSubView) {
      setPreviousSubView(subView ?? null);
    }
    return () => {};
  }, [
    subView,
    userId,
    scrollingRef,
    previousSubView,
    competitionChat,
    windowHeight,
    lastMessageId,
    setScrollProps,
  ]);
};

export interface IDetailsTab {
  id: 'chat' | 'latest' | 'answers' | 'prizes';
  label: string;
  count?: number;
}

export const useDetailsTabs = (unreadChatCount: number) => {
  const tabsList: IDetailsTab[] = useMemo(
    () => [
      {
        label: 'Latest',
        id: 'chat' as const,
        count: unreadChatCount,
      },
      {
        label: 'Scores',
        id: 'latest' as const,
      },
      {label: 'Questions', id: 'answers' as const},
    ],
    [unreadChatCount],
  );

  return tabsList;
};
