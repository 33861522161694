import {Action} from 'redux';

export enum ActionType {
  STARTUP = 'STARTUP',
  SET_INITIAL_VIEW_STATE = 'SET_INITIAL_VIEW_STATE',

  NO_OP = 'NO_OP',

  MESSAGE_SENT = 'MESSAGE_SENT',
  SEND_NAVIGATE = 'SEND_NAVIGATE',
  SEND_LOGIN = 'SEND_LOGIN',
  SEND_ANALYTICS = 'SEND_ANALYTICS',
  SEND_CURRENT_SCREEN = 'SEND_CURRENT_SCREEN',
  SEND_FOLLOW_USER = 'SEND_FOLLOW_USER',
  SEND_UNFOLLOW_USER = 'SEND_UNFOLLOW_USER',
  SEND_ENTER_COMPETITION = 'SEND_ENTER_COMPETITION',
  SEND_CHANGE_ANSWER = 'SEND_CHANGE_ANSWER',
  SEND_QUESTION_ANSWERED = 'SEND_QUESTION_ANSWERED',
  SEND_QUESTION_LIKED = 'SEND_QUESTION_LIKED',
  SEND_OVERLAY_ADD = 'SEND_OVERLAY_ADD',
  SEND_EDIT_PROFILE = 'SEND_EDIT_PROFILE',
  SEND_MUTE = 'SEND_MUTE',

  CONFIG_MESSAGE = 'CONFIG_MESSAGE',
  REMOTE_CONFIG_MESSAGE = 'REMOTE_CONFIG_MESSAGE',
  LOCAL_ANSWERS_MESSAGE = 'LOCAL_ANSWERS_MESSAGE',
  UPCOMING_COMPETITIONS_MESSAGE = 'UPCOMING_COMPETITIONS_MESSAGE',
  COMPETITION_MESSAGE = 'COMPETITION_MESSAGE',
  COMPETITION_SUMMARY_MESSAGE = 'COMPETITION_SUMMARY_MESSAGE',
  QUESTIONS_MESSAGE = 'QUESTIONS_MESSAGE',
  AUTH_MESSAGE = 'AUTH_MESSAGE',
  AUTH_TEMPORARY_USER_SCORE_MESSAGE = 'AUTH_TEMPORARY_USER_SCORE_MESSAGE',
  USER_MESSAGE = 'USER_MESSAGE',
  USER_FOLLOWING_MESSAGE = 'USER_FOLLOWING_MESSAGE',
  USER_ENTRY_MESSAGE = 'USER_ENTRY_MESSAGE',
  USER_CHALLENGES = 'USER_CHALLENGES',
  USER_CHALLENGE_COUNTS = 'USER_CHALLENGE_COUNTS',
  USER_CHALLENGE_READ_COUNTS = 'USER_CHALLENGE_READ_COUNTS',
  LEADERBOARD_CONFIG_MESSAGE = 'LEADERBOARD_CONFIG_MESSAGE',
  LEADERBOARD_MESSAGE = 'LEADERBOARD_MESSAGE',
  COMPETITION_COUNTS_MESSAGE = 'COMPETITION_COUNTS_MESSAGE',
  COMPETITION_VIPS_MESSAGE = 'COMPETITION_VIPS_MESSAGE',
  COMPETITION_STATUS_MESSAGE = 'COMPETITION_STATUS_MESSAGE',
  COMPETITION_CHAT_MESSAGE = 'COMPETITION_CHAT_MESSAGE',
  COMPETITION_CHAT_READ_DATE = 'COMPETITION_CHAT_READ_DATE',
  ENTER_COMP_STATUS_MESSAGE = 'ENTER_COMP_STATUS_MESSAGE',
  PROFILE_IMAGE_UPLOAD = 'PROFILE_IMAGE_UPLOAD',
  MUTE = 'MUTE',
  FOLLOWED_ENTRY = 'FOLLOWED_ENTRY',

  SET_LOCAL_USER = 'SET_LOCAL_USER',
  SET_PAGE_INDEX = 'SET_PAGE_INDEX',
}

export interface IAction<P> extends Action<ActionType> {
  type: ActionType;
  payload: P;
}

export const noOpAction = (): IAction<null> => ({
  payload: null,
  type: ActionType.NO_OP,
});
