import {merge, of} from 'rxjs';
import {ajax} from 'rxjs/ajax';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';

import {Value} from '@sinclair/typebox/value';
import {TOpenGraph} from '../interfaces/firestore/FirestoreInterfaces';
import {assertType} from './TypeUtils';

const ogs = new Map<string, any>();

export const getOpenGraphStream = (gameUrl: string) => (url: string) => {
  const requestStream = of(url).pipe(
    delay(90),
    mergeMap((url) =>
      ajax({
        url: `${gameUrl}/get_open_graph?url=${url}`,
        method: 'GET',
      }).pipe(
        map((response) => Value.Cast(TOpenGraph, response.response)),
        tap((og) => ogs.set(url, og)),
      ),
    ),
    catchError((err) =>
      of(err).pipe(map(() => assertType<TOpenGraph>({url: '', domain: ''}))),
    ),
  );
  const cacheStream = of(ogs.get(url)).pipe(
    filter((og): og is TOpenGraph => og !== undefined),
    delay(50),
  );

  return merge(cacheStream, requestStream).pipe(take(1));
};
