import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch, useNavigate} from 'react-router';

import {AppAction} from '@chancer/common/lib/app/AppMessage';
import {ILocalAnswersForComp} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseComp,
  TFirebaseCompEntry,
  TFirebaseQuestion,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {QuestionStatus} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getSimpleMessageOverlayFromApp} from '@chancer/common/lib/interfaces/overlay/OverlayCreator';
import {
  getAnswersBreakdownForQuestion,
  getLikesForQuestion,
  getUserHasLiked,
  getUserSelectedAnswer,
} from '@chancer/common/lib/utils/CompetitionUtils';
import log from '@chancer/common/lib/utils/Log';
import {getIsSocialEmbed} from '@chancer/common/lib/utils/MediaUtils';
import {
  onQuestionAnswered,
  onQuestionLiked,
} from '../actions/competitions/CompetitionActions';
import {
  sendChangeAnswer,
  sendEnterCompetition,
  sendMute,
} from '../actions/messages/MessageActions';
import {addOverlay} from '../actions/overlay/OverlayActions';
import {setCurrentPageIndex} from '../actions/startup/StartupActions';
import {getCurrentCompetitionsLocalAnswers} from '../selectors/answers/AnswersSelectors';
import {getMute} from '../selectors/audio/AudioSelectors';
import {
  getClientCompetitionStatus,
  getCompetition,
  getCompetitionCounts,
  getIsDraftMode,
  getQuestions,
  getUsersCompetitionEntry,
} from '../selectors/competitions/CompetitionSelectors';
import {
  getAppPlatform,
  getSafeAreaBottomPadding,
  getSafeAreaTopPadding,
} from '../selectors/config/ConfigSelectors';
import {
  COMP_DETAILS_INDEX,
  HOME_PAGE_INDEX,
  LEADER_INDEX,
  getCurrentPageIndex,
  getIsAuthRequired,
  getIsEntryRequired,
  getIsQuestionsRequired,
  getIsSignUpNameRequired,
  getIsViewStateSet,
  getQuestionsToAnswer,
  shouldPageNavigationBeAddedToHistory,
} from '../selectors/startup/StartupSelectors';
import {
  getFollowedUsersAnswersByQuestion,
  getLatestUserName,
} from '../selectors/user/AppUserSelectors';
import './Application.scss';
import {CompetitionScreen} from './Competition/CompetitionScreen';
import * as LazyBundler from './LazyLoader';
import {CompetitionDetailsSubView} from './Leaderboard/CompetitionDetailsScreen';

enum EntryState {
  NONE,
  SEND_ENTRY,
  SEND_ANSWER_CHANGE,
  ENTRY_SENT,
}

const Application: React.FC = () => {
  const competition = useSelector(getCompetition);
  const questions = useSelector(getQuestions);
  const questionsToAnswer = useSelector(getQuestionsToAnswer);
  const localAnswers = useSelector(getCurrentCompetitionsLocalAnswers);
  const usersEntryStatus = useSelector(getUsersCompetitionEntry);
  const competitionData = useSelector(getCompetitionCounts);
  const isDraftMode = useSelector(getIsDraftMode);
  const compStatus = useSelector(getClientCompetitionStatus);
  const readyToRender = useSelector(getIsViewStateSet);
  const isEntryRequired = useSelector(getIsEntryRequired);
  const isQuestionScreensRequired = useSelector(getIsQuestionsRequired);
  const isLoginRequired = useSelector(getIsAuthRequired);
  const isSignUpNameRequired = useSelector(getIsSignUpNameRequired);
  const currentPageIndex = useSelector(getCurrentPageIndex);
  const appPlatform = useSelector(getAppPlatform);
  const safeAreaTopPadding = useSelector(getSafeAreaTopPadding);
  const safeAreaBottomPadding = useSelector(getSafeAreaBottomPadding);
  const followedUsersEntries = useSelector(getFollowedUsersAnswersByQuestion);
  const userName = useSelector(getLatestUserName);

  const mute = useSelector(getMute);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useMatch('/:id/*');

  const pageIndex = parseInt(
    match && match.params ? match.params.id ?? '' : '',
    10,
  );

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [animateScreenSlide, setAnimateScreenSlide] = useState(false);
  const [entryState, setEntryState] = useState(EntryState.NONE);

  const _navigateToPageIndex = useCallback(
    (index: number, addToHistory?: boolean) => {
      if (
        shouldPageNavigationBeAddedToHistory(index, addToHistory) &&
        pageIndex !== index
      ) {
        navigate({
          pathname: `/${index}`,
          search: window.location.search,
        });
      } else {
        navigate(
          {
            pathname: `/${index}`,
            search: window.location.search,
          },
          {replace: true},
        );
      }
    },
    [navigate, pageIndex],
  );

  useEffect(() => {
    let debounce: number;
    const onResize = () => {
      clearTimeout(debounce);
      debounce = window.setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 100);
    };
    window.onresize = onResize;
    onResize();
    return () => {
      clearTimeout(debounce);
      window.onresize = null;
    };
  }, []);

  useEffect(() => {
    if (currentPageIndex !== pageIndex && !isNaN(pageIndex)) {
      log.info('setCurrentPageIndex', pageIndex);
      dispatch(setCurrentPageIndex(pageIndex));
    }
  }, [currentPageIndex, pageIndex, dispatch]);

  const pageCounter = {
    count: 0,
  };

  const _onQuestionAnswered = useCallback(
    (comp: TFirebaseComp, question: TFirebaseQuestion, index: number) => {
      dispatch(onQuestionAnswered(comp.id, question.id, index));
    },
    [dispatch],
  );

  const _onChangeAnswer = useCallback(
    (compId: string, questionId: string, answer: number) => {
      dispatch(onQuestionAnswered(compId, questionId, answer));
      setTimeout(() => {
        setEntryState(EntryState.SEND_ANSWER_CHANGE);
      }, 500);
    },
    [dispatch],
  );

  const _onQuestionLiked = useCallback(
    (comp: TFirebaseComp, question: TFirebaseQuestion, liked: boolean) => {
      dispatch(onQuestionLiked(comp.id, question.id, liked));
    },
    [dispatch],
  );

  const _onMute = useCallback(() => {
    dispatch(sendMute());
  }, [dispatch]);

  const _goToCompHome = (addToHistory?: boolean) => {
    setAnimateScreenSlide(false);
    _navigateToPageIndex(HOME_PAGE_INDEX, addToHistory);
  };

  const _goToCompDetails = useCallback(
    (addToHistory: boolean = true) => {
      setAnimateScreenSlide(false);
      navigate(
        {
          pathname: `/${COMP_DETAILS_INDEX}/${CompetitionDetailsSubView.LATEST}`,
          search: window.location.search,
        },
        {replace: !addToHistory},
      );
    },
    [navigate],
  );

  useEffect(() => {
    if (
      entryState === EntryState.SEND_ENTRY ||
      entryState === EntryState.SEND_ANSWER_CHANGE
    ) {
      log.debug(
        `Submitting ${
          entryState === EntryState.SEND_ANSWER_CHANGE
            ? 'AnswerChange'
            : 'CompEntry'
        }`,
      );
      const previousState = entryState;
      setEntryState(EntryState.ENTRY_SENT);
      if (
        !questions?.some(
          (q) =>
            q.status === QuestionStatus.OPEN ||
            q.status === QuestionStatus.OPEN_CHANGEABLE,
        )
      ) {
        log.debug('_submitCompEntry: Ineligible to enter');
        dispatch(
          addOverlay(
            getSimpleMessageOverlayFromApp({
              title: 'Entries are closed',
              message:
                'Sorry, this competition is now in play. Entries are closed but look out for our next game and get in early!',
              actionButtonText: 'Leaderboard',
              onCloseRoutine: AppAction.GO_HOME,
              onActionRoutine: AppAction.GO_LEADERBOARD,
            }),
          ),
        );
        return;
      }
      if (previousState === EntryState.SEND_ENTRY) {
        dispatch(sendEnterCompetition(userName || 'UNSET'));
        _goToCompDetails(false);
      } else {
        dispatch(sendChangeAnswer(userName || 'UNSET'));
      }
    }
  }, [dispatch, _goToCompDetails, questions, userName, entryState]);

  useEffect(() => {
    log.debug('Send auto entry handling effect');
    if (
      isEntryRequired &&
      !(entryState === EntryState.ENTRY_SENT) &&
      !isQuestionScreensRequired &&
      !isLoginRequired &&
      !isSignUpNameRequired
    ) {
      log.debug('Send auto entry handling effect: Executing');
      setEntryState(EntryState.SEND_ENTRY);
    }
  }, [
    entryState,
    isEntryRequired,
    isQuestionScreensRequired,
    isLoginRequired,
    isSignUpNameRequired,
  ]);

  const _nextPage = useCallback(() => {
    if (currentPageIndex < pageCounter.count - 1) {
      window.scrollTo(0, 0);
      setAnimateScreenSlide(true);
      _navigateToPageIndex(currentPageIndex + 1);
    } else {
      // Go to leaderboard
      if (isEntryRequired) {
        setEntryState(EntryState.SEND_ENTRY);
      }
      _goToCompDetails(false);
    }
  }, [
    currentPageIndex,
    pageCounter.count,
    isEntryRequired,
    _goToCompDetails,
    _navigateToPageIndex,
  ]);

  const _goToAnswers = useCallback(() => {
    setAnimateScreenSlide(false);
    navigate({
      pathname: `/${COMP_DETAILS_INDEX}/${CompetitionDetailsSubView.ANSWERS}`,
      search: window.location.search,
    });
  }, [navigate]);

  const _goToLeaderboard = useCallback(() => {
    setAnimateScreenSlide(false);
    navigate({
      pathname: `/${LEADER_INDEX}`,
      search: window.location.search,
    });
  }, [navigate]);

  const _getQuestionScreens = (
    currentIndex: number,
    comp: TFirebaseComp,
    counter: {count: number},
    questions: TFirebaseQuestion[],
    answers: ILocalAnswersForComp,
    usersEntryStatus: TFirebaseCompEntry | null,
  ) => {
    return (
      <Fragment>
        {questions.map((question, index) => {
          const QuestionComponent = getIsSocialEmbed(question.media)
            ? LazyBundler.LazySocialQuestionScreen
            : LazyBundler.LazyQuestionScreen;
          return (
            <QuestionComponent
              version={2}
              mute={mute}
              currentIndex={currentIndex}
              pageIndex={counter.count++}
              windowHeight={windowHeight}
              appPlatform={appPlatform}
              safeAreaTopPadding={safeAreaTopPadding}
              safeAreaBottomPadding={safeAreaBottomPadding}
              key={`q_${question.id}`}
              comp={comp}
              question={question}
              chosenAnswer={getUserSelectedAnswer(question.id, answers)}
              numQuestions={competitionData.numQuestions}
              liked={getUserHasLiked(question.id, answers, usersEntryStatus)}
              likesCount={getLikesForQuestion(question.id, competitionData)}
              answersBreakdown={getAnswersBreakdownForQuestion(
                question.id,
                competitionData,
              )}
              followedUsersEntries={followedUsersEntries[question.id] ?? []}
              onAnswerSelected={_onQuestionAnswered}
              onQuestionLiked={_onQuestionLiked}
              onNextPage={_nextPage}
              onMute={_onMute}
            />
          );
        })}
      </Fragment>
    );
  };

  let content: JSX.Element;
  if (competition === null || questions === null || !readyToRender) {
    content = <div className="application__loading">Loading</div>;
  } else {
    content = (
      <Fragment>
        <LazyBundler.LazyLeaderboardScreen
          currentIndex={currentPageIndex}
          pageIndex={pageCounter.count++}
          windowHeight={windowHeight}
          userHasEntered={usersEntryStatus !== null}
          comp={competition}
          appPlatform={appPlatform}
          safeAreaTopPadding={safeAreaTopPadding}
          safeAreaBottomPadding={safeAreaBottomPadding}
          onNext={_goToCompDetails}
        />
        <LazyBundler.LazyCompetitionDetailsScreen
          currentIndex={currentPageIndex}
          pageIndex={pageCounter.count++}
          windowHeight={windowHeight}
          userHasEntered={usersEntryStatus !== null}
          comp={competition}
          appPlatform={appPlatform}
          safeAreaTopPadding={safeAreaTopPadding}
          safeAreaBottomPadding={safeAreaBottomPadding}
          followedUsersEntries={followedUsersEntries}
          onNext={_goToCompHome}
          onGoToLeaderboard={_goToLeaderboard}
          onChangeAnswer={_onChangeAnswer}
        />
        <CompetitionScreen
          mute={mute}
          currentIndex={currentPageIndex}
          pageIndex={pageCounter.count++}
          windowHeight={windowHeight}
          comp={competition}
          compStatus={compStatus}
          competitionCounts={competitionData}
          playEnabled={questions !== null}
          entryStatus={usersEntryStatus}
          appPlatform={appPlatform}
          safeAreaTopPadding={safeAreaTopPadding}
          safeAreaBottomPadding={safeAreaBottomPadding}
          onPlayNow={_nextPage}
          onCompDetails={_goToCompDetails}
          onAnswers={_goToAnswers}
          onMute={_onMute}
        />
        {isQuestionScreensRequired && (
          <Fragment>
            {_getQuestionScreens(
              currentPageIndex,
              competition,
              pageCounter,
              questionsToAnswer,
              localAnswers,
              usersEntryStatus,
            )}
          </Fragment>
        )}
        {isEntryRequired && (
          <Fragment>
            {isLoginRequired && (
              <LazyBundler.LazyQuestionsCompleteScreen
                mute={mute}
                currentIndex={currentPageIndex}
                pageIndex={pageCounter.count++}
                windowHeight={windowHeight}
                comp={competition}
                compStatus={compStatus}
                competitionCounts={competitionData}
                appPlatform={appPlatform}
                safeAreaTopPadding={safeAreaTopPadding}
                safeAreaBottomPadding={safeAreaBottomPadding}
                onNext={_nextPage}
                onMute={_onMute}
              />
            )}
            {isSignUpNameRequired && (
              <LazyBundler.LazySignupNameScreen
                mute={mute}
                currentIndex={currentPageIndex}
                pageIndex={pageCounter.count++}
                windowHeight={windowHeight}
                comp={competition}
                compStatus={compStatus}
                appPlatform={appPlatform}
                safeAreaTopPadding={safeAreaTopPadding}
                safeAreaBottomPadding={safeAreaBottomPadding}
                onNext={_nextPage}
                onMute={_onMute}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }

  return (
    <div
      className="application__container"
      style={{height: windowHeight > 0 ? `${windowHeight}px` : '100%'}}>
      <div
        className="application__screens"
        style={{
          transitionProperty: `${animateScreenSlide ? 'left' : 'none'}`,
          left: `-${Math.max(currentPageIndex, 0) * 100}%`,
        }}>
        {content}
      </div>{' '}
      {isDraftMode && competition !== null && (
        <div className="application__draft-watermark">Draft</div>
      )}
    </div>
  );
};

export default Application;
