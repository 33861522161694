import {createSelector} from 'reselect';
import {IAppState} from '../../state/AppState';
import {getLeaderboard} from '../leaderboard/AppLeaderboardSelectors';
import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {getCompetition} from '../competitions/CompetitionSelectors';

const getInternalVips = (state: IAppState) =>
  state.messageData.competitionVips.response;

export const getVipsInLeaderboard = createSelector(
  getCompetition,
  getInternalVips,
  getLeaderboard,
  (comp, vips, leaderboard): TFirebaseUser[] => {
    if (!vips) {
      return [];
    }
    return vips
      .filter((vip) => leaderboard.some((leader) => leader.u === vip.id))
      .sort(sortVips(comp?.vendor));
  },
);

export const getCanShowVipComparison = createSelector(
  getVipsInLeaderboard,
  (vipsInLeaderboard): boolean => vipsInLeaderboard.length > 1,
);

export const getVipsForComparison = createSelector(
  getVipsInLeaderboard,
  (vips) => vips.slice(0, 2),
);

const sortVips = (vendor: string | undefined) => {
  const PREFERRED_ORDER =
    vendor === 'warriors'
      ? WARRIORS
      : vendor === 'nznbl'
        ? NZNBL
        : vendor === 'nblrams'
          ? NBLRAMS
          : [];

  return (a: TFirebaseUser, b: TFirebaseUser) => {
    const aIndex = PREFERRED_ORDER.findIndex((name) => a.name.startsWith(name));
    const bIndex = PREFERRED_ORDER.findIndex((name) => b.name.startsWith(name));
    if (aIndex === -1 && bIndex === -1) {
      return 0;
    } else if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  };
};

const WARRIORS = [
  'TWL Will',
  'TWL Brad',
  'BuntyAppreciationSociety',
  '03Represent',
];

const NZNBL = ['Cf43', 'JN', 'Mulls', 'tomabo10', 'The Ramble', 'G.I.Antics'];
const NBLRAMS = ['The Ramble', 'Cf43', 'JN', 'Mulls', 'tomabo10', 'G.I.Antics'];
