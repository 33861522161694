import {
  TComp,
  TLeaderboard,
  TLeaderboardPrize,
  TMediaEntry,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import React, {ComponentType} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {PrimaryButton} from '../Button/PrimaryButton';
import {SecondaryButton} from '../Button/SecondaryButton';
import {
  COLOR_BACKGROUND,
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_TITLE,
} from '../Styles/DesignSystem-chancer';
import {LeaderboardImage} from './LeaderboardImage';

interface IProps {
  MediaComponent: ComponentType<{media: TMediaEntry}>;
  comp: TComp;
  leaderboardConfig: TLeaderboard;
  userHasEntered: boolean;
  onGoToLeaderboard: () => void;
  onMoreInfo: (info: string) => void;
}

export const PrizeCard: React.FC<IProps> = (props) => {
  const viewModel = getHeaderViewModel(
    props.userHasEntered,
    props.leaderboardConfig,
  );

  const _onPrimaryButtonClick = () => {
    props.onGoToLeaderboard();
  };

  const _onSecondaryButtonClick = () => {
    props.onMoreInfo(props.leaderboardConfig.details);
  };

  return (
    <LeaderboardImage
      style={styles.container}
      media={viewModel.media}
      backgroundColor={
        getColorConfiguration(COLOR_BACKGROUND)
          .leaderboardSummaryHeaderBackground
      }
      MediaComponent={props.MediaComponent}>
      <Text style={styles.title}>{viewModel.name}</Text>
      <Text style={styles.info}>{viewModel.caption}</Text>
      <View style={styles.buttonsContainer}>
        <PrimaryButton
          style={styles.button}
          size="medium"
          label={viewModel.buttonLabel}
          onPress={_onPrimaryButtonClick}
        />
        <SecondaryButton
          style={styles.button}
          size="medium"
          label="Game rules"
          onPress={_onSecondaryButtonClick}
        />
      </View>
    </LeaderboardImage>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  title: {
    color: COLOR_LIGHT,
    fontFamily: FONT_TITLE,
    fontSize: 28,
    maxWidth: '55%',
    position: 'absolute',
    textTransform: 'uppercase',
    top: 32,
  },
  info: {
    color: COLOR_GREY_8,
    fontFamily: FONT_COPY,
    fontSize: 16,
    marginBottom: 16,
  },
  buttonsContainer: {flexDirection: 'row', justifyContent: 'space-between'},
  button: {
    flexGrow: 0,
    width: 140,
    borderWidth: 0,
  },
});

const getHeaderViewModel = (
  userHasEntered: boolean,
  leaderboardConfig: TLeaderboard,
): {
  media: TMediaEntry;
  name: string;
  caption: string;
  buttonLabel: string;
} => {
  let prize: TLeaderboardPrize | undefined;

  if (leaderboardConfig.prizes.length > 0) {
    prize = leaderboardConfig.prizes[0];

    return {
      name: prize.title,
      media: prize.media,
      caption: prize.media.caption ?? '',
      buttonLabel: userHasEntered ? 'Leaderboard' : "Who's Playing?",
    };
  }

  return {
    name: leaderboardConfig.name,
    media: leaderboardConfig.media,
    caption: leaderboardConfig.media.caption ?? '',
    buttonLabel: userHasEntered ? 'Leaderboard' : 'Top Fans',
  };
};
