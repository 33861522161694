import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import React, {useEffect, useState} from 'react';
import {Observable, Subscription} from 'rxjs';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {ChallengeButton} from './ChallengeButton';

interface IProps {
  style?: CompositedViewStyle;
  result?: number[];
  currentUser: TFirebaseUser;
  challengerId: string;
  challengedId: string;
  compImage?: string;
  compPrimaryColor?: string;
  badge: number;
  size?: 'medium' | 'large';
  getUserById: (userId: string) => Observable<TFirebaseUser>;
  onSelected: (userA: TFirebaseUser, userB: TFirebaseUser) => void;
}

export const LazyChallengeButton: React.FC<IProps> = (props) => {
  const {currentUser, getUserById} = props;

  const [challenger, setChallenger] = useState<TFirebaseUser | null>(
    currentUser.id === props.challengerId ? currentUser : null,
  );
  const [challenged, setChallenged] = useState<TFirebaseUser | null>(
    currentUser.id === props.challengedId ? currentUser : null,
  );

  useEffect(() => {
    let sub: Subscription | undefined;
    if (challenger === null) {
      sub = getUserById(props.challengerId).subscribe({
        next: (u) => {
          setChallenger(u);
        },
      });
    }
    if (challenged === null) {
      sub = getUserById(props.challengedId).subscribe({
        next: (u) => {
          setChallenged(u);
        },
      });
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [
    getUserById,
    challenger,
    challenged,
    props.challengerId,
    props.challengedId,
  ]);

  return (
    challenger !== null &&
    challenged !== null && (
      <ChallengeButton
        style={props.style}
        compImage={props.compImage}
        currentUser={currentUser}
        challenger={challenger}
        challenged={challenged}
        showLabels={true}
        badge={props.badge}
        result={props.result}
        size={props.size}
        compPrimaryColor={props.compPrimaryColor}
        onSelected={props.onSelected}
      />
    )
  );
};
