import {IAction, ActionType} from '../Actions';
import {ILocalUser} from '../../state/AppState';

export const setLocalUser = (
  name: string | null,
  imageUrl: string | null,
): IAction<ILocalUser> => ({
  payload: {name, imageUrl},
  type: ActionType.SET_LOCAL_USER,
});
