import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {
  COLOR_DARK_4,
  COLOR_LIGHT,
  COLOR_PURPLE,
  FONT_COPY_BOLD,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {VsIcon, VsIconBubble} from '../Svg/SvgIcons';

interface IProps {
  style?: CompositedViewStyle;
  onSelected: () => void;
  size?: 'small' | 'medium' | 'large';
}

export const NewChallengeButton: React.FC<IProps> = (props) => {
  const size = props.size || 'large';
  const styles = getStyles(size);
  const _onPress = () => {
    props.onSelected();
  };
  return (
    <Pressable style={[styles.container, props.style]} onPress={_onPress}>
      {size !== 'small' && (
        <>
          <View style={styles.vsIconBubble}>
            <VsIconBubble
              color={COLOR_LIGHT}
              width={size === 'large' ? 64 : 64 / 2}
              height={size === 'large' ? 55 : 55 / 2}
            />
          </View>
          <Text style={styles.tapLabel}>Create a challenge</Text>
        </>
      )}
      {size === 'small' && (
        <VsIcon color={COLOR_LIGHT} width={14} height={26} />
      )}
    </Pressable>
  );
};

const getStyles = (size: 'small' | 'medium' | 'large') =>
  StyleSheet.create({
    container: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: size === 'large' ? 'flex-end' : 'center',
      width: size === 'large' ? 112 : size === 'medium' ? 72 : 32,
      height: size === 'large' ? 112 * 2 : size === 'medium' ? 104 : 32,
      overflow: 'hidden',
      paddingVertical: size === 'large' ? 32 : size === 'medium' ? 32 / 2 : 0,
      borderRadius: size !== 'small' ? 16 : 4,
      borderWidth: 2,
      borderColor: COLOR_DARK_4,
      backgroundColor: COLOR_PURPLE,
    },
    vsIconBubble: {
      marginBottom: size === 'large' ? 40 : 40 / 2,
    },
    tapLabel: {
      color: COLOR_LIGHT,
      fontFamily: FONT_COPY_BOLD,
      fontSize: size === 'large' ? 16 : 12,
      textAlign: 'center',
    },
  });
