import {IAction, ActionType} from '../Actions';

import {
  IQuestionAnswer,
  IQuestionLike,
} from '@chancer/common/lib/core/actions/competitions/CompetitionActions';

export const onQuestionAnswered = (
  competitionId: string,
  questionId: string,
  answerIndex: number,
): IAction<IQuestionAnswer> => ({
  type: ActionType.SEND_QUESTION_ANSWERED,
  payload: {
    competitionId,
    questionId,
    answerIndex,
  },
});

export const onQuestionLiked = (
  competitionId: string,
  questionId: string,
  liked: boolean,
): IAction<IQuestionLike> => ({
  type: ActionType.SEND_QUESTION_LIKED,
  payload: {
    competitionId,
    questionId,
    liked,
  },
});
