import {IAppState} from '../../state/AppState';

export const getIsLoggingIn = (state: IAppState) => state.auth.isLogginIn;

export const getAuthUser = (state: IAppState) => state.auth.user;

export const getIsLoggedIn = (state: IAppState) => getAuthUser(state) !== null;

export const getTemporaryUserScore = (state: IAppState) => {
  return state.auth.temporaryUserScore;
};
