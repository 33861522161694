import {TMediaEntry} from '../interfaces/firestore/FirestoreInterfaces';

export const getIsImage = (
  media: TMediaEntry | undefined | null,
): media is Required<Pick<TMediaEntry, 'image' | 'caption'>> =>
  media?.image?.url !== undefined;

export const getIsVideo = (
  media: TMediaEntry | undefined | null,
): media is Required<Pick<TMediaEntry, 'video' | 'caption'>> =>
  media?.video?.url !== undefined;

export const getIsSocialEmbed = (
  media: TMediaEntry | undefined | null,
): media is Required<Pick<TMediaEntry, 'social' | 'caption'>> =>
  media?.social?.embed !== undefined;
