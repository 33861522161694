import {
  TFirebaseChallengeGroup,
  TFirebaseUser,
} from '../interfaces/firestore/FirestoreClientInterfaces';
import {
  TGroup,
  TUserCompletedChallenge,
} from '../interfaces/firestore/FirestoreInterfaces';

export const getChallengeQueryKey = (users: string[]) => {
  return [...users].sort().join('_');
};

// JK: If we want to remove the compound ID, we need to ensure that the challenge is not created twice.
// Currently as we're using the compound id the challenge is recreated with the same id and details if a
// user clicks the invote link 2 times.
export const createChallengeCompositeId = (
  challengerId: string,
  challengedId: string,
  compId: string,
) => {
  return `${getChallengeQueryKey([challengerId, challengedId])}_${compId}`;
};

export const getChallengeCompId = (challenge: TFirebaseChallengeGroup) => {
  return challenge.competitionId;
};

// Updates a challenge so it represents the current user as the first user
export const getNormalisedChallengeForCurrentUser = (
  currentUser: TFirebaseUser,
  challenge: TFirebaseChallengeGroup,
): TFirebaseChallengeGroup => {
  return currentUser.id === challenge.users[0]
    ? challenge
    : {
        ...challenge,
        users: [challenge.users[1], challenge.users[0]],
        ...(challenge.result
          ? {result: [challenge.result[1], challenge.result[0]]}
          : {}),
      };
};

export const getChallengerId = (challenge: TGroup) => {
  return challenge.users[0];
};

export const getChallengedId = (challenge: TGroup) => {
  return challenge.users[1];
};

enum Streak {
  None,
  Challenger,
  Challenged,
}
export const calculateChallengeComplete = (
  currentChallenge: TFirebaseChallengeGroup,
  currentChallengeResult: number[],
  allChallenges: TFirebaseChallengeGroup[],
): TUserCompletedChallenge => {
  const challenger = getChallengerId(currentChallenge);
  const challenged = getChallengedId(currentChallenge);
  const vendor = currentChallenge.vendors[0];

  // Streaks use negative value for streak to the challenged user
  let streak = 0;
  let streakVendor = 0;

  // Longest streaks are always positive
  const longestStreak = [0, 0];
  const longestStreakVendor = [0, 0];

  const wins = [0, 0];
  const winsVendor = [0, 0];
  let totalPlayed = 0;
  let totalPlayedVendor = 0;

  let lastStreakValue: Streak = Streak.None;
  let lastStreakVendorValue: Streak = Streak.None;

  const processChallenge = (
    challenge: TFirebaseChallengeGroup,
    result: number[],
  ) => {
    const isVendorGame = vendor && vendor === challenge.vendors[0];
    totalPlayed++;
    if (isVendorGame) {
      totalPlayedVendor++;
    }
    if (result[0] > result[1]) {
      // Challenger wins
      wins[0]++;
      if (isVendorGame) {
        winsVendor[0]++;
      }

      if (lastStreakValue === Streak.Challenged) {
        streak = 1;
      }
      if (
        lastStreakValue === Streak.None ||
        lastStreakValue === Streak.Challenger
      ) {
        streak++;
      }
      if (streak > longestStreak[0]) {
        longestStreak[0] = streak;
      }
      lastStreakValue = Streak.Challenger;

      if (isVendorGame) {
        if (lastStreakVendorValue === Streak.Challenged) {
          streakVendor = 1;
        }
        if (
          lastStreakVendorValue === Streak.None ||
          lastStreakVendorValue === Streak.Challenger
        ) {
          streakVendor++;
        }
        if (streakVendor > longestStreakVendor[0]) {
          longestStreakVendor[0] = streakVendor;
        }
        lastStreakVendorValue = Streak.Challenger;
      }
    } else if (result[0] < result[1]) {
      // Challeged wins
      wins[1]++;
      if (isVendorGame) {
        winsVendor[1]++;
      }

      if (lastStreakValue === Streak.Challenger) {
        streak = -1;
      }
      if (
        lastStreakValue === Streak.None ||
        lastStreakValue === Streak.Challenged
      ) {
        streak--;
      }
      if (Math.abs(streak) > longestStreak[1]) {
        longestStreak[1] = Math.abs(streak);
      }
      lastStreakValue = Streak.Challenged;

      if (isVendorGame) {
        if (lastStreakVendorValue === Streak.Challenger) {
          streakVendor = -1;
        }
        if (
          lastStreakVendorValue === Streak.None ||
          lastStreakVendorValue === Streak.Challenged
        ) {
          streakVendor--;
        }
        if (Math.abs(streakVendor) > longestStreakVendor[1]) {
          longestStreakVendor[1] = Math.abs(streakVendor);
        }
        lastStreakVendorValue = Streak.Challenged;
      }
    } else {
      // Draw
      if (streak > 0) {
        streak++;
        if (streak > longestStreak[0]) {
          longestStreak[0] = streak;
        }
      } else if (streak < 0) {
        streak--;
        if (Math.abs(streak) > longestStreak[1]) {
          longestStreak[1] = Math.abs(streak);
        }
      }
      if (isVendorGame) {
        if (streakVendor > 0) {
          streakVendor++;
          if (streakVendor > longestStreakVendor[0]) {
            longestStreakVendor[0] = streakVendor;
          }
        } else if (streakVendor < 0) {
          streakVendor--;
          if (Math.abs(streakVendor) > longestStreakVendor[1]) {
            longestStreakVendor[1] = Math.abs(streakVendor);
          }
        }
      }
    }
  };

  [...allChallenges]
    .sort((a, b) => {
      if (a.id === currentChallenge.id) return 1;
      if (b.id === currentChallenge.id) return -1;
      if (a.resulted && b.resulted) {
        return a.resulted.seconds - b.resulted.seconds;
      } else if (a.resulted) {
        return 1;
      } else if (b.resulted) {
        return -1;
      }
      return 0;
    })
    .forEach((challenge) => {
      // Older format
      if (
        Object.prototype.hasOwnProperty.call(challenge, 'results') &&
        !challenge.result
      ) {
        challenge.result = (challenge as any)['results'] as number[];
      }

      if (!challenge.result) return; // Incomplete challenge

      if (challenge.id === currentChallenge.id) {
        processChallenge(challenge, currentChallengeResult);
        return;
      }

      const challengeResult =
        challenge.users[0] === challenger && challenge.users[1] === challenged
          ? [...challenge.result]
          : [...challenge.result].reverse();
      processChallenge(challenge, challengeResult);
    });
  return {
    challengeId: currentChallenge.id,
    compId: getChallengeCompId(currentChallenge),
    users: currentChallenge.users,
    result: currentChallengeResult,
    streak,
    longestStreak,
    streakVendor,
    longestStreakVendor,
    wins,
    winsVendor,
    totalPlayed,
    totalPlayedVendor,
  };
};
