import React from 'react';
import {applyPropClassName} from '@chancer/common/lib/utils/ComponentUtils';

import './LeaderboardDetails.scss';
import '../../Application.scss';

interface IProps {
  className?: string;
  safeAreaTopPadding: number;
  details: string;
  onClose: () => void;
}

export const LeaderboardDetails: React.FC<IProps> = (props) => {
  return (
    <div
      className={applyPropClassName('leaderboard-details__container', props)}
      style={{
        animationName: 'show-profile',
        animationDuration: '300ms',
        paddingTop: `${props.safeAreaTopPadding + 48}px`,
      }}>
      <div
        className="leaderboard-details__copy application__details-styling"
        dangerouslySetInnerHTML={{__html: props.details}}
      />
    </div>
  );
};
