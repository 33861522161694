import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {
  COLOR_DARK_4,
  COLOR_GREY_2,
  COLOR_LIGHT,
  FONT_COPY_BOLD,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  score: number | string;
  color?: 'light' | 'dark';
  size?: 'small' | 'medium' | 'large';
}

export const Score: React.FC<IProps> = (props) => {
  const {size = 'large', color = 'light'} = props;
  const styles = useMemo(() => getStyles(size, color), [size, color]);
  return (
    <View style={[styles.container, props.style]}>
      <Text style={styles.score}>{props.score}</Text>
    </View>
  );
};

const getStyles = (
  size: 'small' | 'medium' | 'large',
  color: 'light' | 'dark',
) =>
  StyleSheet.create({
    container: {
      width: size === 'small' ? 24 : size === 'medium' ? 32 : 36,
      // height: 36, // Crashes on Leaderboard screen. No idea why so using padding instead.
      paddingTop: size === 'small' ? 1 : 4,
      paddingBottom: size === 'small' ? 2 : 6,
      borderRadius: size === 'large' ? 8 : 4,
      display: 'flex',
      flexGrow: 0,
      flexShrink: 0,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: color === 'light' ? COLOR_GREY_2 : COLOR_DARK_4,
    },
    score: {
      pointerEvents: 'none',
      textAlign: 'center',
      userSelect: 'none',
      color: COLOR_LIGHT,
      fontFamily: size === 'small' ? FONT_COPY_BOLD : FONT_SUB_TITLE,
      fontSize: size === 'small' ? 16 : size === 'medium' ? 22 : 24,
    },
  });
