import React, {useMemo} from 'react';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';

import {TFirebaseQuestion} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {PrimaryButton} from '../Button/PrimaryButton';
import {
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_COPY_BOLD,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  question: TFirebaseQuestion;
  getHostname: (url: string) => string | undefined;
  showAnswerButton: boolean;
  onPressAnswerButton: () => void;
  onPressUrl: (url: string | undefined) => void;
}

export const QuestionCard: React.FC<IProps> = (props) => {
  const {question, getHostname} = props;

  const hostname = useMemo(() => {
    if (question?.link?.url) {
      const val = getHostname(question.link.url);
      if (val && val.startsWith('www.')) {
        return val.replace('www.', '');
      }

      return val;
    }

    return undefined;
  }, [getHostname, question?.link?.url]);

  return (
    <Pressable
      style={[styles.container, props.style]}
      onPress={() => props.onPressUrl(question.link?.url)}>
      <Text style={styles.number}>{`Q${props.question.index + 1}`}</Text>
      <Text style={styles.copy}>{props.question.question}</Text>
      {props.showAnswerButton && (
        <PrimaryButton
          style={styles.answerButton}
          size="medium"
          label="Answer Question"
          onPress={props.onPressAnswerButton}
        />
      )}
      {question.link !== null ? (
        <View style={styles.linkContainer}>
          <View style={styles.copyContainer}>
            {question.link.caption && (
              <Text style={styles.title} numberOfLines={2} ellipsizeMode="tail">
                {question.link.caption}
              </Text>
            )}
            {(question.link.publisher?.caption ?? hostname) && (
              <Text
                style={styles.hostname}
                numberOfLines={1}
                ellipsizeMode="tail">
                {question.link.publisher?.caption ?? hostname}
              </Text>
            )}
          </View>
          {question.link.image?.url ? (
            <Image
              style={styles.image}
              resizeMode="cover"
              source={{uri: question.link.image?.url}}
            />
          ) : null}
        </View>
      ) : null}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  linkContainer: {
    flexDirection: 'row',
    marginTop: 16,
  },
  copyContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  number: {
    fontSize: 16,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    paddingBottom: 8,
  },
  copy: {
    fontSize: 16,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY,
    //paddingBottom: 16,
  },
  title: {
    fontFamily: FONT_COPY_BOLD,
    fontSize: 18,
    color: COLOR_LIGHT,
    overflow: 'hidden',
  },
  hostname: {
    fontFamily: FONT_COPY,
    fontSize: 14,
    color: COLOR_GREY_8,
    overflow: 'hidden',
  },
  image: {
    width: 64,
    height: 64,
    overflow: 'hidden',
    marginLeft: 16,
  },
  answerButton: {
    marginTop: 16,
  },
});
