import React, {useMemo} from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';

import {assertType} from '@chancer/common/lib/utils/TypeUtils';
import {Avatar} from '../Avatar/Avatar';
import {COLOR_GREY_6, FONT_COPY} from '../Styles/DesignSystem-chancer';
import {CompositedTextStyle, CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  name?: string;
  url?: string;
  getHostname: (url: string) => string | undefined;
  imageUrl?: string;
  onPress: (url: string | undefined) => void;
}

export const PublisherBar: React.FC<IProps> = (props) => {
  const {name, url, imageUrl, getHostname, onPress} = props;

  const hostname = useMemo(() => {
    if (url) {
      const val = getHostname(url);
      if (val && val.startsWith('www.')) {
        return val.replace('www.', '');
      }

      return val;
    }

    return undefined;
  }, [getHostname, url]);

  const copy = useMemo(() => (name ? name : hostname), [name, hostname]);
  const contextualCopy = useMemo(
    () =>
      assertType<CompositedTextStyle>([
        styles.copy,
        copy?.includes('.') ? {} : {textTransform: 'capitalize'},
      ]),
    [copy],
  );

  return (
    <Pressable
      style={[styles.container, props.style]}
      onPress={() => onPress(url)}>
      {imageUrl && (
        <Avatar style={styles.image} imageUrl={imageUrl} size={24} />
      )}
      <Text style={contextualCopy} numberOfLines={3} ellipsizeMode="tail">
        {copy}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 32,
    flexDirection: 'row',
    alignItems: 'center',
  },
  copy: {
    fontFamily: FONT_COPY,
    fontSize: 14,
    color: COLOR_GREY_6,
    overflow: 'hidden',
  },
  image: {
    overflow: 'hidden',
    marginRight: 8,
  },
});
