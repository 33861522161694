import {TFirebaseUser} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import React, {ReactElement, useMemo} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_DARK,
  COLOR_GREEN,
  COLOR_GREY_2,
  COLOR_GREY_3,
  COLOR_GREY_8,
  COLOR_LIGHT,
  COLOR_RED,
  COLOR_YELLOW,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';
import {CompositedTextStyle, CompositedViewStyle} from '../Styles/StyleTypes';
import {
  CircleCheckMarkIcon,
  CircleCheckMarkOutlineIcon,
  CircleCrossIcon,
} from '../Svg/SvgIcons';

export enum QuestionAnswerState {
  OPEN,
  IN_PLAY,
  CORRECT,
  WRONG,
  CHANGEABLE,
}

interface IProps {
  label: string;
  state: QuestionAnswerState;
  percentage: number | undefined;
  selected: boolean;
  userImageUrl: string | null;
  usersSelected: TFirebaseUser[];
  useCompareLayout?: boolean;
  compareSelected?: boolean;
  compareImageUrl?: string | null;
  onSelected: (() => void) | undefined;
}

interface IAnswerViewModel {
  answerContainerStyle: CompositedViewStyle;
  labelStyle: CompositedTextStyle;
  percentagePositions: number[];
  percentageColors: string[];
  icon: ReactElement | null;
  compareIcon: ReactElement | null;
}

const PERCENTAGE_HEX_OPACITY = '88';
const ICON_SIZE = 36;

export const QuestionAnswer: React.FC<IProps> = (props) => {
  const {
    state,
    selected,
    percentage,
    userImageUrl,
    compareSelected,
    compareImageUrl,
  } = props;

  const answerViewModel: IAnswerViewModel = useMemo(() => {
    const response: IAnswerViewModel = {
      answerContainerStyle: styles.answerContainer,
      labelStyle: styles.label,
      percentagePositions: [1, 1],
      percentageColors: [COLOR_DARK, COLOR_DARK],
      icon: null,
      compareIcon: null,
    };

    const percentageColor: string =
      state === QuestionAnswerState.CORRECT
        ? selected || compareSelected
          ? `${COLOR_GREEN}${PERCENTAGE_HEX_OPACITY}`
          : COLOR_GREY_3
        : state === QuestionAnswerState.WRONG
          ? selected || compareSelected
            ? `${COLOR_RED}${PERCENTAGE_HEX_OPACITY}`
            : COLOR_GREY_3
          : state === QuestionAnswerState.IN_PLAY
            ? COLOR_GREY_3
            : `${COLOR_YELLOW}${PERCENTAGE_HEX_OPACITY}`;

    const labelColor: string =
      state === QuestionAnswerState.CORRECT ||
      state === QuestionAnswerState.WRONG
        ? selected || compareSelected
          ? COLOR_LIGHT
          : COLOR_GREY_8
        : COLOR_LIGHT;

    response.labelStyle = [styles.label, {color: labelColor}];
    if (!selected && !compareSelected) {
      if (state === QuestionAnswerState.CORRECT) {
        response.icon = (
          <CircleCheckMarkOutlineIcon
            color={COLOR_GREY_3}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        );
      }
    } else {
      switch (state) {
        case QuestionAnswerState.CORRECT: {
          if (selected || compareSelected) {
            response.answerContainerStyle = [
              styles.answerContainer,
              styles.selectedAnswerContainer,
              {borderColor: COLOR_GREEN},
            ];
          }
          if (selected) {
            response.icon = (
              <CircleCheckMarkIcon
                color={COLOR_GREEN}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            );
          }
          if (compareSelected) {
            response.compareIcon = (
              <CircleCheckMarkIcon
                color={COLOR_GREEN}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            );
          }
          break;
        }
        case QuestionAnswerState.WRONG: {
          if (selected || compareSelected) {
            response.answerContainerStyle = [
              styles.answerContainer,
              styles.selectedAnswerContainer,
              {borderColor: COLOR_RED},
            ];
          }
          if (selected) {
            response.icon = (
              <CircleCrossIcon
                color={COLOR_RED}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            );
          }
          if (compareSelected) {
            response.compareIcon = (
              <CircleCrossIcon
                color={COLOR_RED}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            );
          }
          break;
        }
        case QuestionAnswerState.OPEN:
        case QuestionAnswerState.CHANGEABLE:
        case QuestionAnswerState.IN_PLAY: {
          if (selected || compareSelected) {
            response.answerContainerStyle = [
              styles.answerContainer,
              styles.selectedAnswerContainer,
              {borderColor: COLOR_YELLOW},
            ];
          }
          if (selected) {
            response.icon = (
              <Avatar
                imageUrl={userImageUrl}
                size={ICON_SIZE - 4}
                defaultIconColor={COLOR_GREY_2}
                highlightColor={COLOR_YELLOW}
                highlightWidth={2}
              />
            );
          }
          if (compareSelected) {
            response.compareIcon = (
              <Avatar
                imageUrl={compareImageUrl}
                size={ICON_SIZE - 4}
                defaultIconColor={COLOR_GREY_2}
                highlightColor={COLOR_YELLOW}
                highlightWidth={2}
              />
            );
          }
          break;
        }
      }
    }
    if (percentage !== undefined && percentage > 0) {
      response.percentagePositions = [percentage / 100, percentage / 100, 1];
      response.percentageColors = [percentageColor, COLOR_DARK, COLOR_DARK];
    }

    return response;
  }, [
    percentage,
    state,
    selected,
    userImageUrl,
    compareSelected,
    compareImageUrl,
  ]);

  return (
    <View style={styles.container}>
      {props.useCompareLayout && (
        <View style={styles.compareIconContainer}>
          {answerViewModel.compareIcon}
        </View>
      )}
      <Pressable
        onPress={props.onSelected}
        style={styles.pressable}
        disabled={
          props.onSelected === undefined ||
          selected ||
          state === QuestionAnswerState.CORRECT ||
          state !== QuestionAnswerState.CHANGEABLE
        }>
        <LinearGradient
          useAngle={true}
          angle={90}
          colors={answerViewModel.percentageColors}
          locations={answerViewModel.percentagePositions}
          style={answerViewModel.answerContainerStyle}>
          <View style={styles.labelContainer}>
            <Text style={answerViewModel.labelStyle}>{props.label}</Text>
          </View>
          {props.usersSelected
            .filter(
              (u) =>
                u.media?.image?.url !== undefined && u.media?.image?.url !== '',
            )
            .map(
              (user, index) =>
                index < 3 && (
                  <Avatar
                    key={user.id}
                    style={styles.selectedAvatars}
                    imageUrl={user.media?.image?.url}
                    size={24}
                    defaultIconColor={COLOR_GREY_2}
                    highlightWidth={0}
                  />
                ),
            )}
        </LinearGradient>
      </Pressable>
      <View style={styles.iconContainer}>{answerViewModel.icon}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  pressable: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  answerContainer: {
    position: 'relative',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 32,
    paddingLeft: 16,
    paddingRight: 4,
    paddingVertical: 4,
    borderRadius: 16,
    backgroundColor: COLOR_DARK,
    overflow: 'hidden',
  },
  selectedAnswerContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    minHeight: 36,
    borderRadius: 18,
    margin: -2,
  },
  labelContainer: {
    width: 0,
    flexGrow: 1,
    flex: 1,
  },
  label: {
    flexWrap: 'wrap',
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 16,
  },
  iconContainer: {
    flexShrink: 0,
    marginLeft: 0,
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  compareIconContainer: {
    flexShrink: 0,
    marginRight: 0,
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  selectedAvatars: {
    alignSelf: 'flex-start',
    marginLeft: -2,
  },
});
