import {useCallback, useState, useRef, useEffect, useLayoutEffect} from 'react';
import log from '@chancer/common/lib/utils/Log';
import {IAction} from '../../actions/Actions';
import {sendCurrentScreen} from '../../actions/messages/MessageActions';

export const useTimeout = (callback: () => void, delay?: number) => {
  const savedCallback = useRef<() => void>();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== undefined) {
        savedCallback.current();
      }
    }
    if (delay !== undefined) {
      let id = window.setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useGetElementDimensions = (): [
  (node: HTMLDivElement | null) => void,
  {w: number; h: number},
] => {
  const [dimensions, setDimensions] = useState<{w: number; h: number}>({
    w: 0,
    h: 0,
  });
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setDimensions({w: node.offsetWidth, h: node.offsetHeight});
    }
  }, []);
  return [ref, dimensions];
};

export const useResizeObserver = <T extends HTMLElement>(
  callback: (target: T, entry: ResizeObserverEntry) => void,
) => {
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    const element = ref?.current;
    if (!element) {
      return;
    }
    const observer = new ResizeObserver((entries) => {
      callback(element, entries[0]);
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);
  return ref;
};

export const useCurrentScreenView = (
  screenName: string,
  isActive: boolean,
  setScreen: (payload: IAction<string>) => void,
) => {
  const hasSentRef = useRef(false);
  useEffect(() => {
    if (isActive && hasSentRef.current === false) {
      hasSentRef.current = true;
      setScreen(sendCurrentScreen(screenName));
    } else if (isActive === false && hasSentRef.current === true) {
      hasSentRef.current = false;
    }
  }, [isActive, screenName, setScreen]);
};

// Utility to trace what has changed on a screen
export const useTraceUpdate = (props: any) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        (ps as any)[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      log.debug('Changed props:', changedProps);
    }
    prev.current = props;
  });
};
