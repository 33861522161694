import React, {useMemo} from 'react';
import {StyleSheet, Text, View, ViewStyle} from 'react-native';
import {
  COLOR_GREEN,
  COLOR_LIGHT,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';

interface IProps {
  style?: CompositedViewStyle;
  label: string;
  backgroundColor?: string;
  labelColor?: string;
  borderColor?: string;
  numberOfLines?: number;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
}

export const LabelBadge: React.FC<IProps> = (props) => {
  const {backgroundColor, labelColor, borderColor} = props;
  const backgroundColorStyle: ViewStyle = useMemo(
    () => ({
      backgroundColor: backgroundColor ?? COLOR_GREEN,
      ...(borderColor ? {borderWidth: 1, borderColor} : {}),
    }),
    [backgroundColor, borderColor],
  );
  const labelColorStyle = useMemo(
    () => ({color: labelColor ?? COLOR_LIGHT}),
    [labelColor],
  );
  return (
    props.label && (
      <View style={[styles.statusContainer, props.style, backgroundColorStyle]}>
        <Text
          style={[styles.statusText, labelColorStyle]}
          ellipsizeMode={props.ellipsizeMode}
          numberOfLines={props.numberOfLines}>
          {props.label}
        </Text>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  statusContainer: {
    backgroundColor: COLOR_GREEN,
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 3,
    alignSelf: 'baseline',
  },
  statusText: {
    color: COLOR_LIGHT,
    fontFamily: FONT_SUB_TITLE,
    textAlign: 'center',
    fontSize: 11,
    letterSpacing: 0,
  },
});
